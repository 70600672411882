import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'

// Actions
import {
    closeChangesModal
} from '../../actions/bookings'

// Selectors
import { getOriginStation, getDestinationStation } from '../../selectors'

// Components
import Journey from '../Journey/Journey'

// Styles
import './ChangesModal.scss'

const mapStateToProps = (state) => ({
    isChangesModalOpen: state.isChangesModalOpen,
    originStation: getOriginStation(state.stations, state.booking),
    destinationStation: getDestinationStation(state.stations, state.booking)
})

const mapDispatchToProps = (dispatch) => ({
    onCloseModal: () => dispatch(closeChangesModal())
})

const ChangesIcon = props => (
    <svg width={16.7} height={7.6} {...props}>
        <path
            d="M16.3 3.4h-4.2C12 1.5 10.3 0 8.4 0c-2 0-3.6 1.5-3.8 3.4H.4c-.2 0-.4.2-.4.4s.2.4.4.4h4.2c.2 1.9 1.8 3.4 3.8 3.4 2 0 3.6-1.5 3.8-3.4h4.2c.2 0 .4-.2.4-.4s-.3-.4-.5-.4zM8.4 6.8c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.4 3-3 3z"
            fill="#fff"
        />
    </svg>
)

export class ChangesModal extends Component {
    static propTypes = {
        isChangesModalOpen: PropTypes.bool.isRequired,
        onCloseModal: PropTypes.func.isRequired
    }

    handleCloseModal = () => {
        this.props.onCloseModal()
    }

    render() {
        return (
            <Modal
                open={this.props.isChangesModalOpen}
                onClose={this.handleCloseModal}
                classNames={{
                    modal: 'changes-modal'
                }}
                center
            >
                <h5 className="changes-modal__title">{<ChangesIcon />} Stops</h5>
                <Journey
                    leftText={this.props.originStation.nreCode}
                    leftSubText={this.props.originStation.name}

                    rightText={this.props.destinationStation.nreCode}
                    rightSubText={this.props.destinationStation.name}
                />
            </Modal>
        )
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ChangesModal)
