import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs from 'dayjs'

// Syles
import './Header.scss';
import logo from "../../assets/TOCs/LNER/logo.svg";

// Components
import Journey from '../Journey/Journey';

// Selectors
import { getOriginStation, getDestinationStation } from '../../selectors'

const mapStateToProps = (state) => ({
    originStation: getOriginStation(state.stations, state.booking),
    destinationStation: getDestinationStation(state.stations, state.booking),
    step: state.step,
})

export class Header extends Component {
    static propTypes = {
        originStation: PropTypes.object.isRequired,
        destinationStation: PropTypes.object.isRequired,
    }

    getTitle() {
        switch (this.props.step) {
            case 2:
                return 'PURCHASE COMPLETE'
                break;
            default:
                return 'COMPLETE PURCHASE'
        }
    }

    render() {
        return (
            <header className="animated fadeIn">
                <p className="complete-purchase"><b>{this.getTitle()}</b></p>
                {/* <Journey
                    leftText={this.props.originStation.nreCode}
                    leftSubText={this.props.originStation.name}

                    rightText={this.props.destinationStation.nreCode}
                    rightSubText={this.props.destinationStation.name}
                /> */}
            </header>
        )
    }
}

export default connect(mapStateToProps, null)(Header);
