import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames';
// Components 
import Separator from '../Separator/Separator'

import './Card.scss';

export default class Card extends Component {
    static propTypes = {
        children: PropTypes.node,
        title: PropTypes.string,
        className: PropTypes.string,
    }

    render() {
        return (
            <div className={cx('card', this.props.className)}>
                <h5>{this.props.title}</h5>
                <Separator />
                {this.props.children}
            </div>
        )
    }
}
