import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import humanizeDuration from 'humanize-duration'

// Styles
import './JourneyDetails.scss'

// Components
import Card from '../Card/Card'
import Journey from '../Journey/Journey'

// Actions
import {
    openChangesModal
} from '../../actions/bookings'

// Selectors
import {
    getOriginStation,
    getDestinationStation,
    getDepartureDateTime,
    getArrivalDateTime,
    getTotalDuration,
    getChanges,
    getRules
} from '../../selectors'

const mapStateToProps = (state) => ({
    originStation: getOriginStation(state.stations, state.booking),
    destinationStation: getDestinationStation(state.stations, state.booking),
    departureDateTime: dayjs(getDepartureDateTime(state.booking).replace('Z', ''), { timeZone: 'Europe/London' }),
    arrivalDateTime: dayjs(getArrivalDateTime(state.booking).replace('Z', ''), { timeZone: 'Europe/London' }),
    // duration: getTotalDuration(state.booking),
    changes: getChanges(state.booking),
    rules: getRules(state.booking).filter(rule => rule.hasOwnProperty('description'))
})

const mapDispatchToProps = (dispatch) => ({
    onOpenChangesModal: () => dispatch(openChangesModal())
})

class JourneyDetails extends Component {
    static propTypes = {
        journey: PropTypes.object
    }

    handleOpenChangesModal = () => {
        this.props.onOpenChangesModal()
    }

    render() {
        return (
            <Card title="Journey Details" className="animated fadeIn">
                <Journey
                    leftText={this.props.departureDateTime.format('HH:mm')}
                    leftSubText={this.props.originStation.name}

                    rightText={this.props.arrivalDateTime.format('HH:mm')}
                    rightSubText={this.props.destinationStation.name}
                    className="journey--light"
                />
                <div className="journey-details">
                    <b>Out: {this.props.departureDateTime.format('HH:mm DD MMM YYYY')}</b>
                    <p>{`${this.props.originStation.name} (${this.props.originStation.nreCode})`} → {`${this.props.destinationStation.name} (${this.props.destinationStation.nreCode})`}</p>
                    <span className="journey-details__travel-time" onClick={this.handleOpenChangesModal}>
                        
                    </span>
                    <ul>
                        {this.props.rules.map((info, i) => (
                            <li key={i}>{info.description}</li>
                        ))}
                    </ul>
                </div>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JourneyDetails)
