import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

const MERCHANT_IDENTIFIER = process.env.REACT_APP_RAILPAY_APPLEPAY_MERCHANT_ID;
const ApplePaySession = window.ApplePaySession;

export default class ApplePayButton extends PureComponent {

    static propTypes = {
        onPaymentAuthorized: PropTypes.func.isRequired,
        orderTotal: PropTypes.number.isRequired
    }
    canUseApplePay = () => {
        return ApplePaySession && ApplePaySession.canMakePayments(MERCHANT_IDENTIFIER)
    }

    handleClick = () => {
        let request = {
            countryCode: 'GB',
            currencyCode: 'GBP',
            supportedNetworks: ['visa', 'masterCard', 'amex'],
            merchantCapabilities: ['supports3DS'],
            lineItems: [{
                label: `Total Price Ticket`,
                amount: this.props.orderTotal.toFixed(2)
            }],
            requiredBillingContactFields: [
                'postalAddress',
            ],
            requiredShippingContactFields: [
                'email',
            ],
            total: { label: 'RailPay', amount: this.props.orderTotal }
        }

        // Create Apple Pay Session
        let session = new ApplePaySession(3, request)

        // Validate session with the API server + Apple Servers
        session.onvalidatemerchant = ({ validationURL }) => {
            axios.post(process.env.REACT_APP_RAILPAY_API_URL + '/apple-pay-verify', {
                validationURL: validationURL
            }).then(({ data }) => {
                session.completeMerchantValidation(data)
            })
        }

        session.onpaymentauthorized = (event) => {
            const completePayment = () => session.completePayment(ApplePaySession.STATUS_SUCCESS)
            console.log(event)
            this.props.onPaymentAuthorized(event.payment, 'applepay', completePayment)
        }

        session.begin()
        // this.props.onPaymentAuthorized(() => { })
    }

    render() {
        if (this.canUseApplePay()) {
            return (
                <div
                    className="apple-pay-button-with-text"
                    onClick={this.handleClick}
                >
                    <span className="text">Buy with</span>
                    <span className="logo"></span>
                </div>
            );
        }

        return null
    }
}
