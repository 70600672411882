import axios from 'axios'
import * as constants from '../constants'
import { stations } from './stations.js'
import qs from 'querystring'
import { offlineBooking } from './offline-booking.js'

import { isAndroid } from 'react-device-detect'

const railPayConfig = {
  hostname: process.env.REACT_APP_RAILPAY_API_URL,
  clientId: process.env.REACT_APP_RAILPAY_API_CLIENT_ID,
  clientSecret: process.env.REACT_APP_RAILPAY_API_CLIENT_SECRET
}

export const API_HOSTNAME = railPayConfig.hostname

const setAxiosConfig = (token) => {
  // Add a request interceptor to default
  axios.interceptors.request.use((config) => {
    config.headers.post['Access-Control-Allow-Origin'] = '*'
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

// Requests
const getToken = ({ clientId, clientSecret }) => (dispatch, getState) => {
  axios.post(`${API_HOSTNAME}/oauth/token`,
    qs.stringify({
      grant_type: 'client_credentials',
      client_id: clientId,
      client_secret: clientSecret,
      scope: 'api-access'
    }))
    .then(({ data }) => {
      setAxiosConfig(data.access_token)
      dispatch(getStations())
      dispatch(getBooking(getState().bookingId))
    })
}

const getBooking = (bookingId = null) => (dispatch) => {
  axios.get(`${API_HOSTNAME}/v1/bookings/${bookingId}`)
    .then(({ data }) => {
      return dispatch({
        type: constants.BOOKING_LOADED,
        booking: data
      })
    })
}

const getStations = () => (dispatch) => {
  // axios.get(`${API_HOSTNAME}/v1/stations`)
  //     .then(({ data }) => {
  return dispatch({
    type: constants.STATIONS_LOADED,
    stations: stations
  })
  // })
}

export const processPayment = (bookingId, total, tokenisedPaymentData, completePayment, paymentType, platform) => (dispatch) => {
  const paymentData = {
    amount: String(total),
    confirmBooking: true,
    ticketOption: 'XVD'
  }

  let name 
  let email

  axios.post(`${API_HOSTNAME}/v1/bookings/${bookingId}/payments?paymentType=${paymentType}`, { 
      tokenisedPaymentData,
      paymentData
  }).then((data) => {
      // Finish payment session
      completePayment()
      dispatch({
        type: constants.PAYMENT_PROCESSED
      })
      
      if (paymentType === 'applepay') {
        name = tokenisedPaymentData.billingContact.givenName
        email = tokenisedPaymentData.shippingContact.emailAddress 
      } else {
        name = tokenisedPaymentData.paymentMethodData.info.billingAddress.name.split(' ')[0]
        email = tokenisedPaymentData.email 
      }

      return dispatch(getTicket(bookingId, platform, name, email))
  })
}

export const getPass = (bookingId, passIndex) => (dispatch) => {
  const newTab = window.open()
  axios({
    url: `${API_HOSTNAME}/passes/${bookingId}?ticket=${passIndex}&platform=ios`,
    method: 'GET',
  }).then(({ data }) => {
    newTab.location.href = data.url
  })
} 


export const getETicket = (bookingId, passIndex) => (dispatch) => {
  const newTab = window.open()
  axios({
    url: `${API_HOSTNAME}/passes/${bookingId}?ticket=${passIndex}`,
    method: 'GET',
  }).then(({ data }) => {
    newTab.location.href = data.url
  })
}

export const generateValueDocument = (bookingId, platform = null, name, email) => (dispatch) => {
  dispatch({
    type: constants.RETRIEVING_VALUE_DOCUMENT
  })
  axios.get(`${API_HOSTNAME}/v1/bookings/${bookingId}/tickets?platform=ios&name=${name}&email=${email}`)
    .then(({ data }) => {
      dispatch({
        type: constants.PASSES_READY,
        numberOfPasses: data
      })
    })
}

// Actions

export const setBoookingId = (bookingId = null) => ({
  type: constants.SET_BOOKING_ID,
  bookingId
})

export const loadBooking = (bookingId = null) => (dispatch) => {
  dispatch(getToken(railPayConfig))
  dispatch(setBoookingId(bookingId))
  return dispatch({
    type: constants.GETTING_BOOKING
  })
}

export const sendPaymentToProcessing = (bookingId, total, tokenisedPaymentData, completePayment, paymentType,  platform) => (dispatch) => {
  dispatch(processPayment(bookingId, total, tokenisedPaymentData, completePayment,paymentType, platform))
  return dispatch({
    type: constants.PROCESSING_PAYMENT
  })
}

export const getTicket = (bookingId, platform, name, email) => (dispatch) => dispatch(generateValueDocument(bookingId, platform, name, email))

export const closeChangesModal = () => (dispatch) => dispatch({
  type: constants.CLOSE_MODAL
})

export const openChangesModal = () => (dispatch) => dispatch({
  type: constants.OPEN_MODAL
})
