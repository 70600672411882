export const GET_TOKEN = 'GET_TOKEN'
export const SET_TOKEN = 'SET_TOKEN'

export const STATIONS_LOADED = 'STATIONS_LOADED'

export const SET_BOOKING_ID = 'SET_BOOKING_ID'
export const GETTING_BOOKING = 'GETTING_BOOKING'
export const BOOKING_LOADED = 'BOOKING_LOADED'

export const PROCESSING_PAYMENT = 'PROCESSING_PAYMENT'
export const PAYMENT_PROCESSED = 'PAYMENT_PROCESSED'

export const RETRIEVING_VALUE_DOCUMENT = 'RETRIEVING_VALUE_DOCUMENT'
export const VALUE_DOCUMENT_RETRIEVED = 'VALUE_DOCUMENT_RETRIEVED'

export const PASSES_READY = 'PASSES_READY'

export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'
