import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

// Styles
import './App.scss'

// Components
import Header from '../Header/Header.jsx'
import Card from '../Card/Card'
import JourneyDetails from '../JourneyDetails/JourneyDetails.jsx'
import OrderDetails from '../OrderDetails/OrderDetails.jsx'
import TicketDownloader from '../TicketDownloader/TicketDownloader.jsx'
import ChangesModal from '../ChangesModal/ChangesModal.jsx'
import BounceLoader from 'react-spinners/BounceLoader'

// Actions
import { loadBooking } from '../../actions/bookings'
import TicketCard from '../TicketCard/TicketCard'

const mapDispatchToProps = (dispatch) => ({
  loadBooking: (bookingId) => dispatch(loadBooking(bookingId))
})

const mapStateToProps = (state) => ({
  isLoading: state.isLoading,
  loadingLabel: state.loadingLabel,
  step: state.step,
  bookingStatus: state.bookingStatus
})

class App extends PureComponent {
  componentDidMount () {
    // Get the whole path and remove empty splits
    const pathName = window.location.pathname.split('/').filter(path => path.length > 0)
    // Pass the last path which is assumed to be the booking ID
    this.props.loadBooking(pathName[pathName.length - 1])
  }

  render () {
    return (
      <div className='app-root'>
        {this.props.isLoading
          ? <div className='app-root__spinner animated fadeIn'>
            <BounceLoader
                color='#C8102E'
                loading={this.props.isLoading}
              />
            <p>{this.props.loadingLabel}</p>
          </div>
          : (
              this.props.step !== -1
                ? <>
                  <Header />
                  {this.props.step === 2 && <TicketDownloader />}
                  {this.props.step === 3 &&
                      <Card title='All done!'>
                      <p>Ticket added to your wallet!</p>
                    </Card>}
                  <TicketCard hasFooter />
                  {this.props.step === 1 && <OrderDetails />}
                  {/* {this.props.step === 1 && <ChangesModal />} */}
                </>
                : <div className='app-root__spinner'>
                  <p>We're sorry but this booking is: {this.props.bookingStatus}</p>
                </div>
            )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
