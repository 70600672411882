import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
    isIOS,
    isMacOs,
} from 'react-device-detect'

import './TicketDownload.scss';
import addToWallet from './add-to-wallet.svg';

// Components
// import Card from '../Card/Card';

// Actions
import { getETicket } from '../../actions/bookings'
import { getPass } from '../../actions/bookings'

const mapStateToProps = (state) => ({
    bookingId: state.bookingId,
    passes: state.numberOfPasses
})

const mapDispatchToProps = (dispatch) => ({
    onAddToWalletClick: (bookingId, i) => dispatch(getPass(bookingId, i)),
    onDownloadTicket: (bookingId, i) => dispatch(getETicket(bookingId, i))
})

export class TicketDownloader extends Component {

    static propTypes = {
        onAddToWalletClick: PropTypes.func.isRequired,
        onDownloadTicket: PropTypes.func.isRequired
    }

    handleAddToWalletClick = (i) => {
        this.props.onAddToWalletClick(this.props.bookingId, i);
    }

    handleDownloadTicket = (i) => {
        this.props.onDownloadTicket(this.props.bookingId, i);
    }

    render() {
        if ((isIOS || isMacOs)) {
            return Array.from(new Array(this.props.passes)).map((a, i) => (
                <>
                    <span>{i === 0 ? 'Outward' : 'Return'}</span>
                    <button className="button button--passbook">
                        <img src={addToWallet} onClick={() => this.handleAddToWalletClick(i)} alt="Add ticket to Apple Wallet" />
                    </button>
                </>
            ))
        } else {
            return Array.from(new Array(this.props.passes)).map((a, i) => (
                <>
                    <span>{i === 0 ? 'Outward' : 'Return'}</span>
                    <button className="button button--download" onClick={() => this.handleDownloadTicket(i)}>
                        Download your ticket
                    </button>
                </>
            ))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDownloader)
