import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
    isIOS,
    isMacOs,
    isAndroid
} from 'react-device-detect'

//Styles
import './OrderDetails.scss'

// Components
import Card from '../Card/Card'
import ApplePayButton from './ApplePayButton/ApplePayButton'
import GooglePayButton from './GooglePayButton/GooglePayButton'

// Actions
import { sendPaymentToProcessing } from '../../actions/bookings'

// Selectors
import {
    getPassengers,
    getPriceTotal
} from '../../selectors'

const mapStateToProps = (state) => {
    const passengers = getPassengers(state.booking);
    return {
        adultsCount: passengers.filter(pax => pax.ageAtTimeOfTravel > 10).length,
        childCount: passengers.filter(pax => pax.ageAtTimeOfTravel <= 10).length,
        orderTotal: getPriceTotal(state.booking),
        bookingId: state.bookingId,
    }
}
const mapDispatchToProps = (dispatch) => ({
    onPaymentDone: (bookingId, total, tokenisedPaymentData, paymentType, handleCompletePayment) => 
        dispatch(sendPaymentToProcessing(bookingId, total, tokenisedPaymentData, handleCompletePayment, paymentType, (isIOS || isMacOs) ? 'ios' : null))
});

class OrderDetails extends Component {
    static propTypes = {
        journey: PropTypes.object
    }

    handlePaymentAuthorized = (tokenisedPaymentData, paymentType, completePayment) => {
        this.props.onPaymentDone(this.props.bookingId, this.props.orderTotal, tokenisedPaymentData, paymentType, completePayment)
    }

    render() {
        return (
            <div className="payment-options">
                <p>By paying for this ticket you're agreeing to the <a href="http://www.nationalrail.co.uk/NRCOT" target="_blank" rel="noreferrer">National Rail Conditions of Travel</a> and the <a href="https://www.lner.co.uk/support/privacy-policy/" target="_blank" rel="noreferrer">LNER privacy policy</a></p>
                <ApplePayButton
                    onPaymentAuthorized={this.handlePaymentAuthorized}
                    orderTotal={this.props.orderTotal}
                />
                <GooglePayButton
                    onPaymentAuthorized={this.handlePaymentAuthorized}
                    orderTotal={this.props.orderTotal}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
