import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// Styles
import './Journey.scss';

// Assets 
import arrow from '../../assets/arrow.svg';

const JourneyItem = ({ mainText, subText = null, size }) => (
    <div className="journey__item">
        <p className={cx('journey__text-main', `journey__text-main--${size}`)}>{mainText}</p>
        <p className={cx('journey__text-sub', `journey__text-sub--${size}`)}>{subText}</p>
    </div>
)

const Arrow = ({ direction = 'right' }) =>
    <div className={cx('journey__arrow', `journey__arrow--${direction}`)}>
        <img src={arrow} alt="arrow" />
    </div>

export default class Journey extends Component {
    static propTypes = {
        leftText: PropTypes.string,
        leftSubText: PropTypes.string,

        rightText: PropTypes.string,
        rightSubText: PropTypes.string,

        direction: PropTypes.string,
        size: PropTypes.string,
        className: PropTypes.string,
    }

    render() {
        return (
            <div className={cx("journey", this.props.className)}>
                <JourneyItem
                    mainText={this.props.leftText}
                    subText={this.props.leftSubText}
                    size={this.props.size}
                />
                <Arrow />
                <JourneyItem
                    mainText={this.props.rightText}
                    subText={this.props.rightSubText}
                    size={this.props.size}
                />
            </div>
        )
    }
}