import * as constants from './constants.js'
const initialState = {
  isLoading: true,
  isChangesModalOpen: true,
  loadingLabel: 'Retrieving your booking',
  booking: null,
  stations: null,
  step: 1
}
const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case constants.STATIONS_LOADED:
      return {
        ...state,
        stations: action.stations,
        isLoading: state.booking === null
      }

    case constants.SET_BOOKING_ID:
      return {
        ...state,
        bookingId: action.bookingId
      }

    case constants.GETTING_BOOKING:
      return {
        ...state,
        isLoading: true
      }

    case constants.BOOKING_LOADED:
      if (['CLOSED', 'BALANCED'].includes(action.booking.attributes.status)) {
        return {
          ...state,
          booking: action.booking,
          isLoading: state.stations === null,
          step: -1,
          bookingStatus: action.booking.attributes.status
        }
      }
      return {
        ...state,
        booking: action.booking,
        isLoading: state.stations === null
      }
    case constants.PROCESSING_PAYMENT:
      return {
        ...state,
        isLoading: true,
        loadingLabel: 'Processing your payment'
      }
    case constants.PAYMENT_PROCESSED:
      return {
        ...state,
        isLoading: false,
        step: 2
      }
    case constants.RETRIEVING_VALUE_DOCUMENT:
      return {
        ...state,
        isLoading: true,
        loadingLabel: 'Building your ticket'
      }
    case constants.VALUE_DOCUMENT_RETRIEVED:
      return {
        ...state,
        isLoading: false,
        step: 3
      }

    case constants.PASSES_READY:
      return {
        ...state,
        isLoading: false,
        numberOfPasses: action.numberOfPasses
      }

    case constants.OPEN_MODAL:
      return {
        ...state,
        isChangesModalOpen: true
      }

    case constants.CLOSE_MODAL:
      return {
        ...state,
        isChangesModalOpen: false
      }
    default:
      return state
  }
}

export default reducer
