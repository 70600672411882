import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import humanizeDuration from 'humanize-duration'
import dayjs from 'dayjs'
import railcards from './../../assets/railcards.json'

import Modal from 'simple-react-modal'

import './TicketCard.scss'

// Selectors
import {
  getPassengers,
  getPriceTotal,
  selectBookingTravelSegmentsByTicketableFareID,
  selectStationByCode,
  getTotalDurationFromTravelSegments,
  selectBookingOrderedLegTicketableFares
} from '../../selectors'

const duration = require('dayjs/plugin/duration')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.tz.setDefault("Europe/London")

const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>'
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    }
  }
})

const TicketItem = ({
  ticketableFare,
  index,
  adultsCount,
  childCount,
  onShowFareRules
}) => {
  const travelSegments = useSelector(selectBookingTravelSegmentsByTicketableFareID(ticketableFare.attributes.ticketableFareID))

  const travelSegmentsForLeg = travelSegments.filter(t => (t.attributes.legGrouping === `${index + 1}`))
  const fares = ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode
  const originFare = fares[0]
  const destinationFare = fares[fares.length - 1]

  const originTravelSegment = travelSegmentsForLeg[0]
  const destinationTravelSegment = travelSegmentsForLeg[travelSegmentsForLeg.length - 1]

  const originStation = useSelector(selectStationByCode(originTravelSegment.originTravelPoint.$value))
  const destinationStation = useSelector(selectStationByCode(destinationTravelSegment.destinationTravelPoint.$value))

  const departureDateTime = dayjs(originTravelSegment.departureDateTime)
  const arrivalDateTime = dayjs(destinationTravelSegment.arrivalDateTime)
  const tsDuration = departureDateTime.diff(arrivalDateTime, 'seconds')

  const hasRailcard = ticketableFare.fareQualifiers?.fareQualifier
  let railcard = '-'
  if (hasRailcard) {
    railcard = railcards.find(railcard => railcard.attributes.program === hasRailcard[0].program).attributes.description
  }

  const handleShowFareRules = useCallback(() => {
    onShowFareRules(ticketableFare.rules.rule.filter(rule => rule.description))
  }, [onShowFareRules, ticketableFare])

  return (
    <div className='ticket-card'>
      <div className='ticket-header'>
        <span>{index === 0 ? 'Outward' : 'Return'}</span>
        <span className='depart-date'>{departureDateTime.format('dddd, D MMM')}</span>
      </div>
      <div className='ticket-content'>
        <div className='journey-details'>
          <div className='origin'>
            <small>From</small>
            <span className='bold'>{originStation.attributes.name}</span>
          </div>
          <div className='destination'>
            <small>To</small>
            <span className='bold'>{destinationStation.attributes.name}</span>
          </div>
          <div className='passenger-detail'>
            <div>
              <small>Passenger</small>
              <span className='bold'>{adultsCount ? 'Adult' : 'Child'}</span>
            </div><br /><br />
            <div>
              <small>Railcard</small>
              <span className='bold'>{railcard}</span>
            </div>
          </div>
          <div className='fare-detail'>
            <small>Fare</small>
            <span className='bold fare'>{originFare?.fareDisplayName}</span><span className='info-toggle' onClick={handleShowFareRules} />
          </div>
        </div>
        <div className='order-details'>
          <div>
            <small>Depart</small>
            <span className='bold'>{departureDateTime.format('HH:mm')}</span>
          </div>
          <div>
            <small>Arrive</small>
            <span className='bold'>{arrivalDateTime.format('HH:mm')}</span>
          </div>
          <div>
            <small>Duration</small>
            <span className='bold'>{shortEnglishHumanizer(tsDuration * 1000, { spacer: '', delimiter: '' })}, {travelSegmentsForLeg.length === 1 ? 'direct' : `${travelSegmentsForLeg.length - 1} changes`}</span>
          </div>
          <span className='bold total-price'>£{ticketableFare?.totalPrice?.$value}</span>
        </div>
      </div>
      <div className='ticket-bite bottomLeft' />
      <div className='ticket-bite bottomRight' />
    </div>
  )
}

export default function TicketCard ({
  hasUpBites = false,
  hasFooter = false
}) {
  const passengers = useSelector(state => getPassengers(state.booking))
  const adultsCount = passengers.filter(pax => pax.ageAtTimeOfTravel > 10).length
  const childCount = passengers.filter(pax => pax.ageAtTimeOfTravel <= 10).length
  const orderTotal = useSelector(state => getPriceTotal(state.booking))

  const ticketableFares = useSelector(selectBookingOrderedLegTicketableFares)

  const [showRulesModal, setShowRulesModal] = useState(false)
  const [currentRules, setCurrentRules] = useState([])

  const handleShowFareRules = useCallback((rules) => {
    setCurrentRules(rules)
    setShowRulesModal(true)
  }, [setCurrentRules, setShowRulesModal])

  return (
    <>
        {ticketableFares.map((ticketableFare, i) =>
          <TicketItem
            key={`ticket-${i}`}
            ticketableFare={ticketableFare}
            index={i}
            adultsCount={adultsCount}
            childCount={childCount}
            onShowFareRules={handleShowFareRules}
          />
        )}
      <Modal
        show={showRulesModal}
        containerClassName='restrictions-modal'
      >
        <h1 className='restriction-title'>Ticket Restrictions</h1>
        <div className='restrictions'>
          {currentRules.map((info, i) => <p key={`restriction-${i}`} className='restriction' dangerouslySetInnerHTML={{ __html: urlify(info.description) }} />)}
        </div>
        <button className='button' onClick={() => setShowRulesModal(false)}>
          Close
        </button>
      </Modal>
      {hasFooter && (
        <div className='ticket-footer'>
          <div className='total'><small>Total:  </small><b>£{orderTotal.toFixed(2)}</b></div>
          <div className='ticket-bite upperLeft' />
          <div className='ticket-bite upperRight' />
        </div>
      )}
    </>
  )
}
