export const stations = [
    {
      attributes: {
        code: 'GB!BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Barrow+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrow+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!BE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brentwood+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brentwood+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Birmi Stns+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmi Stns+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bradfrdstn+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bradfrdstn+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bishopstorfd+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bishopstorfd+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!BU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bushey+Busgw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bushey+Busgw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!CR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cradley H+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cradley H+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!LZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 4*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '44A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 4*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!SH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shrewsbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shrewsbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stevenage+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stevenage+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB!ZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U3456 Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U3456 Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB#AC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBACC'
              }
            },
            {
              attributes: {
                code: 'GBAML'
              }
            },
            {
              attributes: {
                code: 'GBSAT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#AL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Allerton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLPY'
              }
            },
            {
              attributes: {
                code: 'GBWSA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#AR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardrossan (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBADN'
              }
            },
            {
              attributes: {
                code: 'GBADS'
              }
            },
            {
              attributes: {
                code: 'GBASB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#AS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashford International (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBAFK'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#AY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ayr (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBAYR'
              }
            },
            {
              attributes: {
                code: 'GBNOA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnes (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBNI'
              }
            },
            {
              attributes: {
                code: 'GBBNS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBDM'
              }
            },
            {
              attributes: {
                code: 'GBBSJ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beckenham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBEC'
              }
            },
            {
              attributes: {
                code: 'GBBKJ'
              }
            },
            {
              attributes: {
                code: 'GBNBC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bicester (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBCS'
              }
            },
            {
              attributes: {
                code: 'GBBIT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belfast (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBFA'
              }
            },
            {
              attributes: {
                code: 'GBBFC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brondesbury (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBSP'
              }
            },
            {
              attributes: {
                code: 'GBBSY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burscough (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBCB'
              }
            },
            {
              attributes: {
                code: 'GBBCJ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Battersea (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBAK'
              }
            },
            {
              attributes: {
                code: 'GBQRB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBNC'
              }
            },
            {
              attributes: {
                code: 'GBBUB'
              }
            },
            {
              attributes: {
                code: 'GBBYM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buxton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBUX'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#BY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Byfleet (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBFN'
              }
            },
            {
              attributes: {
                code: 'GBWBY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chessington (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCSN'
              }
            },
            {
              attributes: {
                code: 'GBCSS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clapham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCLJ'
              }
            },
            {
              attributes: {
                code: 'GBCLP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canterbury (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCBE'
              }
            },
            {
              attributes: {
                code: 'GBCBW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coatbridge (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCBC'
              }
            },
            {
              attributes: {
                code: 'GBCBS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Croydon (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBECR'
              }
            },
            {
              attributes: {
                code: 'GBSCY'
              }
            },
            {
              attributes: {
                code: 'GBWCY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#CT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Catford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCFB'
              }
            },
            {
              attributes: {
                code: 'GBCTF'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorking (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBDKG'
              }
            },
            {
              attributes: {
                code: 'GBDKT'
              }
            },
            {
              attributes: {
                code: 'GBDPD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dulwich (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEDW'
              }
            },
            {
              attributes: {
                code: 'GBNDL'
              }
            },
            {
              attributes: {
                code: 'GBWDU'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumbarton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBDBC'
              }
            },
            {
              attributes: {
                code: 'GBDBE'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunfermline (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBDFE'
              }
            },
            {
              attributes: {
                code: 'GBDFL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorchester (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBDCH'
              }
            },
            {
              attributes: {
                code: 'GBDCW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#DU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dudley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBDDP'
              }
            },
            {
              attributes: {
                code: 'GBSAD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#EA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ealing (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEAL'
              }
            },
            {
              attributes: {
                code: 'GBWEA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#ED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edenbridge(Kent) (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEBR'
              }
            },
            {
              attributes: {
                code: 'GBEBT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#EN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enfield (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBENC'
              }
            },
            {
              attributes: {
                code: 'GBENF'
              }
            },
            {
              attributes: {
                code: 'GBENL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#EP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Epsom (Surrey) (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEPD'
              }
            },
            {
              attributes: {
                code: 'GBEPS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#EW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ewell (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEWE'
              }
            },
            {
              attributes: {
                code: 'GBEWW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#FA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falkirk (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBFKG'
              }
            },
            {
              attributes: {
                code: 'GBFKK'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#FM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falmouth (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBFAL'
              }
            },
            {
              attributes: {
                code: 'GBFMT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#FO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Folkestone (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBFKC'
              }
            },
            {
              attributes: {
                code: 'GBFKW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#FR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farnborough (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBFNB'
              }
            },
            {
              attributes: {
                code: 'GBFNN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#GA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gainsborough (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGBL'
              }
            },
            {
              attributes: {
                code: 'GBGNB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#GI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grimsby (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGMB'
              }
            },
            {
              attributes: {
                code: 'GBGMD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#GR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGFD'
              }
            },
            {
              attributes: {
                code: 'GBSGN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#GU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Guildford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGLD'
              }
            },
            {
              attributes: {
                code: 'GBLRD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hackney (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHAC'
              }
            },
            {
              attributes: {
                code: 'GBHKC'
              }
            },
            {
              attributes: {
                code: 'GBHKW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heath (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHHL'
              }
            },
            {
              attributes: {
                code: 'GBHLL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillington (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHLE'
              }
            },
            {
              attributes: {
                code: 'GBHLW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Helensburgh (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHLC'
              }
            },
            {
              attributes: {
                code: 'GBHLU'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamilton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHNC'
              }
            },
            {
              attributes: {
                code: 'GBHNW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampstead (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHDH'
              }
            },
            {
              attributes: {
                code: 'GBSOH'
              }
            },
            {
              attributes: {
                code: 'GBWHD'
              }
            },
            {
              attributes: {
                code: 'GBWHP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#HY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hyde (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHYC'
              }
            },
            {
              attributes: {
                code: 'GBHYT'
              }
            },
            {
              attributes: {
                code: 'GBNWN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#KE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kentish Town (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBKTN'
              }
            },
            {
              attributes: {
                code: 'GBKTW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#KI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kiveton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBKIV'
              }
            },
            {
              attributes: {
                code: 'GBKVP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#KW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kew (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBKWB'
              }
            },
            {
              attributes: {
                code: 'GBKWG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#LE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lelant (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLEL'
              }
            },
            {
              attributes: {
                code: 'GBLTS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#LL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanwrst (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLWR'
              }
            },
            {
              attributes: {
                code: 'GBNLR'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#LP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lympstone (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLYC'
              }
            },
            {
              attributes: {
                code: 'GBLYM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#LU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Luton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLTN'
              }
            },
            {
              attributes: {
                code: 'GBLUT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#LY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leyton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLEM'
              }
            },
            {
              attributes: {
                code: 'GBLER'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maesteg (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMEW'
              }
            },
            {
              attributes: {
                code: 'GBMST'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maidstone (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMDB'
              }
            },
            {
              attributes: {
                code: 'GBMDE'
              }
            },
            {
              attributes: {
                code: 'GBMDW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#ML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Malling (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEML'
              }
            },
            {
              attributes: {
                code: 'GBWMA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marple (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMPL'
              }
            },
            {
              attributes: {
                code: 'GBRSH'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mansfield (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMFT'
              }
            },
            {
              attributes: {
                code: 'GBMSW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Matlock (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMAT'
              }
            },
            {
              attributes: {
                code: 'GBMTB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#MV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Malvern (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGMV'
              }
            },
            {
              attributes: {
                code: 'GBMVL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#NA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newark (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNCT'
              }
            },
            {
              attributes: {
                code: 'GBNNG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#NB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbury (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNBY'
              }
            },
            {
              attributes: {
                code: 'GBNRC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#NE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Cross (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNWX'
              }
            },
            {
              attributes: {
                code: 'GBNXG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#NH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newhaven (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNVH'
              }
            },
            {
              attributes: {
                code: 'GBNVN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#NO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Norwood (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNWD'
              }
            },
            {
              attributes: {
                code: 'GBWNW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#OL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oldham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBOLM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#OU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oulton Broad (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBOUN'
              }
            },
            {
              attributes: {
                code: 'GBOUS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paisley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPCN'
              }
            },
            {
              attributes: {
                code: 'GBPYG'
              }
            },
            {
              attributes: {
                code: 'GBPYJ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Peckham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPMR'
              }
            },
            {
              attributes: {
                code: 'GBQRP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pembroke (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPMB'
              }
            },
            {
              attributes: {
                code: 'GBPMD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshaws (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPWE'
              }
            },
            {
              attributes: {
                code: 'GBPWW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Purley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPUO'
              }
            },
            {
              attributes: {
                code: 'GBPUR'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#PV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pevensey (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPEB'
              }
            },
            {
              attributes: {
                code: 'GBPEV'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reading (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRDG'
              }
            },
            {
              attributes: {
                code: 'GBRDW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhoose (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ruislip (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSRU'
              }
            },
            {
              attributes: {
                code: 'GBWRU'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Runcorn (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRUE'
              }
            },
            {
              attributes: {
                code: 'GBRUN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rugeley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRGT'
              }
            },
            {
              attributes: {
                code: 'GBXRG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#RY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryde (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRYD'
              }
            },
            {
              attributes: {
                code: 'GBRYP'
              }
            },
            {
              attributes: {
                code: 'GBRYR'
              }
            },
            {
              attributes: {
                code: 'GBXRD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSFD'
              }
            },
            {
              attributes: {
                code: 'GBSLD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Helens (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSHJ'
              }
            },
            {
              attributes: {
                code: 'GBSNH'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Leonards (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSLQ'
              }
            },
            {
              attributes: {
                code: 'GBWLD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Smethwick (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSGB'
              }
            },
            {
              attributes: {
                code: 'GBSMR'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stourbridge (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSBJ'
              }
            },
            {
              attributes: {
                code: 'GBSBT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Pancras International (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Streatham (Greater London) (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSRC'
              }
            },
            {
              attributes: {
                code: 'GBSRH'
              }
            },
            {
              attributes: {
                code: 'GBSTE'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Budeaux (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSBF'
              }
            },
            {
              attributes: {
                code: 'GBSBV'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sudbury (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB#SY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sydenham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLSY'
              }
            },
            {
              attributes: {
                code: 'GBSYD'
              }
            },
            {
              attributes: {
                code: 'GBSYH'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#TH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorne (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBTNN'
              }
            },
            {
              attributes: {
                code: 'GBTNS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#TI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tilbury (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBETL'
              }
            },
            {
              attributes: {
                code: 'GBTBR'
              }
            },
            {
              attributes: {
                code: 'GBTIL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#TO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tottenham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSTO'
              }
            },
            {
              attributes: {
                code: 'GBSVS'
              }
            },
            {
              attributes: {
                code: 'GBTTK'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#TR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trefforest (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBTRE'
              }
            },
            {
              attributes: {
                code: 'GBTRF'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#TY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tyndrum (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBTYL'
              }
            },
            {
              attributes: {
                code: 'GBUTY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wakefield (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWKF'
              }
            },
            {
              attributes: {
                code: 'GBWKK'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welwyn (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWGC'
              }
            },
            {
              attributes: {
                code: 'GBWLW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whyteleafe (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWHS'
              }
            },
            {
              attributes: {
                code: 'GBWHY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wigan (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWGN'
              }
            },
            {
              attributes: {
                code: 'GBWGW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Workington (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWKG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#WL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wallasey (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWLG'
              }
            },
            {
              attributes: {
                code: 'GBWLV'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB#YE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yeovil (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBYVJ'
              }
            },
            {
              attributes: {
                code: 'GBYVP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bangor+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bangor+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Berkhamsted+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berkhamsted+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Birmi Intl+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmi Intl+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bletchley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bletchley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Branksome+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Branksome+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bournemouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bournemouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristl Pwy+Busgb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristl Pwy+Busgb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Barnstaple+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnstaple+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Basingstoke+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Basingstoke+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Tm+Busgb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Tm+Busgb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%BU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burnleymrd+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnleymrd+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%CA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Carmarthen+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carmarthen+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheshunt+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheshunt+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%CO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Coseley+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Coseley+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%CR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Christchurch+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Christchurch+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%DU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Durham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Durham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%GL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gloucester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gloucester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%GR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grtmalvern+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grtmalvern+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%HA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hathersage+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hathersage+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%LE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Letchworth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Letchworth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%LI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Littlehaven+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Littlehaven+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%LL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Llanelli+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanelli+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%LZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 3*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '33A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 3*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%NE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newquay+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newquay+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%PR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%SH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shotton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shotton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%SL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stanford L H+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stanford L H+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stalbansab+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stalbansab+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%WI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Witham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%WO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worcstrstn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worcstrstn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%WR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Workington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Workington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%Z3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U345* Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U345* Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%Z4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U456  Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U456  Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB%ZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U2356 Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U2356 Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Braintree+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braintree+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bury St Edms+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bury St Edms+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chesterfield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chesterfield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&LZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 5*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '55A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 5*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&RZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: '& R1 Zone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '& R1 Zone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB&ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stirling+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stirling+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zone 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zones 1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zones 1-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zones 1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zones 1-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*L6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Underground Zones 1-6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*T2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '12A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 1-2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*T3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 1-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '13A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 1-3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*T4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '14A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 1-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*T5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 1-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '15A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 1-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB*T6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London (All day travelcard) - London Travelcard Zones 1-6',
        timeZone: 'Europe/London',
        type: 'Product'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          groupStation: [
            {
              attributes: {
                code: 'GB@LO'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB-AB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberdeen+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdeen+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-AL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Altrincham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Altrincham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Baldock+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baldock+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bridgwater+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridgwater+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Benfleet+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Benfleet+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Birkenhdctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhdctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackburn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackburn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Banbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Banbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bolton+Busgm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bolton+Busgm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bpool Sth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bpool Sth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Barnsley+Bussy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnsley+Bussy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Basildon+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Basildon+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bathgate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bathgate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burnleyctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnleyctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-BX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buxton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buxton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Caerphilly+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caerphilly+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chester Bustozoo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester Bustozoo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheltenham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheltenham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchr Stns+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchr Stns+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Carlisle+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carlisle+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Castleford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castleford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-CW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cwmbran+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cwmbran+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-DA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Darwen+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darwen+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-DO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dorking+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dorking+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-DR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dorridge+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dorridge+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-DU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunfermline+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunfermline+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-DV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dovercourt+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dovercourt+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-EL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Elstreebhmwd+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elstreebhmwd+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-FL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flint+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flint+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-GL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glossop+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glossop+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-GR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grantham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grantham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harrogate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrogate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hereford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hereford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hitchin+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hitchin+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hertford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hertford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hatfield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hatfield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-HV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Haverfordwst+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haverfordwst+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-KN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knebworth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knebworth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-L2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 2-6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '26A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 2-6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-L4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 4-6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '46A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 4-6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Larbert+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larbert+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leicester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leicester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Larne Harbour NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larne Harbour NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Linlithgow+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Linlithgow+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Llandudnojct+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandudnojct+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Longbridge+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Longbridge+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Thameslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Thameslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-LZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 2*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '22A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 2*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-MA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mansfield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mansfield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-NE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newcastle+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newcastle+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-NW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newhaven+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newhaven+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-PL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Poole+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Poole+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-PO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Polegate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Polegate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-PR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prestatyn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestatyn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-RD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Redruth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Redruth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-RE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Redditch+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Redditch+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-RO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royston+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royston+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stockport+Busgm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stockport+Busgm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shenfield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shenfield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Albans+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Albans+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Solihull+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Solihull+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stourbdgtn+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stourbdgtn+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southend Ctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southend Ctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sutton Cfd+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sutton Cfd+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-SW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swindon+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swindon+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-UN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'University+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'University+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Walthamx+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walthamx+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Welwyn+Bus Wn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welwyn+Bus Wn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wrexham Gen+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wrexham Gen+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitehaven+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitehaven+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Windsorctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windsorctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wolverton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wolverton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Windsorriv+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windsorriv+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wolverhamp+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wolverhamp+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warngtnstn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warngtnstn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Weston-S-M+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weston-S-M+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-WT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Watfordjun+Busgw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watfordjun+Busgw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-Z2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U2345 Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U2345 Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-Z3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U34*  Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U34*  Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-Z4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U45*  Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U45*  Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-Z5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U56   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U56   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB-ZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U234* Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U234* Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB1DA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tower Gateway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '1DA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tower Gateway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Saints',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'All Saints',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackwall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackwall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bow Church',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bow Church',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canary Wharf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Canary Wharf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crossharbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crossharbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Devons Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Devons Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East India',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'East India',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heron Quays',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heron Quays',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Island Gardens',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Island Gardens',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Langdon Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Langdon Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mudchute',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mudchute',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Poplar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Poplar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West India Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West India Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB2DO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Westferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '2DO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Westferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canning Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3BA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Canning Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beckton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beckton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beckton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beckton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Custom House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Custom House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cyprus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cyprus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gallions Reach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gallions Reach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'King George V',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'King George V',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London City Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London City Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontoon Dock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pontoon Dock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prince Regent',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Prince Regent',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Albert',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Royal Albert',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Victoria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Royal Victoria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Star Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Star Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB3DN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Silvertown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '3DN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Silvertown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@AB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberdare+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdare+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@AL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aldershot+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldershot+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@AN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Andover+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Andover+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@AP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Apsley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Apsley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@AY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ayr+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ayr+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ballymoney NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballymoney NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bridgend+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridgend+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bellarena ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bellarena <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBMO'
              }
            },
            {
              attributes: {
                code: 'GBBSW'
              }
            },
            {
              attributes: {
                code: 'GBQQN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Billericay+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Billericay+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackpool (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBPB'
              }
            },
            {
              attributes: {
                code: 'GBBPN'
              }
            },
            {
              attributes: {
                code: 'GBBPS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bromsgrove+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bromsgrove+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bangor NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bangor NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bodminpkwy+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bodminpkwy+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bpool Nth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bpool Nth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bristol (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBPW'
              }
            },
            {
              attributes: {
                code: 'GBBRI'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brighton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBTN'
              }
            },
            {
              attributes: {
                code: 'GBLRB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bath+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burgess Hill+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burgess Hill+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@BX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Broxbourne+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broxbourne+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Carrickfegus ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carrickfegus <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colwyn Bay+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colwyn Bay+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiff Ctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Ctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cuffley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cuffley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chelmsford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelmsford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chippenham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chippenham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Clacton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clacton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Camborne+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camborne+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Congleton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Congleton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chorley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chorley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Conway Park+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Conway Park+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crumlin NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crumlin NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Castlerock ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castlerock <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cullybackey NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cullybackey NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Coventry+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Coventry+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@CW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardiff (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCDB'
              }
            },
            {
              attributes: {
                code: 'GBCDF'
              }
            },
            {
              attributes: {
                code: 'GBCDQ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Darlington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darlington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dudley Pt+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dudley Pt+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Derby+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Derby+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Didcot+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Didcot+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dumfries+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dumfries+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dundee+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dundee+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Doncaster+Bussy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Doncaster+Bussy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dartford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dartford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dublin City',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dublin City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dhu Varren ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dhu Varren <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@DW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dewsbury+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dewsbury+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eaglescliffe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eaglescliffe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ely +Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ely +Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Exter Ctrl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exter Ctrl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@ED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edinburgh+Busge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburgh+Busge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Egham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Egham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Elgin+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elgin+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@EX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exeter (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEXC'
              }
            },
            {
              attributes: {
                code: 'GBEXD'
              }
            },
            {
              attributes: {
                code: 'GBEXT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@FA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Falkirk Grhm+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falkirk Grhm+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@FI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Five Ways+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Five Ways+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@FL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Falmouthtown+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falmouthtown+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@FO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fort William+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fort William+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@GC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Ctrl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Ctrl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@GL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glasgow (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGLC'
              }
            },
            {
              attributes: {
                code: 'GBGLQ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@GR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Greenisland ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greenisland <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@GY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Yarmth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Yarmth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Halifax+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Halifax+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heathrow Rail',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Rail',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hemel H+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hemel H+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hinckley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hinckley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hamilton Sqr+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamilton Sqr+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harwich Intl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich Intl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Horsham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horsham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harpenden+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harpenden+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harwich Town+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich Town+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Huddersfld+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Huddersfld+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Havant+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Havant+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@HW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hawthorns+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawthorns+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@IN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Inverness+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Inverness+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@IO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Isle Of Man+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Isle Of Man+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@IP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ipswich+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ipswich+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@JE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Jewelleryq+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Jewelleryq+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@KE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Keighley+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Keighley+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@KI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kiddermstr+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kiddermstr+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@KL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kilmarnock+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilmarnock+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@KN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kings Lynn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Lynn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@KR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kirkcaldy+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkcaldy+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@L2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 2-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '25A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 2-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@L3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 3-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '35A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 3-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@L4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 4-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '45A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 4-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@L5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 5-6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '56A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 5-6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@L6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 6*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '66A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 6*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lancaster+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lancaster+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lye +Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lye +Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Clipper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Clipper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leeds+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leeds+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leamington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leamington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Rail (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHAF'
              }
            },
            {
              attributes: {
                code: 'GBHWV'
              }
            },
            {
              attributes: {
                code: 'GBHXX'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lincoln+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lincoln+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Llandudno+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandudno+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBFR'
              }
            },
            {
              attributes: {
                code: 'GBCHX'
              }
            },
            {
              attributes: {
                code: 'GBCST'
              }
            },
            {
              attributes: {
                code: 'GBCTK'
              }
            },
            {
              attributes: {
                code: 'GBFST'
              }
            },
            {
              attributes: {
                code: 'GBLBG'
              }
            },
            {
              attributes: {
                code: 'GBMOG'
              }
            },
            {
              attributes: {
                code: 'GBMYB'
              }
            },
            {
              attributes: {
                code: 'GBQQK'
              }
            },
            {
              attributes: {
                code: 'GBQQP'
              }
            },
            {
              attributes: {
                code: 'GBQQU'
              }
            },
            {
              attributes: {
                code: 'GBQQW'
              }
            },
            {
              attributes: {
                code: 'GBSTP'
              }
            },
            {
              attributes: {
                code: 'GBWAE'
              }
            },
            {
              attributes: {
                code: 'GBZLS'
              }
            },
            {
              attributes: {
                code: 'GBZEP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLIV'
              }
            },
            {
              attributes: {
                code: 'GBLVC'
              }
            },
            {
              attributes: {
                code: 'GBLVJ'
              }
            },
            {
              attributes: {
                code: 'GBMRF'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liskeard+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liskeard+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Larne Town NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larne Town NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lurgan ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lurgan <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Livingstn N+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Livingstn N+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lewes+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lewes+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leyland+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leyland+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@LZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zone 1*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '11A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zone 1*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBMCO'
              }
            },
            {
              attributes: {
                code: 'GBMCV'
              }
            },
            {
              attributes: {
                code: 'GBQQM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Macclesfield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Macclesfield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maidenhead+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidenhead+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@ME',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Meadowhall+Bussy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Meadowhall+Bussy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Miltonkeyn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Miltonkeyn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Manch Stns+Busgm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manch Stns+Busgm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Morecambe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Morecambe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merthyr+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merthyr+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@MT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Matlock+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Matlock+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newton Abbot+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton Abbot+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Neath+Bussb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Neath+Bussb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newprt Gwent+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newprt Gwent+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Northamptn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northamptn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Norwich+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norwich+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nuneaton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nuneaton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@NW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@ON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Onley Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Onley Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@OX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Paignton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paignton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Potters Bar+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Potters Bar+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Penzance+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penzance+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Plymouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Plymouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontypridd+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontypridd+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Poyntzpass ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Poyntzpass <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Perth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Perth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@PT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Port Talbot+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Port Talbot+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Radlett+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Radlett+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Reading+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Reading+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Raveng/Esk Rlwy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Raveng/Esk Rlwy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rugby+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rugby+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rhyl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rhyl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rochdale+Busgm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rochdale+Busgm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rotherham+Bussy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rotherham+Bussy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ruabon+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ruabon+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rowley Rgs+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rowley Rgs+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@RY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rayleigh+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rayleigh+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandw & Du+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandw & Du+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scarva ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scarva <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southend (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSOC'
              }
            },
            {
              attributes: {
                code: 'GBSOV'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Seaford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seaford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sheffield+Bussy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sheffield+Bussy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stoke+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stoke+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Slough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Slough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Smethwk Gb+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Smethwk Gb+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandringham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandringham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southampton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSOA'
              }
            },
            {
              attributes: {
                code: 'GBSOU'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stourbdgej+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stourbdgej+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Austell+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Austell+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@ST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stansted (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSSD'
              }
            },
            {
              attributes: {
                code: 'GBSST'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sunderland+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sunderland+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sevenoaks+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sevenoaks+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@SW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swansea+Bussb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swansea+Bussb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Taunton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Taunton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Telford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Telford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Theobaldsg+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Theobaldsg+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tipton+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tipton+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tenby+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tenby+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Torquay+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Torquay+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Truro+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Truro+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@TT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Totnes+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Totnes+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@UN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'University ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'University <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wakefldstn+Buswy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wakefldstn+Buswy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wrexham Ctl+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wrexham Ctl+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wellington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wellington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wiganstns+Busgm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wiganstns+Busgm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitehead NI',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitehead NI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wickford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wickford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Walsall+Buswm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walsall+Buswm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Winchester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winchester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wokingham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wokingham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ware+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ware+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Somerset Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Somerset Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Watfordhs+Busgw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watfordhs+Busgw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wivenhoe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wivenhoe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@WW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Welwyn+Bus Wgc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welwyn+Bus Wgc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@YO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'York+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'York+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@Z2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U23*  Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U23*  Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@Z3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U3*   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U3*   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@Z4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U4*   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U4*   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@Z5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U5*   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U5*   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@Z6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U6*   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U6*   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB@ZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone U2*   Londn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone U2*   Londn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBA11',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Atbristol 1 &Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Atbristol 1 &Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBA26',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southease A26',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southease A26',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SSA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBA2S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any C2C Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any C2C Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAA2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ap Airshow 2012',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ap Airshow 2012',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alloa Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alloa Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Saints (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'All Saints (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any TPE Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Tpe Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alexandra Palace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alexandra Palace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any ATW Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Atw Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Achanalt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Achanalt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Arriva Xcstn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Arriva Xcstn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual All Zones',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual All Zones',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aberdare',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aberdare',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Altnabreac',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Altnabreac',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aberdeen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aberdeen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aber',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aber',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abererch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abererch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ABH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberdeen Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdeen Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Abercarn Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abercarn Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abbey Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abbey Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ABW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBABY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashburys',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashburys',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'NO',
        mainStation: 'NO',
        name: 'Atlantic Coast Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acton Bridge (Cheshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Acton Bridge (Cheshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acton Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Acton Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acocks Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Acocks Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Achnashellach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Achnashellach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Accrington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Accrington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acklington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Acklington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Acle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Achnasheen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Achnasheen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ACN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Accrington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Accrington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ACR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alderney (Channel Islands)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alderney (Channel Islands)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ascot (Berks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ACT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ascot (Berks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBACY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abercynon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abercynon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ACY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Adlington (Cheshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Adlington (Cheshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Adderley Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Adderley Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Admission',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Admission',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ardwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Adlington (Lancashire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Adlington (Lancashire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Adisham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adisham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ADM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardrossan Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ardrossan Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ADDISCOMBE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADDISCOMBE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Airdrie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Airdrie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardrossan Harbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ardrossan Harbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Dutchstation',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Dutchstation',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Andover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ADV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Andover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBADW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Addiewell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Addiewell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ADW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberdare Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdare Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Audley End House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Audley End House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Emt Services',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'All Emt Services',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Air Exp Promo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Air Exp Promo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Paddington HEX Gate Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paddington HEX Gate Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any EMT Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Emt Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashford International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AFK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashford International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alfriston-Polegate Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alfriston-Polegate Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashford (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AFS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashford (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ansdell & Fairhaven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AFV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ansdell & Fairhaven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Adlt Gfood Wknd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adlt Gfood Wknd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAFY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any GWR Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Gwr Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Grand Central Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Grand Central Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Gatwick Express Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Gatwick Express Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ARRIVA GILLINGHM',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARRIVA GILLINGHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abergele & Pensarn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abergele & Pensarn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Angel Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Angel Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Argyle Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Argyle Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldrington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aldrington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abergavenny',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abergavenny',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AGV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Adlt Gfood Wkdy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adlt Gfood Wkdy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAGY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any GNE Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Gne Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashtead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashtead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashton-under-Lyne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashton-under-Lyne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashurst (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashurst (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldershot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aldershot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ash Vale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AHV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ash Vale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Hull Trains Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Hull Trains Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAI2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Airshow 2012',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Airshow 2012',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAI3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Airdrie 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Airdrie 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Abertillery Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abertillery Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aigburth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AIG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aigburth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Advnt Isle Junr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Advnt Isle Junr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Island Line Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Island Line Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aintree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aintree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Airbles',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Airbles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Chiltern Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Chiltern Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAIW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Advn Island Wagn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Advn Island Wagn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAL3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alloa 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alloa 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alpamare',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alpamare',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Albrighton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Albrighton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alderley Edge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alderley Edge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alfreton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alfreton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldeburgh (via Saxmundham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldeburgh (via Saxmundham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ALG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alnmouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alnmouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aldrington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldrington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aslockton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aslockton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lichfield Trent Valley High Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lichfield Trent Valley High Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alnmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alnmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Althorne (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Althorne (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alloa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alloa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ALO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Althorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Althorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alresford (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alresford (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Altrincham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Altrincham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alvechurch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ALV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Alvechurch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Allens West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Allens West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ALW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alexandria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alexandria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ALX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Ldn Ovgd Stn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Ldn Ovgd Stn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBALZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Allerton Zonetkt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Allerton Zonetkt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ambergate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ambergate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ammanford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AMF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ammanford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Acton Main Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Acton Main Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Abraham Mossmtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abraham Mossmtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any West Midlands Trains Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any West Midlands Trains Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashton Moss Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashton Moss Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Amersham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Amersham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldermaston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldermaston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Anglia Meal Vchr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anglia Meal Vchr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Amberley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Amberley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAN4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LPOOL S PWAY HL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LPOOL S PWAY HL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Addenbrookes Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Addenbrookes Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ancaster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ANC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ancaster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Anderston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Anderston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Anfield&Goodison',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anfield&Goodison',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashurst New Forest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ANF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashurst New Forest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Angmering',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ANG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Angmering',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Anchorage Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anchorage Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ANH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Adelaide (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adelaide (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Anniesland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ANL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Anniesland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Annan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ANN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Annan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Anglia Plus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anglia Plus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ainsdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ainsdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ANS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'AIR TRVL INTL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AIR TRVL INTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Nthn W Stn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Nthn W Stn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Anglia Exchange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anglia Exchange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Nthn E Stn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Nthn E Stn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBANZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Anerley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anerley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ANZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aboyne Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aboyne Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Atlantic Coast Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Atlantic Coast Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Greater Anglia Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Greater Anglia Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Anglia Plus-Atp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Anglia Plus-Atp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Appley Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Appley Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Appledore (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Appledore (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Appleford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Appleford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aspley Guise',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aspley Guise',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newcastle Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newcastle Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Appleby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Appleby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Apsley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'APS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Apsley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Adderley P Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adderley P Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAPY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Apperley Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Apperley Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'APY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAR1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ARRIVA WATFORD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arriva Watford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAR3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberdour/Rsyth 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdour/Rsyth 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Skye (Armadale)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Skye (Armadale)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arbroath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arbroath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Arnhem (CIV)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arnhem (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardgay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ardgay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Arlesey+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arlesey+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arisaig',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arisaig',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Arriva Hemel Hempstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arriva Hemel Hempstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arlesey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arlesey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Armadale (West Lothian)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Armadale (West Lothian)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arnside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arnside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alvechurch Red Lion',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alvechurch Red Lion',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ARP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arram',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arram',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBART',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arrochar & Tarbet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arrochar & Tarbet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ART',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arundel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Arundel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBARV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ARRIVAONE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ARRIVAONE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Merseyrail Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Merseyrail Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardrossan South Beach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ardrossan South Beach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ASB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashchurch for Tewkesbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashchurch for Tewkesbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'As Advertised',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'As Advertised',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alfriston-Seaford Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alfriston-Seaford Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ashfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alsager',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alsager',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ASG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ash',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'NO',
        mainStation: 'NO',
        name: 'Ashford International (CIV)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Askam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Askam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Audenshaw Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Audenshaw Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Addlestone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Addlestone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ASN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aspatria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ASP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aspatria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ashford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ashford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ASS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Advnt Isle Big',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Advnt Isle Big',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBASY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ashley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ASY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAT2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Southern Train',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Southern Train',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAT3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Thameslink Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Thameslink Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAT4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Thameslink or Great Northern station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Thameslink or Great Northern station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Southern Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Southern Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Attenborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ATB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Attenborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Asc Trolley Vcr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Asc Trolley Vcr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Atherstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ATH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Atherstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Swt Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Swt Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Attleborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ATL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Attleborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Atherton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Atherton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ATN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Scotrail Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Scotrail Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Attadale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ATT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Attadale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBATW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Alton Towers',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alton Towers',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Appleddore Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Appleddore Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Audley End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Audley End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aughton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AUG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aughton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardlui',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ardlui',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AUI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Auchinleck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Auchinleck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AUK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashton U L Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ashton U L Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Arsenal (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arsenal (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aberdour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any Southeastern Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Southeastern Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Atw Rugby Train',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Atw Rugby Train',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAUW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ascott-under-Wychwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ascott-under-Wychwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AUW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Avon Valley Cnc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Avon Valley Cnc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Avoncliff',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AVF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Avoncliff',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aviemore',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aviemore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AVM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Avonmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Avonmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Abingdon (via Oxford) Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abingdon (via Oxford) Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aylesbury Vale Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AVP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aylesbury Vale Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Avon Valley Rly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Avon Valley Rly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAVY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aberdovey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberdovey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AVY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ABBEY WOOD CROSSRAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ABBEY WOOD CROSSRAIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Adwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Adwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AWK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashton West Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ashton West Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ashwell & Morden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ashwell & Morden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Wagn Service',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'All Wagn Service',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Armathwaite',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Armathwaite',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Any WCML Stn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Any Wcml Stn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAXM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Axminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AXM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Axminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAXP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Alexandra Parade',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Alexandra Parade',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AXP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aberystwyth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberystwyth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Aylesbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aylesbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aylesham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AYH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aylesham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aylesford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Aylesford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Albany Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Albany Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ayr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'AYR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aylesbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aylesbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAYW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aberystwyth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aberystwyth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'AYW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual Zone 1*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual Zone 1*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual Zone*2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual Zone*2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual Zone 123*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual Zone 123*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual Zone 12*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual Zone 12*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Annual Zone1234',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Annual Zone1234',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Zone Student',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'All Zone Student',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBAZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'All Zone Unizone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'All Zone Unizone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB1B',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackp & Bustour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackp & Bustour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB1D',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 15 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 15 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB1M',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 1 Month',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 1 Month',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB2D',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 22 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 22 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB6D',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buck P Rd A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buck P Rd A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBB6S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Baker St A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baker St A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBA2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Britrail Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Britrail Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBA4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Britleisure Fam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Britleisure Fam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBA6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Baker Street A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baker Street A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Barnham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Balcombe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Balcombe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bache',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bache',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Banstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Banstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bluewater Staff',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bluewater Staff',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bagshot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bagshot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bank Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bank Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blairhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blairhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baglan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baglan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BAJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Battersea Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Battersea Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Balham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Balham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bamford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bamford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Banbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Banbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bare Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bare Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bere Alston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bere Alston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Battle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Battle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barton-on-Humber',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barton-on-Humber',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barrow Haven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrow Haven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackwater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackwater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bayford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bayford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBB2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Benfleet Bus 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Benfleet Bus 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBB3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bridport via First Bus X51/X53',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridport via First Bus X51/X53',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBB7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bury St Edmonds Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bury St Edmonds Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBB8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bedford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBB9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brighton & Hove+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brighton & Hove+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Berlin Stadtbahn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berlin Stadtbahn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Battle Abbey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Battle Abbey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BT CIB DUNDEE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BT CIB DUNDEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brentwood Bus 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brentwood Bus 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bishopbriggs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bishopbriggs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buckngham Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buckngham Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Buslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Buslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bilbrook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bilbrook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bat & Ball',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bat & Ball',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bedford Bus MK',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedford Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackburn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackburn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bluebell Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bluebell Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bordesley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bordesley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bicester Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ballater Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballater Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bicester North or Bicester Village',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester North or Bicester Village',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berry Brow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BBW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Berry Brow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBBZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bicester Bus Zn2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester Bus Zn2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBC1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Empire C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Empire C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Braehead Ctr Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braehead Ctr Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burscough Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burscough Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beccles',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beccles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beckton (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beckton (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bracknell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bracknell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beaconsfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beaconsfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birchgrove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birchgrove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birchington-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birchington-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Conf Ctr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Conf Ctr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burscough Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burscough Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buckley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Buckley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bracknell+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bracknell+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Branchton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Branchton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bicester North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bicester North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brockenhurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brockenhurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bruce Grove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bruce Grove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBCY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brockley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BCY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brockley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBD1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Botanic (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Botanic (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBD6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buck P Road A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buck P Road A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brundall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brundall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broadbottom',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Broadbottom',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brodick (Arran)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brodick (Arran)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackhorse Road (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackhorse Road (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bodmin Mount Folly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bodmin Mount Folly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridgeton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bridgeton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedhampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bedhampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bradford Interchange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bradford Interchange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baldock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baldock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BDK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birkdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bedford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brading',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brading',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Britrail Dup Pas',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Britrail Dup Pas',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bradford Forster Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bradford Forster Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bridlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brockhurst Drbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brockhurst Drbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedwyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bedwyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bredbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bredbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bremen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bremen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brighton Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brighton Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BritRail England',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BritRail England',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 4 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 4 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 6 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 6 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 8 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 8 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBE9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BT CIB DUNDEE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BT CIB DUNDEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridge of Allan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bridge of Allan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bebington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bebington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beckenham Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beckenham Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Britleisure Disc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Britleisure Disc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beeston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beeston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Benfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Benfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beltring',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beltring',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bognor Regis+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bognor Regis+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Berks+Hnts+W.S.R',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berks+Hnts+W.S.R',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beauly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beauly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bempton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bempton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bentham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bentham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beeston+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beeston+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bermuda Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bermuda Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bearley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bearley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bescar Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bescar Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bethnal Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bethnal Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beaulieu Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beaulieu Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BEU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beverley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Beverley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Berkswell Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berkswell Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bexhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BEX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bexhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ben Rhydding',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ben Rhydding',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belfast Port',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belfast Port',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BFA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Benfleet Bus1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Benfleet Bus1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belfast Central ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belfast Central <Nir>',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BFC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brentford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brentford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bere Ferrers',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bere Ferrers',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BFE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blaenau Ffestiniog',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blaenau Ffestiniog',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Ferry Hop-On Hop-Off',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Ferry Hop-On Hop-Off',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Ferry Return',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Ferry Return',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brdge Of Allan 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brdge Of Allan 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bath Tour Family',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath Tour Family',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Byfleet & New Haw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Byfleet & New Haw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Blackfriars',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BFR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Blackfriars',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bradford Forster Square or Interchange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bradford Forster Square or Interchange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Ferry Single',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Ferry Single',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBFU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blaenau Ffestiniog (Ffestiniog Railway)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blaenau Ffestiniog (Ffestiniog Railway)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBG1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bittern Srn Rgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bittern Srn Rgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brundall Gardens',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brundall Gardens',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Big Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Big Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bargoed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bargoed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BGD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broad Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Broad Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brigg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brigg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brighouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brighouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bargeddie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bargeddie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BGI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baguley Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baguley Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BGK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bugle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bugle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bellingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bellingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridgend',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bridgend',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bishop Train & Bus Group',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bishop Train & Bus Group',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bogston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bogston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Biggleswade+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Biggleswade+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Banchory Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Banchory Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Balloch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Balloch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brithdir',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brithdir',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BHD NTH/GREENLN',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bhd Nth/Greenln',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BURFORD HANBOROUGH BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BURFORD HANBOROUGH BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bathgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bathgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BHG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bush Hill Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bush Hill Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Birmingham New St, Moor St or Snow Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmingham New St, Moor St or Snow Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackhorse Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackhorse Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Builth Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Builth Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brockholes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brockholes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bath Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBHX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birmingham International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBI2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Briteng2Dayin2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Briteng2Dayin2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bishop Auckland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bishop Auckland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Basildon Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Basildon Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Billericay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Billericay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bidston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bidston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Belfast (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belfast (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barrow-in-Furness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrow-in-Furness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BIF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Billingshurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Billingshurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blickling Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blickling Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkbeck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birkbeck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Billingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Billingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baillieston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baillieston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BIO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bishopstone (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bishopstone (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ballycarry (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballycarry (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bishops Stortford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bishops Stortford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bicester Village',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester Village',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bicester Village (via shuttle bus)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester Village (via shuttle bus)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Biggleswade',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Biggleswade',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BIW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBIY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bingley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bingley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BIY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bookham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bookham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackwood Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackwood Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhead Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blakedown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blakedown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Breakfast',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Breakfast',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barking',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barking',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackheath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackheath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead 12 Quays',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhead 12 Quays',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beckenham Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beckenham Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bickley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bickley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berkhamsted',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berkhamsted',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhead North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brookwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brookwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhead Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead Hamilton Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birkenhead Hamilton Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blackridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bekesbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bekesbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blake Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blake Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bowker Vale Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BKV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bowker Vale Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBKW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berkswell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berkswell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BKW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBL2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackridge 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackridge 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBL3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bishops Lydeard (West Somerset Railway)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bishops Lydeard (West Somerset Railway)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBL4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Battersea (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Battersea (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blair Atholl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blair Atholl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Battlesbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Battlesbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brecon Llo Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brecon Llo Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baildon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baildon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bramley (West Yorkshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bramley (West Yorkshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blckplsealifectr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blckplsealifectr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bellgrove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bellgrove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bellshill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bellshill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackrod',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackrod',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bardon Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bardon Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belmont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belmont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blundellsands & Crosby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Blundellsands & Crosby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blaydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blaydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Britleisure Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Britleisure Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blantyre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blantyre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bluewater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bluewater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belle Vue',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belle Vue',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bulwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bulwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bloxwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bloxwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bletchley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bletchley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBM1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Breakfast C Emt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Breakfast C Emt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bamber Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bamber Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromley Cross (Lancs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromley Cross (Lancs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brimsdown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brimsdown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBME',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broome',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BME',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Broome',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broomfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Broomfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barming',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barming',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BMG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bournemouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bournemouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bristol Empire',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Empire',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bramhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bramhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barlow M Rd Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barlow M Rd Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromley North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromley North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham Moor Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmingham Moor Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brampton (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brampton (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromborough Rake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromborough Rake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromley South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromley South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromsgrove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromsgrove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bramley (Hampshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bramley (Hampshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN0',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bonn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bonn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brit Eng 2 Days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brit Eng 2 Days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Balmoral (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Balmoral (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beddington Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beddington Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBN5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bishop Train & Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bishop Train & Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnage',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burnage',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brecon Nwt Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brecon Nwt Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnley Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burnley Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brandon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brandon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bourne End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bourne End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Briton Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Briton Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bangor (Gwynedd)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bangor (Gwynedd)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnehurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnehurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnes Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnes Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Benchill Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Benchill Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnham (Buckinghamshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burnham (Buckinghamshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ballinderry (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballinderry (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnstaple',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnstaple',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brockley Whins',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brockley Whins',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brinnington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brinnington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Banavie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Banavie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bootle New Strand',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bootle New Strand',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnsley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnsley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBNZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Brighton Unizone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brighton Unizone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBO1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Book',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Book',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBO2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Borders Explorer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Borders Explorer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bradford-on-Avon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bradford-on-Avon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Besses O\' Th\' Barn Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Besses O\' Th\' Barn Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bootle (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bootle (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bodmin Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bodmin Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Botley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Botley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bedford Midland or Bedford St Johns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedford Midland or Bedford St Johns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bognor Regis',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bognor Regis',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bosham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bosham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Broads Boattrain',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broads Boattrain',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brooklands Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brooklands Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Barlastn Orch Dv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barlastn Orch Dv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bromborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bolton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bolton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bowes Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bowes Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bodorgan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bodorgan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bootle Oriel Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bootle Oriel Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Big Bus Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Big Bus Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBOV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bittern Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bittern Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackpool Pleasure Beach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackpool Pleasure Beach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BPB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bath Spa Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath Spa Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beckton Park (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beckton Park (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BRI PARIS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRI PARIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brookmans Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brookmans Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPL',
        countryCode: 'GB',
        fareStation: 'NO',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barlaston Orchard Drive',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barlaston Orchard Drive',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackpool North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackpool North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BPN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buck P Rd A6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buck P Rd A6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blackpool South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackpool South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BPS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bishopton (Renfrewshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bishopton (Renfrewshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bristol Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BPW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bristol Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blackwall (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blackwall (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bootle New Strand or Oriel Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bootle New Strand or Oriel Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Briteng3Dayin2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Briteng3Dayin2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Briteng4Dayin2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Briteng4Dayin2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Briteng15Dayin2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Briteng15Dayin2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buck P Rd A6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buck P Rd A6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bow Church (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bow Church (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Briteng8Dayin2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Briteng8Dayin2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBR9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buckley Ctr Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buckley Ctr Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brora',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brora',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Breich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Breich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broadway Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Broadway Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brentwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brentwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brierfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Brierfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Borough Green & Wrotham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Borough Green & Wrotham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Borth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Borth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bristol Temple Meads',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bristol Temple Meads',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berwick (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berwick (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barrhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bearsden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bearsden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridge of Orchy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridge of Orchy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brampton (Suffolk)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brampton (Suffolk)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barrhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berrylands',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berrylands',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barlaston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barlaston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bruton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bruton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bournville',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bournville',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brunswick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brunswick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brixton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brixton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBRZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burton Road Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BRZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burton Road Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBS3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bognor Regis Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bognor Regis Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBS6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Baker St A6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baker St A6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBS7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Braemar Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braemar Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBS8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Braintree Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braintree Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Baker St A6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baker St A6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bleasby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bleasby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bescot Stadium',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bescot Stadium',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bearsted',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bearsted',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bury St Edmunds',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BSE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bury St Edmunds',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bushey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bushey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Balmossie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Balmossie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bedford St Johns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bedford St Johns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Basingstoke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Basingstoke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Beasdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beasdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Branksome',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Branksome',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Boston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Basildon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Basildon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brondesbury Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brondesbury Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broadstairs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broadstairs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barassie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barassie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brunstane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brunstane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buckshaw Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BSV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Buckshaw Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham Snow Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmingham Snow Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brondesbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brondesbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBSZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bond Street Crossrail',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bond Street Crossrail',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBT1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burnham Mkt Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnham Mkt Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBT2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bath Tour Redctn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath Tour Redctn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnetby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnetby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BROMLEY STH TCAP',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BROMLEY STH TCAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bolton-Upon-Dearne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bolton-Upon-Dearne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bitterne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bitterne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bottesford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bottesford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barnt Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barnt Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bath Spa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bath Spa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Broadstairs+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broadstairs+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Batley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Batley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brighton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brighton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Betchworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Betchworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Braintree Freeport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braintree Freeport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Braintree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braintree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burntisland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burntisland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Battersby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Battersby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Beatles Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Beatles Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bicester Taxibus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bicester Taxibus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bentley (Hampshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bentley (Hampshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Biddenden Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Biddenden Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bideford Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bideford Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Biggleswade Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Biggleswade Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blairgowrie Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blairgowrie Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blake St WM bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blake St WM bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBU9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Blandford Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blandford Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Buckfastleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buckfastleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnley Barracks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burnley Barracks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buckenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Buckenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burneside (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burneside (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bures',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bures',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Big Bus Tour Family',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Big Bus Tour Family',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burgess Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burgess Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Brough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnside (South Lanarkshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burnside (South Lanarkshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burton Joyce',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Burton Joyce',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bucknell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bucknell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Butlers Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Butlers Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Burnham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bursledon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bursledon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bury Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bury Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Busby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Busby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burton-on-Trent',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burton-on-Trent',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnham-on-Crouch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnham-on-Crouch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burley-in-Wharfedale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burley-in-Wharfedale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buxton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BUX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Buxton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burley Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burley Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BUY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBUZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bury St Buszone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bury St Buszone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Belvedere',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Belvedere',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BVD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bure Valley Rly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bure Valley Rly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBVF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bordon (via Farnham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bordon (via Farnham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bordon (via Haslemere)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bordon (via Haslemere)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BRIDGNORTH SVR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridgnorth Svr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bow Brickhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bow Brickhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birchwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birchwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bluewt Ensignbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bluewt Ensignbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bowling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bowling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bodmin & Wenford Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bodmin & Wenford Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berwick-upon-Tweed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berwick-upon-Tweed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bescot Sta Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bescot Sta Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bloxwich North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bloxwich North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bricket Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bricket Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bangor West (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bangor West (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barrow-upon-Soar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barrow-upon-Soar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bridgwater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridgwater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBWU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Butlers La Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Butlers La Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broxbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broxbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Buxted',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Buxted',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bexleyheath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bexleyheath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bexhill+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bexhill+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Box Hill & Westhumble',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Box Hill & Westhumble',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Boxhill Burfd Br',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boxhill Burfd Br',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBXY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bexley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bexley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BXY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Berney Arms',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Berney Arms',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Blythe Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Blythe Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Betws-y-Coed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Betws-y-Coed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barry Docks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barry Docks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bynea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BYE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Bynea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Broughty Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Broughty Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barry Island',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barry Island',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bentley (South Yorkshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bentley (South Yorkshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barry Links',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barry Links',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Burnley Manchester Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Burnley Manchester Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bryn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bryn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Braystones',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Braystones',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBYU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Barry+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barry+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Boundary Zone 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boundary Zone 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBZ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Boundary Zone 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boundary Zone 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBZ4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Boundary Zone 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boundary Zone 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBZ5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Boundary Zone 5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Boundary Zone 5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBBZJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'BRISTOL ZOO J564',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bristol Zoo J564',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBC1A',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro 1Wk Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro 1Wk Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBC2A',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro 2Wk Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro 2Wk Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBC4A',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro 4Wk Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro 4Wk Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCA1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro One Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro One Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coventry Arena',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coventry Arena',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Caernarfon Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caernarfon Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caldercruix',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Caldercruix',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cadoxton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cadoxton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Camberley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camberley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Calsleeper Flexi',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Calsleeper Flexi',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carrbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Carrbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cark & Cartmel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cark & Cartmel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crawley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crawley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Camberley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Camberley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carnoustie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Carnoustie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cannock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cannock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CAO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carlisle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Carlisle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castleton (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Castleton (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caterham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Caterham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Causeland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Causeland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cadbury World',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cadbury World',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carntyne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carntyne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCB1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chelmsford Bus 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelmsford Bus 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCB2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chelmsford Bus 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelmsford Bus 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCB3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchester Bus 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchester Bus 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCB4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Camber Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camber Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carbis Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carbis Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coatbridge Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coatbridge Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Conon Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Conon Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canterbury East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canterbury East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cambridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cambridge Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge Bus Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge Bus Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cranbrook (Devon)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cranbrook (Devon)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cambuslang',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambuslang',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Camborne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camborne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castle Bar Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castle Bar Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cooksbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cooksbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coatbridge Sunnyside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coatbridge Sunnyside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Campbeltown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Campbeltown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Caerphilly Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caerphilly Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canterbury West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canterbury West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CBW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cumbria Bus Xplr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cumbria Bus Xplr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Charlbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Charlbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCBZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Christchurch Bus Zone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Christchurch Bus Zone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canvey Card',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canvey Card',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Criccieth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CCC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Criccieth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiff Capital Card',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Capital Card',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chelt Rc & Race',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelt Rc & Race',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiff Conf Ctr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Conf Ctr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chichester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chichester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Camb Citybustour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camb Citybustour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cumbrian Coast Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cumbrian Coast Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cathcart',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cathcart',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCCX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambrian Cst Exp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambrian Cst Exp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCD1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'CLV CST & WTB DR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLV CST & WTB DR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cumbria Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cumbria Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardiff Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiff Canton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Canton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardenden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardenden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheshire Day Rgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheshire Day Rgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardiff Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crediton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CDI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crediton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crossharbour (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crossharbour (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Camelford Bdmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camelford Bdmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coulsdon Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coulsdon Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardonald',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardonald',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardiff Queen Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Queen Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coulsdon South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CDS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coulsdon South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caldicot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caldicot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cam & Dursley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cam & Dursley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Conwy Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Conwy Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cartsdyke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cartsdyke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCE1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Central Scotland Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Central Scotland Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCE3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chiltern Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chiltern Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCE8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheltenham Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheltenham Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cleland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cleland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotwold Line Crd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotwold Line Crd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cheddington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cheddington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cleethorpes+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cleethorpes+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chapel-en-le-Frith',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chapel-en-le-Frith',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coleshill Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coleshill Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chelford (Cheshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chelford (Cheshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Centrl Park Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Centrl Park Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge North+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge North+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canterbury East or Canterbury West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canterbury East or Canterbury West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambrian Eve Rgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambrian Eve Rgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cressing (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cressing (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colchester Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Colchester Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crewe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crewe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswoldbus Eve1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswoldbus Eve1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canley Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canley Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cononley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cononley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Catford Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Catford Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CFB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corfe Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Corfe Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castleford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castleford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Comm Rail Fest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Comm Rail Fest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Croftfoot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Croftfoot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chafford Hundred Lakeside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chafford Hundred Lakeside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crossflatts',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crossflatts',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cwl Psspt To Fdm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cwl Psspt To Fdm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clifton Down',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clifton Down',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chalfont & Latimer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chalfont & Latimer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chandlers Ford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chandlers Ford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crofton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CFT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crofton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCFV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cosford Food Festival',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cosford Food Festival',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCGD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Craigendoran',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CGD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Craigendoran',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cottingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cottingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cogan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cogan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caergwrle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caergwrle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CGW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eastleigh+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastleigh+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clayton Hal Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clayton Hal Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Charing Cross (Glasgow)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Charing Cross (Glasgow)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chesterfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chesterfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cheam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cheam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Church Fenton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Church Fenton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Charing (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Charing (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Christs Hospital',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Christs Hospital',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chingford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Chingford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chiswick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chiswick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chilworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chilworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chelmsford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelmsford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cheshunt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheshunt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cholsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cholsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chipstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chipstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Christchurch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Christchurch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chatham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chatham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chathill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chathill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cheadle Hulme',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheadle Hulme',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chalkwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chalkwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Charing Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Charing Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chertsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chertsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCHZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chester Zoo&Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester Zoo&Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCI1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiff Air Ria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiff Air Ria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCI2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cumbria Round Robin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cumbria Round Robin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCI3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Calais',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Calais',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCI4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'CHINNOR RAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHINNOR RAIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cultra (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cultra (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chichester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chichester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chilham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chilham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cilmeri',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cilmeri',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CIM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheshire Oaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheshire Oaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caledonian Road & Barnsbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caledonian Road & Barnsbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chislehurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chislehurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colnbrook Branch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colnbrook Branch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corkerhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Corkerhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corkickle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Corkickle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crewkerne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crewkerne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clarkston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clarkston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crookston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crookston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCKY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crosskeys',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CKY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crosskeys',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCL3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Caledonian Solo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caledonian Solo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCL4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cracker Me Only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cracker Me Only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCL5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Callander Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Callander Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clandon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clandon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castle Cary',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castle Cary',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chelsfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chelsfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cleethorpes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cleethorpes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Claygate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Claygate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clitheroe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clitheroe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clifton (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clifton (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clapham Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clapham Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clock House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Clock House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Collington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Collington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Collingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Collingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chapeltown (South Yorkshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chapeltown (South Yorkshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clapham High Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clapham High Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clarbeston Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clarbeston Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chester-le-Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester-le-Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clacton-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clacton-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carluke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carluke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Claverdon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Claverdon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chorleywood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chorleywood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chinley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chinley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCLZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchesterzoo676',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchesterzoo676',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCM1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCM2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswoldbus Mim1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswoldbus Mim1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCM3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswoldbus Mim3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswoldbus Mim3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro Trm Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro Trm Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cambridge North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambrian Coaster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambrian Coaster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Camden Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camden Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCME',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Combe (Oxon)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CME',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Combe (Oxon)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cromford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CMF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cromford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cwmbach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cwmbach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crossacres Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crossacres Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carmyle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carmyle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carmarthen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carmarthen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Camelon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Camelon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cromer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cromer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge or Cambridge North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge or Cambridge North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cnm Meals V Cnm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cnm Meals V Cnm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crossmyloof',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crossmyloof',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCN1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheltenham Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheltenham Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCN2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chippenham Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chippenham Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canterbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canterbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cannock+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cannock+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Colne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carnforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carnforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Congleton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Congleton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Clipperstown (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clipperstown (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chorlton Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chorlton Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cheltenham Spa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheltenham Spa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canonbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canonbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chetnole',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chetnole',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Conway Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Conway Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crianlarich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crianlarich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Conisbrough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Conisbrough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Conwy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Conwy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cantley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cantley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCO2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro Ann Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro Ann Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCO3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cosford Air Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cosford Air Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCO4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ctfc Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ctfc Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCO5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Corby+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Corby+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coatdyke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coatdyke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cooden Beach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cooden Beach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Catford or Catford Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Catford or Catford Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coombe Junction Halt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coombe Junction Halt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crosskeys Tonbge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crosskeys Tonbge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crowborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crowborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crosshill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crosshill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colchester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Colchester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Commondale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Commondale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Connel Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Connel Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cookham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cookham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Copplestone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Copplestone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Corby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cosford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cosford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cottingley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cottingley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coventry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Coventry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cowdenbeath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cowdenbeath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cockermouth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cockermouth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coryton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Coryton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'COY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCOZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cornbrook Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cornbrook Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'COZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCP4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cupar 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cupar 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corpach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Corpach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chauff Punt Camb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chauff Punt Camb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caerphilly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caerphilly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cleeth Psure Isl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cleeth Psure Isl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carpenders Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carpenders Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chippenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chippenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chapelton (Devon)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chapelton (Devon)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Car Parking',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Car Parking',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clapton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clapton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Capenhurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Capenhurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chepstow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chepstow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCPY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clapham (North Yorkshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clapham (North Yorkshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CPY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCR0',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cheltenham Races 05',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cheltenham Races 05',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCR2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Custom House (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Custom House (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cradley Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cradley Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Corbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chester Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crewe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crewe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carfin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carfin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cross Gates',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cross Gates',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crouch Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crouch Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cricklewood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cricklewood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crumpsall Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crumpsall Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chirk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chirk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chorley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chorley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cramlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cramlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crowthorne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Crowthorne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Croy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Croy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cairnryan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cairnryan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cemetery Rd Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cemetery Rd Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Corrour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Corrour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carstairs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carstairs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chartham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chartham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Craignure (Mull)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Craignure (Mull)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Craven Arms',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Craven Arms',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crawley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crawley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crayford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crayford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCRZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardiffzone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardiffzone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCS1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchester Bus 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchester Bus 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCS2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchester Bus 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchester Bus 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCS6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cumbernauld Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cumbernauld Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cosham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cosham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carshalton Beeches',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carshalton Beeches',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'City Sights Camb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'City Sights Camb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cobham & Stoke D\'Abernon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cobham & Stoke D\'Abernon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cressington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cressington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carshalton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carshalton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Calstock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Calstock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Codsall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Codsall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castleton Moor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castleton Moor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chessington North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chessington North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Croston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Croston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cst Pks4In8Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cst Pks4In8Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chassen Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chassen Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chessington South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chessington South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Cannon Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Cannon Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chestfield & Swalecliffe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chestfield & Swalecliffe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Coseley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Coseley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCT6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'CHILTERN RTP',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CHILTERN RTP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castlebay (Barra)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castlebay (Barra)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'City Cruises',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'City Cruises',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canning Town (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canning Town (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chatelherault',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chatelherault',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Catford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Catford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canning Town (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canning Town (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chadwell Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chadwell Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'City Thameslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'City Thameslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cattal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cattal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chatham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chatham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Charlton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Charlton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carlton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carlton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Comp Tea Coffee',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Comp Tea Coffee',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Church Stretton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Church Stretton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Church & Oswaldtwistle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Church & Oswaldtwistle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Centro Termextra',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Centro Termextra',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'City Days Out Glasgow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'City Days Out Glasgow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Carnforth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carnforth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Caterham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caterham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cirencester Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cirencester Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Clitheroe Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clitheroe Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Clovelly Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clovelly Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Coltishall Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Coltishall Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cranbrook Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cranbrook Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCU9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Crieff Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crieff Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Culrain',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Culrain',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cumbernauld',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cumbernauld',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cuddington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cuddington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'City Days Out Edinburgh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'City Days Out Edinburgh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cuffley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cuffley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Curriehill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Curriehill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cumbrae',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cumbrae',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Culham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Culham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colliers Wd Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colliers Wd Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cupar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cupar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chess Woa Adult',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chess Woa Adult',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clunderwen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clunderwen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CUW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCUX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cuxton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CUX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cuxton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCV1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Clacton Bus Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clacton Bus Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCV3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswoldbus Eve3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswoldbus Eve3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canvey Bus1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canvey Bus1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cherwell Valley Day Ranger (Banbury-Didcot)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cherwell Valley Day Ranger (Banbury-Didcot)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Central Scotland Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Central Scotland Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cherwell Valley Day Ranger (Banbury-Reading)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cherwell Valley Day Ranger (Banbury-Reading)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Conwy Valley Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Conwy Valley Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cambridge Bus Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cambridge Bus Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'CAMBRIAN REVIVAL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAMBRIAN REVIVAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCVW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cherwell Valley Day Ranger (Banbury-Oxford)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cherwell Valley Day Ranger (Banbury-Oxford)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCW1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Colchester or Colchester Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colchester or Colchester Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCW2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canada Water (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canada Water (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCW3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'CLV CST & WTB FR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CLV CST & WTB FR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colwyn Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colwyn Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chappel & Wakes Colne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chappel & Wakes Colne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Creswell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Creswell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crowle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crowle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'CANARY WHARF CROSSRAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canary Wharf Crossrail',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crews Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crews Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colwall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Colwall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cwmbran',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cwmbran',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cowden (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cowden (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswolds Wildlife Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswolds Wildlife Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canary Wharf (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canary Wharf (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Caersws',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Caersws',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crowhurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crowhurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CWU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Canary Wharf (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canary Wharf (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCX1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswold Explorer 1-Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswold Explorer 1-Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCX3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cotswold Explorer 3-Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cotswold Explorer 3-Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCXB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cressexpress Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cressexpress Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCXG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'NO',
        mainStation: 'NO',
        name: 'Croxley Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cefn-y-Bedd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cefn-y-Bedd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cyprus (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cyprus (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cardigan Ayw Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cardigan Ayw Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clydebank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clydebank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CYK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cynghordy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cynghordy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Crystal Palace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Crystal Palace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cathays',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cathays',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBCYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cherry Tree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cherry Tree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daytripper Famly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daytripper Famly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalgety Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dalgety Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalmarnock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dalmarnock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalmally',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dalmally',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalmeny',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalmeny',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Darnall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Darnall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dytripper Add-On',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dytripper Add-On',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Promo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Promo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Darlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Darlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Datchet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Datchet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Day Validity',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Day Validity',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daytripper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daytripper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDB1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'DOVER BR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dover Br',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Debenham Busycle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Debenham Busycle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumbarton Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dumbarton Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Denby Dale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Denby Dale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumbarton East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dumbarton East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mottisfont & Dunbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mottisfont & Dunbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunblane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunblane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dengiebus B-On-C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dengiebus B-On-C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Derby Road (Ipswich)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Derby Road (Ipswich)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Doune Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Doune Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dovercourt Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dovercourt Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Derby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Derby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDCG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duncraig',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DCG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duncraig',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorchester South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dorchester South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Danescourt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Danescourt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDCW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorchester West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DCW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dorchester West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dun Laoghaire (Civ)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dun Laoghaire (Civ)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dorridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dagenham Dock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dagenham Dock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DDK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Darley Dale (Peak Rail)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darley Dale (Peak Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Devon Day Out',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Devon Day Out',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dudley Port',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DDP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dudley Port',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dorking, Dorking West or Deepdene',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dorking, Dorking West or Deepdene',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Deal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Deal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dereham Coach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dereham Coach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dundee',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dundee',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dereham Konectbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dereham Konectbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dean (Wilts)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dean (Wilts)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Deptford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DEP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Deptford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Rambler',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Rambler',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dengiebus Sthmst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dengiebus Sthmst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Debden (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Debden (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dewsbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dewsbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dartford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dartford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunfermline Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DFE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunfermline Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duffield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DFI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duffield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunfermline Queen Margaret',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunfermline Queen Margaret',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drumfrochar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DFR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drumfrochar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Driffield Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Driffield Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDGC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Denham Golf Club',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denham Golf Club',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DGC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dingle Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dingle Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Douglas (Isle of Man)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Douglas (Isle of Man)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Deansgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Deansgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDGY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Deganwy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Deganwy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DGY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Durham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Durham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Deighton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Deighton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Diss Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Diss Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Digby&Sowtn Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Digby&Sowtn Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Didcot Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Didcot Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dinner',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dinner',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Digby & Sowton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DIG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Digby & Sowton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dingwall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dingwall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Diss',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Diss',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDKD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunkeld & Birnam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DKD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunkeld & Birnam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorking (Main)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dorking (Main)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDKR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Derker Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Derker Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DKR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorking West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dorking West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Deal+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Deal+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duloe(Causeland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duloe(Causeland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dolgarrog',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dolgarrog',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Doleham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Doleham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalston Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalston Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalston Kingsland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalston Kingsland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Delamere',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Delamere',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'DUDLEY BUS LINK',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUDLEY BUS LINK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalreoch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalreoch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalston (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalston (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalton (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalton (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalwhinnie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalwhinnie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunmow Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunmow Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drumchapel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drumchapel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumfries',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dumfries',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dinas (Rhondda)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dinas (Rhondda)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DMG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dilton Marsh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dilton Marsh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Denmark Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denmark Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Droylesden Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Droylesden Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Derby Matchdays only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Derby Matchdays only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumpton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dumpton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dalmuir',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dalmuir',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dormans',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dormans',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Droylsden (Metrolink)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Droylsden (Metrolink)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drumry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drumry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDN4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dundee 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dundee 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dengiebus Althrn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dengiebus Althrn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Denbigh Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denbigh Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dinsdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dinsdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunton Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunton Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Derriaghy (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Derriaghy (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunlop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunlop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Denham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dengiebus Nfambr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dengiebus Nfambr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunrobin Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DNO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunrobin Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Devon Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Devon Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dinas Powys',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dinas Powys',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dent',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dent',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunmurry (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunmurry (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Downshire (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Downshire (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Danby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Danby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDO2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Days Out 2 In 8',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Days Out 2 In 8',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDO4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Days Out 4 In 8',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Days Out 4 In 8',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDO7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Days Out 7 In 15',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Days Out 7 In 15',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dornoch Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dornoch Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dockyard (Devonport)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dockyard (Devonport)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dodworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dodworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dolau',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dolau',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dortmund',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dortmund',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Doncaster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Doncaster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dover Priory+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dover Priory+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dore & Totley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dore & Totley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Downham Market',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DOW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Downham Market',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dorking (Deepdene)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dorking (Deepdene)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DPD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daytripper Plus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daytripper Plus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Devonport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Devonport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDQ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunfermline Qm 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunfermline Qm 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDR1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Rambler',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Rambler',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dersingham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dersingham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dane Road-Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dane Road-Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Railcard MCM Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Railcard MCM Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Driffield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Driffield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drayton Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Drayton Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DRG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drigg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drigg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drem',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Drem',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duirinish',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duirinish',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dronfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dronfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Rambler Family',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Rambler Family',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Darton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drumgelloch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drumgelloch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Devons Road (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Devons Road (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Deeside Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Deeside Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Disc Staff Berth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Disc Staff Berth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dusseldorf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dusseldorf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dorchester South or Dorchester West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dorchester South or Dorchester West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dales Railcard Settle & Carlisle Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dales Railcard Settle & Carlisle Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Disley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Disley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Darsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dartmoor Sun Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dartmoor Sun Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dartmoor Sun Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dartmoor Sun Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duke Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duke Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dengiebus Swfer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dengiebus Swfer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Daisy Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daisy Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daysaver TBM',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daysaver TBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dinting',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dinting',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daysaver Tm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daysaver Tm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Denton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Droitwich Spa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Droitwich Spa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunbar 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunbar 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dunblane 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunblane 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dulverton Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dulverton Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duddeston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Duddeston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Duisburg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Duisburg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dullingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dullingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dumbreck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dumbreck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunbar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dunbar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dunoon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dunoon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DUO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Durrington-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Durrington-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Duns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Duns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DUU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDUW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Duddeston Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Duddeston Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dedham Vale Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dedham Vale Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dovercourt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dovercourt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dove Holes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dove Holes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Didsbury Village Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Didsbury Village Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Davenport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Davenport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dover Priory',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dover Priory',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Devon Evening Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Devon Evening Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDVY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dovey Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dovey Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DVY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Drby Wayfr Dayrg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Drby Wayfr Dayrg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dolwyddelan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dolwyddelan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Denbigh Wrx Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denbigh Wrx Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Denbigh Wxc Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Denbigh Wxc Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dawlish',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dawlish',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Darwen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Darwen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dawlish Warren',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dawlish Warren',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DWW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dayrover W Yorks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dayrover W Yorks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dyce',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dyce',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Dytrippr Exchnge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dytrippr Exchnge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDYF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dyffryn Ardudwy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DYF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dyffryn Ardudwy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drayton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Drayton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Daysaver Tb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Daysaver Tb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBDZY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Danzey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Danzey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DZY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eastbourne Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastbourne Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earlsfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Earlsfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eaglescliffe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eaglescliffe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ealing Broadway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ealing Broadway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Putney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Putney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Earley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earlston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earlston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eastleigh+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastleigh+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Earlswood Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earlswood Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Euxton Balshaw Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EBA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Euxton Balshaw Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ebbw Vale Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EBB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ebbw Vale Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ebbsfleet+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ebbsfleet+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ebbsfleet Intciv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ebbsfleet Intciv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edinburgh Bus Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburgh Bus Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eastbrook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastbrook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Boldon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Boldon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Epsom Bus Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Epsom Bus Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eastbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edenbridge(Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Edenbridge(Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edenbridge Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EBT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Edenbridge Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEBV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ebbw Vale Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ebbw Vale Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eden Camp Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eden Camp Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eccles (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ECC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eccles (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Exeter Conf Ctr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exeter Conf Ctr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EXPLORE CAMBRIAN FAM',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXPLORE CAMBRIAN FAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edinbrh Citycab',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinbrh Citycab',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eccleston Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eccleston Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ECL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eccles Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eccles Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ECM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Enfield Chase or Enfield Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Enfield Chase or Enfield Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Energlyn & Churchill Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ECP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Energlyn & Churchill Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Croydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Croydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ECR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eccles Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eccles Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ECS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ec Catering',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ec Catering',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBECW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Cowes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Cowes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ECW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edinburgh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Edinburgh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EXPLORE CDF CAP',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXPLORE CDF CAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Mids Day Rg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Mids Day Rg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EAST SUFFOLK DAY RANGER',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAST SUFFOLK DAY RANGER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edge Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Edge Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'E Didsbury Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'E Didsbury Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eden Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eden Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edinburgh Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburgh Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edmonton Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edmonton Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eden Senior',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eden Senior',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Exeter St Davids+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exeter St Davids+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Dulwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Dulwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Didsbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Didsbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LNER Exec Car Parking',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LNER Exec Car Parking',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Essex Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Essex Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Effingham Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Effingham Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Farleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Farleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Midlands Flexi Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Midlands Flexi Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEG3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EXPLORE GOWER 3 DAY',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXPLORE GOWER 3 DAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Garforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Garforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EGF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eggesford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EGG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eggesford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Egham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EGH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Egham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eastrington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastrington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Grinstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Grinstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Egton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Egton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Explore Gower',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Explore Gower',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEGY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edinburgh Gateway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburgh Gateway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EGY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Etihad Camp Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Etihad Camp Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Emid Park&Ride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Emid Park&Ride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eskbank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EKB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eskbank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEKL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Kilbride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Kilbride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EKL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earlswood (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earlswood (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ELD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elmers End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Elmers End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elgin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Elgin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edge Lane Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edge Lane Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ELM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elton & Orston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Elton & Orston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ellesmere Port',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ellesmere Port',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ELP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elsecar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Elsecar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elstree & Borehamwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Elstree & Borehamwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eltham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eltham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ELW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBELY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ely',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEM1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LNER Midweek 1st',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LNER Midweek 1st',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Midlands Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Midlands Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'E Mids Fam Rngr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'E Mids Fam Rngr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Malling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Malling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Emerson Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Emerson Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East M Air/Dby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East M Air/Dby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Emsworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Emsworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Midland Air/Lge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Midland Air/Lge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBENC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enfield Chase',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ENC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Enfield Chase',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EAST INDIA DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EAST INDIA DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBENF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enfield Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ENF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Enfield Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBENL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enfield Lock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Enfield Lock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ENL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBENR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Ang Rw Muse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Ang Rw Muse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBENT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Entwistle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ENT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Entwistle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edenbridge or Edenbridge Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edenbridge or Edenbridge Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East or West Croydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East or West Croydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ec Car Parking',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ec Car Parking',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Epsom Downs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EPD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Epsom Downs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEPH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elephant & Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elephant & Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EPH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ellesmere Port+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ellesmere Port+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Epsom (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EPS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Epsom (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eastham Rake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastham Rake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ERA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Erdington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ERD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Erdington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Erith',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ERH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Erith',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ERI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earlestown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earlestown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ERL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Midlands Airport/East Midlands Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Midlands Airport/East Midlands Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East M Air/Not',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East M Air/Not',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EDINBURGH AIRPORT',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EDINBURGH AIRPORT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBERS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ELSTREE SHIRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ELSTREE SHIRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eastbourne+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastbourne+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Exe Estuary Circ',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exe Estuary Circ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elmstead Woods',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elmstead Woods',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ESD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Grinstead+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Grinstead+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Esher',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ESH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Esher',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eastleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ESL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elsenham (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elsenham (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ESM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Essen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Essen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Epsom Racecourse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Epsom Racecourse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eastlgh Sbl Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eastlgh Sbl Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Easterhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Easterhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBESW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elmswell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elmswell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ESW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBETB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eden T10 Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eden T10 Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBETC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Etchingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ETC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Etchingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBETL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Tilbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Tilbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ETL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBETP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edinburghdngn Tp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburghdngn Tp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EUROSTAR UK ILIN',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EUROSTAR UK ILIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEUJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Euston/Watford Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Euston/Watford Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Eurovoyager Supp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Eurovoyager Supp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ebbw Vale Bus (via Cardiff)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ebbw Vale Bus (via Cardiff)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Evesham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EVE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Evesham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEVF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Everton Fc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Everton Fc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEVJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Euro Voygr Jpfee',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Euro Voygr Jpfee',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Earlswood (West Midlands)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earlswood (West Midlands)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ewell East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ewell East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'EDINBGH W LOUNGE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EDINBGH W LOUNGE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Worthing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Worthing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EWR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ewell West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ewell West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EWW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exeter Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Exeter Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exeter St David\'s',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Exeter St David\'s',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exhibition Centre (Glasgow)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Exhibition Centre (Glasgow)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Exmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EXN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Exton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exchge Quay Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exchge Quay Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EXQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Essex Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Essex Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EXR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEXT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Exeter St Thomas',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Exeter St Thomas',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EXT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Eynsford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Eynsford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'East Yorks Round Robin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Yorks Round Robin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBEZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edinburgh Zone 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edinburgh Zone 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF1I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 15Dy In 1M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 15Dy In 1M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF1N',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 15Dy In 2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 15Dy In 2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF2I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 2Dys In 2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 2Dys In 2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF3I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 3Dys In 1M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 3Dys In 1M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF3M',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom 3Month',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom 3Month',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF3N',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 3Dys In 2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 3Dys In 2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF4I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 4Dys In 1M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 4Dys In 1M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF4N',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 4Dys In 2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 4Dys In 2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF6S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fnchly Rd A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fnchly Rd A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF8I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 8Dys In 1M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 8Dys In 1M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBF8N',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexi 8Dys In 2M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexi 8Dys In 2M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFA1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fareham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fareham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFA2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fauldhouse 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fauldhouse 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Farnborough Airshow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnborough Airshow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fraserburgh Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fraserburgh Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom Travelpass AC',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom Travelpass AC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Falmouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falmouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Farmers Festival',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farmers Festival',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falmouth Docks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Falmouth Docks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Frankfurt-am-Main',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frankfurt-am-Main',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Faversham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Faversham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fazakerley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FAZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fazakerley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFB1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Frm Of Brit 14Dy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frm Of Brit 14Dy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'FRDM TVLPASS ABC',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frdm Tvlpass Abc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fakenham Bus Kln',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fakenham Bus Kln',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'First Bus Monthly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'First Bus Monthly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fakenham Bus Nor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fakenham Bus Nor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'First Bus Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'First Bus Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flitwick Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flitwick Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'First Bus Weekly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'First Bus Weekly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Framlingham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Framlingham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Formby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Formby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fylde Coast Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fylde Coast Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falconwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falconwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Folkestone Central or West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Folkestone Central or West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'FARRINGDON CROSSRAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FARRINGDON CROSSRAIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Family Day Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Family Day Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Featherstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Featherstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fellgate Metro',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fellgate Metro',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Felbrigg Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Felbrigg Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Feltham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Feltham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Frm Of E Mids 7D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frm Of E Mids 7D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fenny Stratford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fenny Stratford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fernhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fernhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Festival Upgrade',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Festival Upgrade',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ffairfach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FFA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ffairfach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of Britain 7 day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of Britain 7 day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom Of Coast',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom Of Coast',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Freshford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Freshford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of North West  4 in 8 Day Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of North West  4 in 8 Day Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of North East Flexi Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of North East Flexi Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fairford Riat',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fairford Riat',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of Scotland 4 in 8 days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of Scotland 4 in 8 days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFFX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Festival Of Xmas',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Festival Of Xmas',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFGH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fishguard Harbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FGH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fishguard Harbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFGO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Falkirk Grahamston or High',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falkirk Grahamston or High',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Faygate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Faygate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fishguard & Goodwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FGW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fishguard & Goodwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Freehold Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Freehold Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'First Food',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'First Food',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Filey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Filey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Finstock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Finstock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Firstbusrailcard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Firstbusrailcard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Filton Abbey Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Filton Abbey Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFIU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Firstbusrcard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Firstbusrcard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Folkestone Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FKC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Folkestone Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falkirk Grahamston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falkirk Grahamston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFKK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falkirk High',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falkirk High',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FKK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFKW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Folkestone West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FKW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Folkestone West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFL2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flexpas 2Din1Mth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flexpas 2Din1Mth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom Travelpass ABD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom Travelpass ABD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fleetwood Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fleetwood Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fauldhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fauldhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Flowery Field',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Flowery Field',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Farringdon L Fcc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farringdon L Fcc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Flixton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Flixton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Flitwick+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flitwick+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Flimby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flimby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Flint',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flint',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ffos Las Shuttle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ffos Las Shuttle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Falmer+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falmer+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Failsworth Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Failsworth Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Flitwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Flitwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fulwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Fulwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFLX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Felixstowe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Felixstowe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FLX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frimley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Frimley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Farnborough Main or Farnborough North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnborough Main or Farnborough North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falmer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falmer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falmouth Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falmouth Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farnborough (Main)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnborough (Main)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farncombe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farncombe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farnham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Finaghy (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Finaghy (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farnborough North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnborough North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farningham Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farningham Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Feniton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Feniton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Furness Vale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FNV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Furness Vale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farnworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Finchley Road & Frognal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Finchley Road & Frognal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFO8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Foss Rover 8In15',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Foss Rover 8In15',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Forfar Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Forfar Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Falls of Cruachan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Falls of Cruachan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of Devon and Cornwall Rover 3 days in 7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of Devon and Cornwall Rover 3 days in 7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Forest Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Forest Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Forest Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Forest Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Four Oaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Four Oaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Folkestone+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Folkestone+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of Scotland 8 in 15 days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of Scotland 8 in 15 days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of North East 7 Day Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of North East 7 Day Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of North West 7 Day Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of North West 7 Day Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Forres',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Forres',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFOX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Foxfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FOX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Foxfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Finsbury Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Finsbury Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFR3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Foss Rover 3 In7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Foss Rover 3 In7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFR6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Finchley Road A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Finchley Road A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFR8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fodc Rover 8In15',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fodc Rover 8In15',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fairbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fairbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fnchly Rd A6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fnchly Rd A6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frodsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Frodsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Freshfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Freshfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fairfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fairfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ffestiniog Round Robin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ffestiniog Round Robin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fnchly Rd A6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fnchly Rd A6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frinton-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Frinton-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fairlie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fairlie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fareham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fareham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fearn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fearn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frome',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Frome',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Farnborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farnborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Forsinard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Forsinard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frant',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frant',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fairwater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fairwater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ferriby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ferriby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fishbourne (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fishbourne (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'FENCHURCH ST RTS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FENCHURCH ST RTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of Scotland Flexi Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of Scotland Flexi Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fishersgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fishersgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fiskerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fiskerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Five Shake Props',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Five Shake Props',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Fenchurch Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Fenchurch Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom of South West Rover  3 in 7 days',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom of South West Rover  3 in 7 days',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'FRDM TVLPASS A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frdm Tvlpass A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fort Matilda',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fort Matilda',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fratton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fratton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fort William',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fort William',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFV8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fosw Rover 8In15',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fosw Rover 8In15',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFVA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'FRDM TVLPASS AB',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frdm Tvlpass Ab',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Freedom Travelpass ABCD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Freedom Travelpass ABCD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFW2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wkzone*2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wkzone*2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wk Allzone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wk Allzone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Firswood Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Firswood Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Fontwell Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fontwell Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wk Zone 12*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wk Zone 12*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Five Ways',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Five Ways',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFWZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wk Zone 1*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wk Zone 1*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFXN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Foxton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Foxton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FXN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ferryside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ferryside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFZH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Frizinghall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Frizinghall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FZH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFZN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wk Zone123*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wk Zone123*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFZO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Four Wk Zone1234',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Four Wk Zone1234',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFZP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Furze Platt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Furze Platt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FZP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBFZW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Fitzwilliam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Fitzwilliam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FZW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gatwick Airport+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gatwick Airport+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grantham Busvlcn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grantham Busvlcn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasto Charter',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasto Charter',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GLASGOW AIRP FSR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GLASGOW AIRP FSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gainsborough Hse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gainsborough Hse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Galashiels',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Galashiels',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gate Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gate Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garrowhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Garrowhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Airport Bus via Partick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Airport Bus via Partick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gardeners World',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gardeners World',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Airport Xbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Airport Xbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Go As You Please',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Go As You Please',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Garswood Zonetkt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garswood Zonetkt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gilberdyke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gilberdyke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gorebridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gorebridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenbank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenbank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gainsborough Lea Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gainsborough Lea Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goring-by-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goring-by-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Central or Queen St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Central or Queen St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Greenwich (Cablecar)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greenwich (Cablecar)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gmpte Acmp Child',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gmpte Acmp Child',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Central Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Central Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garelochhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garelochhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Central Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Central Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gmpte Countycard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gmpte Countycard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gainsborough Central or Gainsborough Lea Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gainsborough Central or Gainsborough Lea Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gloucester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GCR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gloucester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Conference Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Conference Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Coates',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Great Coates',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGCW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glan Conwy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GCW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Glan Conwy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gordon Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gordon Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Godley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Godley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Godstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Godstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gidea Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GDP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gidea Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Goodwood Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Goodwood Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gretna Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gretna Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Escape London Midland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Escape London Midland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gerrards Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gerrards Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGEZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Metrolink Z1-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Metrolink Z1-3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gilfach Fargoed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gilfach Fargoed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Giffnock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Giffnock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Guide Friday Oxford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Guide Friday Oxford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grand Tour of Scotland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grand Tour of Scotland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Guide Friday Stratfd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Guide Friday Stratfd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGFY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gravesend Piers',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GFY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gravesend Piers',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGG2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glders Grna6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glders Grna6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGG5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glders Grna6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glders Grna6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGG6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Golders Green A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Golders Green A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGGA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glders Grn A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glders Grn A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGGJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Georgemas Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Georgemas Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GGJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glasgow Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Glasgow Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGGV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gargrave',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gargrave',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GGV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Group Wayfarer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Group Wayfarer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Go Ahead Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Go Ahead Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gravelly H Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gravelly H Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grimsby Busvlcn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grimsby Busvlcn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Giggleswick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GIG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Giggleswick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gillingham (Dorset)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gillingham (Dorset)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gipsy Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gipsy Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Girvan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Girvan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grimsby Town+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grimsby Town+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gillingham Kent+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gillingham Kent+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenock Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GKC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenock Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGKW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenock West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GKW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenock West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGL4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gleneagles 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gleneagles 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glamzone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glamzone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glasgow Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Guildford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Guildford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gleneagles',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gleneagles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glenfinnan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glenfinnan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glengarnock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glengarnock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glasshoughton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasshoughton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gillingham (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gillingham (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glyndebourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glyndebourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glossop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GLO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Glossop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glasgow Queen Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Queen Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glaisdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glaisdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glenrothes With Thornton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glenrothes With Thornton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glynde',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Glynde',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGLZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Glazebrook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glazebrook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GLZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grimsby Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grimsby Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grimsby Docks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grimsby Docks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garth (Mid Glamorgan)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garth (Mid Glamorgan)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GMG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Missenden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Great Missenden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'G M Rail Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'G M Rail Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grosmont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grosmont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Malvern',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Great Malvern',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Wayfarer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gm Wayfarer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goodmayes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goodmayes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGMZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Metrolink Z1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Metrolink Z1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gainsborough Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gainsborough Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GNB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenhithe For Bluewater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenhithe For Bluewater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glenavy (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glenavy (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Green Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Green Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Green Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Green Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gunton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gunton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Greenwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glynn (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glynn (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGO2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Golflinkbus 2004',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Golflinkbus 2004',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gobowen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gobowen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gosport Cycle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gosport Cycle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Godalming',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Godalming',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goldthorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goldthorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Golf Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Golf Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Golspie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Golspie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gomshall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gomshall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goole',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goole',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goring & Streatley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goring & Streatley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grange-over-Sands',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grange-over-Sands',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGOX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goxhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GOX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Goxhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grange Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grange Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGPO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gospel Oak',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gospel Oak',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GPO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gmpte Traincard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gmpte Traincard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGQL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Queen Street Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Queen Street Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGQS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Queen Street Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Queen Street Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grantham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Grantham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Bentley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Great Bentley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Chesterford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GRC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Great Chesterford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gallions Reach (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gallions Reach (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gravesend+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gravesend+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gartcosh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gartcosh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gourock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gourock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenfaulds',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greenfaulds',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grindleford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grindleford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Groombridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Groombridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grove Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grove Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garscadden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garscadden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grateley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grateley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Greenford Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greenford Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gravesend',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gravesend',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grays',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grays',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gilshochill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gilshochill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GSC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garsdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garsdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Glasgow Sightseeing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Glasgow Sightseeing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gunnislake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gunnislake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gt Yarm Sea Life',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gt Yarm Sea Life',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garston (Hertfordshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garston (Hertfordshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Go Staff Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Go Staff Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gathurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gathurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garswood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garswood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Guiseley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Guiseley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Ayton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Ayton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Greenhithe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greenhithe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GX STH TERMINAL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GX STH TERMINAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Grand Tour of Scotland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grand Tour of Scotland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garth (Powys)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garth (Powys)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Grangetown (Cardiff)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Grangetown (Cardiff)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gorton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gorton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Goostrey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Goostrey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gatwick Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gatwick Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gatley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gatley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGTZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Metrolink Z1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Metrolink Z1-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Goodwood Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Goodwood Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Guide Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GUI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Guide Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Guildford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Guildford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gunnersbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gunnersbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Guernsey (C.I.)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Guernsey (C.I.)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGUU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gunnersbury Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gunnersbury Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGVC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Galashiels (via Carlisle)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Galashiels (via Carlisle)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Galashiels (via Edinburgh)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Galashiels (via Edinburgh)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Garve',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Garve',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GVE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gravelly Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gravelly Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GVH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGW1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Concession Wayfarer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Concession Wayfarer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GREAT WEST WAY WEST',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GREAT WEST WAY WEST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gwersyllt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gwersyllt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gowerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gowerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GREAT WEST WAY GLOBAL 7 DAY',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GREAT WEST WAY GLOBAL 7 DAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GREAT WEST WAY EAST',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GREAT WEST WAY EAST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGXX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gourock Pier',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gourock Pier',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GXX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Yarmouth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Yarmouth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGYM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Great Yarmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Yarmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GYM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gypsy Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Gypsy Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Yorkshire Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Yorkshire Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBGZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'GM Metrolink Z1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'GM Metrolink Z1-2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBH2R',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HOWCIRC 2DAY RNGR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOWCIRC 2DAY RNGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Habrough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Habrough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hackney Downs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hackney Downs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haddiscoe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Haddiscoe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Terminal 4 (Rail Station Only)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heathrow Terminal 4 (Rail Station Only)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hagley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hagley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Halling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Halling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hale (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hale (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamworthy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hamworthy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hanwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hanwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harlow+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlow+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hatfield Peverel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hatfield Peverel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Halesworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Halesworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hatfield (Herts)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hatfield (Herts)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harlesden Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlesden Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Havant',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Havant',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hawick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hayes & Harlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hayes & Harlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hazel Grove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hazel Grove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hubberts Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HBB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hubberts Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harbour Cty Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harbour Cty Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hebden Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hebden Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hatfield Bpk Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hatfield Bpk Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heysham Bus Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heysham Bus Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Helens Bay (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helens Bay (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hollingbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hollingbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hornbeam Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HBP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hornbeam Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hadleigh Bus Rbz',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hadleigh Bus Rbz',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HAWKHURST BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HAWKHURST BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hartlebury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hartlebury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hackbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hackbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HCB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holmes Chapel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Holmes Chapel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Headcorn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Headcorn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Helensburgh Central or Helensburgh Upper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helensburgh Central or Helensburgh Upper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hampton Court Palace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton Court Palace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hants+Drst C Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hants+Drst C Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Huncoat',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Huncoat',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haydon Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haydon Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hedge End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hedge End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hadfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hadfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heald Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heald Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampstead Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampstead Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Headstone Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Headstone Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haddenham & Thame Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haddenham & Thame Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlesden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlesden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Howcirc Day Rngr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Howcirc Day Rngr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hadley Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hadley Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Headingley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Headingley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHE7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ht Of England 7D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ht Of England 7D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heckington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heckington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Halewood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Halewood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Herne Bay+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Herne Bay+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heighington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heighington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hensall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hensall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hendon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hendon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hertford East or North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hertford East or North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hersham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hersham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hessle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hessle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Herts Uni&Busnpk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Herts Uni&Busnpk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hever',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hever',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heworth (Metro)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Heworth (Metro)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hexham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HEX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hexham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHEZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heworth (Metro)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heworth (Metro)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hereford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hereford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hertford East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hertford East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HFE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hertford North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hertford North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hatfield & Stainforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hatfield & Stainforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HFS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ht Of Wsx Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ht Of Wsx Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHFX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Halifax',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Halifax',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HFX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heybridge Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heybridge Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hungerford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HGD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hungerford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hag Fold',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hag Fold',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HGF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haggerston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haggerston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HGG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Highland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Highland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Higham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Higham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hough Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hough Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hither Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hither Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hastings',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hastings',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrogate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrogate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hall Green Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hall Green Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harringay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harringay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HGY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHGZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hough Gn Zonetkt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hough Gn Zonetkt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHH1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Helsby Hooton 150',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helsby Hooton 150',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heysham Port',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heysham Port',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HHB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holyhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Holyhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haywards Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haywards Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heath High Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heath High Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'High Rocks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'High Rocks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Haywards Heath+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haywards Heath+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'High Wycombe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'High Wycombe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Highbury & Islington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Highbury & Islington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampton-in-Arden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton-in-Arden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HIA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'High Brooms',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'High Brooms',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hall-i-th-Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hall-i-th-Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Highbridge & Burnham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Highbridge & Burnham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHII',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harwich Int Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich Int Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hillside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hindley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hindley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Highams Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Highams Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horton-in-Ribblesdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Horton-in-Ribblesdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hitchin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hitchin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHIW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hampton In Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton In Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hawkhurst Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawkhurst Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hackney Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hackney Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HKC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hawkhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawkhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HKH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hykeham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hykeham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hucknall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hucknall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHKW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hackney Wick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hackney Wick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HKW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hildenborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hildenborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Helensburgh Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helensburgh Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hellifield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hellifield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillington East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hillington East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillfoot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hillfoot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hall Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hall Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Healing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Healing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heath Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heath Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holmwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Holmwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlington (Bedfordshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlington (Bedfordshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hall Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hall Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hilsea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hilsea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Helensburgh Upper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helensburgh Upper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillington West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hillington West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holytown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Holytown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hamburg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamburg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampton Court',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton Court',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampden Park (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampden Park (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHME',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamble',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamble',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HME',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hawes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hemel Hempstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hemel Hempstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hammerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hammerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Homerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Homerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampton (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Helmsdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helmsdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ham Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ham Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hampton Wick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hampton Wick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hairmyres',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hairmyres',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hinton Admiral',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hinton Admiral',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Herne Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Herne Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamilton Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamilton Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hanborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hanborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hednesford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hednesford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hengoed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hengoed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Herne Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Herne Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hilden (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hilden (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hinckley (Leics)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hinckley (Leics)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Henley-in-Arden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Henley-in-Arden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hardwick Nt Mbr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hardwick Nt Mbr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hannover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hannover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Huntly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Huntly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamilton West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamilton West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hunts Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HNX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hunts Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Holywood (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Holywood (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heathrow Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hockley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hockley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hollinwood Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hollinwood Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heart of England Rover 3In7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heart of England Rover 3In7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hough Green+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hough Green+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrow-on-the-Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrow-on-the-Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holton Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Holton Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Honiton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Honiton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hooton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hooton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hope (Derbyshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hope (Derbyshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Horley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Henley-on-Thames',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Henley-on-Thames',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hounslow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hounslow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Howden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Howden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hoxton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hoxton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Honley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Honley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HOY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHOZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Howwood (Renfrewshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Howwood (Renfrewshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HOZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Honor Oak Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Honor Oak Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Haydock Park Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haydock Park Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harpenden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harpenden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hope (Flintshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hope (Flintshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heaton Park Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heaton Park Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hartlepool',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hartlepool',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hapton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hapton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harwich International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hopton Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hopton Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHQD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heron Quays (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heron Quays (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heathrow Rail',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Rail',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harlow Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlow Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harling Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harling Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Horley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harlington+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlington+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Haydock Race Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haydock Race Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlech',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlech',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrietsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrietsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hornsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hornsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harold Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harold Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hartlepool+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hartlepool+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horsforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horsforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harlow Townrider',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlow Townrider',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Highland Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Highland Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrow & Wealdstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrow & Wealdstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harringay Green Lanes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harringay Green Lanes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Helsby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Helsby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hoscar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hoscar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamstead (Birmingham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamstead (Birmingham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hathersage',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hathersage',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HARLINGTON SHRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HARLINGTON SHRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HARPENDEN SHIRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HARPENDEN SHIRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hassocks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hassocks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haslemere',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haslemere',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hastings+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hastings+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HW SMART PAYG',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HW SMART PAYG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'High Street (Glasgow)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'High Street (Glasgow)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heswall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heswall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horsley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horsley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHT1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HBRY&ISLNGTN STN',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HBRY&ISLNGTN STN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HOLYHEAD STENA',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HOLYHEAD STENA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Halstead Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Halstead Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heaton Chapel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heaton Chapel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hatch End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hatch End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hartford (Cheshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hartford (Cheshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Handforth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Handforth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harwich Town Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich Town Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holt Town Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Holt Town Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hatton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hatton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hightown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hightown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Central Bus Station',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Central Bus Station',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Heathrow Terminal 5 (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Terminal 5 (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hartwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hartwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hattersley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hattersley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Holywell Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Holywell Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hull+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hull+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Huntingdon Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Huntingdon Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hythe Waterside Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hythe Waterside Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHU5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HITCHIN BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HITCHIN BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hunmanby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hunmanby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Huddersfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Huddersfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Huntingdon+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Huntingdon+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hull',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hull',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Huntingdon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Huntingdon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Humphrey Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Humphrey Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HUP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hurst Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hurst Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hutton Cranswick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hutton Cranswick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHUY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Huyton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HUY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Huyton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hawick (via Edinburgh)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawick (via Edinburgh)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHVF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haverfordwest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haverfordwest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HVF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hoek Van Holland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hoek Van Holland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HVH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Havenhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Havenhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHVU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hove+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hove+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hawarden Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawarden Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harwich Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harwich Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hawarden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hawarden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haltwhistle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haltwhistle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Horwich Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Horwich Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlow Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlow Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlow Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harlow Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hadrians W Cntry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hadrians W Cntry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'HADRIANS W BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hadrians W Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Harrow & Wld Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Harrow & Wld Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Terminal 5 (Rail Station Only)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Terminal 5 (Rail Station Only)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'How Wood (Hertfordshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'How Wood (Hertfordshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'High Wycombe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'High Wycombe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHXB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hoveton+Wroxham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hoveton+Wroxham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHXH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hexham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hexham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHXM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hoveton & Wroxham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hoveton & Wroxham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HXM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHXX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Terminals 2 and 3 (Rail Station Only)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Terminals 2 and 3 (Rail Station Only)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HXX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Honeybourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Honeybourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hyde Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hyde Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heyford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heyford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hay Festival',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hay Festival',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hythe (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HYH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hythe (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hoylake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hoylake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hayle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hayle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haymarket',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haymarket',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hyndland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'HYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Hyndland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Haydons Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Haydons Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hayes (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hayes (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hyde North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hyde North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBHYW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hinchley Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hinchley Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'HYW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'IMPLANT BRIT CCL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IMPLANT BRIT CCL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'IBM Halt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IBM Halt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Iw Bus Train Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Iw Bus Train Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIFB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ifield Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ifield Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ilford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ilford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ifield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IFI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ifield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ifield+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ifield+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ikea Glasgow Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ikea Glasgow Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIGD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Invergordon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IGD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Invergordon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Island Gardens (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Island Gardens (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ingatestone Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ingatestone Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBILB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ilfracombe Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ilfracombe Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBILK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ilkley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ILK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ilkley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBILN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ilkeston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ilkeston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ILN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBILR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Island Line Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Island Line Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Imperial Wharf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Imperial Wharf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'IMW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Instow Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Instow Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ince (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ince (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ince & Elton (Cheshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ince & Elton (Cheshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'INE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBING',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Invergowrie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ING',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Invergowrie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Invershin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Invershin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Inverkeithing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Inverkeithing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Inverkip',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Inverkip',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Inverurie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Inverurie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Insch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Insch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ingatestone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ingatestone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'INT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBINV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Inverness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'INV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Inverness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Isle of Wight Steam Day Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Isle of Wight Steam Day Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ipswich Buses',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ipswich Buses',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Itfc Portman Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Itfc Portman Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ipswich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IPS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ipswich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Irish Rail Zones 1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Irish Rail Zones 1-2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Irlam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Irlam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Irish Rail Zone 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Irish Rail Zone 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Irvine',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IRV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Irvine',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIRZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Irish Rail Zone 0',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Irish Rail Zone 0',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBISL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Isleworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ISL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Isleworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBISP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Islip',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Islip',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ISP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Iver',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Iver',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'IVR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIVY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ivybridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IVY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ivybridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'IWM DUXFORD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'IWM DUXFORD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBIWP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Imperial War Museum',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Imperial War Museum',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Jacobite Steam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Jacobite Steam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'James Cook University Hospital',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'James Cook University Hospital',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'JCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJEQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Jewellery Quarter',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'JEQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Jewellery Quarter',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Johnstone (Strathclyde)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'JHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Johnstone (Strathclyde)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Jordanstown (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Jordanstown (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Johnston (Pembs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'JOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Johnston (Pembs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Jordanhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'JOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Jordanhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBJSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Jersey (C.I.)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Jersey (C.I.)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'JSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Robin Hood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Robin Hood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kinbrace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kinbrace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkby-in-Furness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kirkby-in-Furness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kents Bank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kents Bank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingsway Bp Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kingsway Bp Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilburn High Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kilburn High Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Keswick Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Keswick Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knebworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knebworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKBX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirby Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KBX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kirby Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKC4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knghrn/Cwdnbth 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knghrn/Cwdnbth 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knockholt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knockholt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kings Cross N Lt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Cross N Lt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKD1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kennet Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kennet Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kidbrooke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kidbrooke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kidsgrove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kidsgrove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkcaldy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kirkcaldy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kenley (A22)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kenley (A22)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kendal Festival Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kendal Festival Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kendal+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kendal+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Keith',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Keith',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Keighley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Keighley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kelvedon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kelvedon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kemble',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kemble',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kendal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kendal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kensington Palace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensington Palace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kent Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kent Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kettering',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kettering',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kenton Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kenton Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Keighley & Worth Valley Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Keighley & Worth Valley Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Keyham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Keyham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingsknowe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kingsknowe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kinghorn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kinghorn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Langley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kings Langley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Nympton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kings Nympton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kings Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Sutton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kings Sutton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilgetty',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kilgetty',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kensal Green Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensal Green Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kew Gardens Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kew Gardens Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kings Hill Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Hill Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kirriemuir Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirriemuir Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kidderminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kidderminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kildonan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kildonan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingussie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kingussie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkby (Merseyside)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kirkby (Merseyside)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kintbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kintbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKIV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kiveton Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KIV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kiveton Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkby-in-Ashfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkby-in-Ashfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkham & Wesham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkham & Wesham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirknewton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirknewton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirk Sandall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirk Sandall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kildale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kildale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkstall Forge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkstall Forge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilmaurs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilmaurs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Lynn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Lynn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kenley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kenley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kempston Hardwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kempston Hardwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilmarnock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilmarnock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kemsley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kemsley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knole Nt Member',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knole Nt Member',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kempton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kempton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kemsing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kemsing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKN2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kings Nort Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Nort Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knaresborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knaresborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knockmore (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knockmore (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingswood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kingswood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kennett',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kennett',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knutsford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knutsford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kingston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kingston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knighton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KNI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knighton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kensal Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensal Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Norton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kings Norton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knottingley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KNO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knottingley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kensal Rise',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensal Rise',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kennishead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kennishead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kenton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kenton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Knucklas',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KNU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Knucklas',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kenilworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kenilworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Koblenz',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Koblenz',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knole',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knole',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Koln',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Koln',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kensington O Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensington O Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kensington Olympia',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kensington Olympia',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KPA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilpatrick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilpatrick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkconnel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkconnel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kirkwall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkwall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kearsley (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kearsley (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kearsney (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kearsney (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkby Stephen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkby Stephen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kent House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kent House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirton Lindsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirton Lindsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kentish Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kentish Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ket Park&Ride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ket Park&Ride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kettering+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kettering+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kentish Town West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kentish Town West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kirknwtn/Uphll 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirknwtn/Uphll 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kelvindale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kelvindale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KVD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kiveton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kiveton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KVP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKVS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Kirkwall via Scrabster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkwall via Scrabster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kew Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kew Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kirkwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kirkwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKWG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kew Gardens',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kew Gardens',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KWG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kidwelly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kidwelly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilwinning',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilwinning',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kyle of Lochalsh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kyle of Lochalsh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBKYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Keynsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Keynsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBL1C',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lounge 1st Class',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lounge 1St Class',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBL6S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lon Viccs A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lon Viccs A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBL9S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lst & Ssd A9 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lst & Ssd A9 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLA3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Larbert 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larbert 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLA5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lst & Ssd A9 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lst & Ssd A9 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lampeter Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lampeter Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lancing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lancing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ladywell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ladywell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langwith-Whaley Thorns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Langwith-Whaley Thorns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Airbus Ht',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Airbus Ht',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Laindon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Laindon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lakenheath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lakenheath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lamphey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lamphey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lancaster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lancaster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Airport Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Airport Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lapford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lapford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Largs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Largs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llansamlet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llansamlet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Air Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Air Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Laurencekirk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Laurencekirk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Landywood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Landywood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LAW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Layton (Lancs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Layton (Lancs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLB9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Bus & Tram Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Bus & Tram Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Long Buckby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Long Buckby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lincoln Bus Z 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lincoln Bus Z 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Loughborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LBO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Loughborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanbedr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llanbedr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Larbert',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larbert',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LBT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLBZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leighton Buzzard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LBZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leighton Buzzard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lon Vic Cs A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lon Vic Cs A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lochluichart',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lochluichart',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London City Airport (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London City Airport (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lochgelly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lochgelly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lichfield City+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lichfield City+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lockwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lockwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lochailort',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lochailort',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lincoln',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lincoln',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leevalley Cruise',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leevalley Cruise',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Locheilside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LCS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Locheilside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ler Launch Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ler Launch Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lancs Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lancs Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lakes Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lakes Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lincolnshire Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lincolnshire Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandanwg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llandanwg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Londonderry (Nir)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Londonderry (Nir)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leeds',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LDS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leeds',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ladywell Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ladywell Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ladybank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ladybank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLE4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leuchars 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leuchars 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leagrave',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leagrave',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lea Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lea Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ledbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ledbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lee (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lee (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lea Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lea Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lea Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lea Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leicester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leicester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lelant',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lelant',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leyton Midland Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leyton Midland Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leominster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leominster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leytonstone High Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leytonstone High Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leigh-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leigh-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Letchworth Garden City',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Letchworth Garden City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leuchars',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leuchars',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lewisham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lewisham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leyland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Leyland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLF0',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Latitude Fest 09',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Latitude Fest 09',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lingfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lingfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ludlow Food Fest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ludlow Food Fest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leigh Fleurdelis',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leigh Fleurdelis',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lakes Fam Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lakes Fam Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langbank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langbank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LGB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lingwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lingwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Long Eaton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Long Eaton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langley Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langley Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LGG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Loughborough Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Loughborough Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longbeck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longbeck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langley Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langley Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llangynllo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LGO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llangynllo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leagrave+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leagrave+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leicester Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leicester Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langwathby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langwathby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LGW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Loch Awe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LHA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Loch Awe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leatherhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leatherhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Loch Eil Outward Bound',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Loch Eil Outward Bound',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LEAGRAVE SHIRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LEAGRAVE SHIRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lealholm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lealholm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langho',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langho',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Limehouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Limehouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lochwinnoch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LHW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lochwinnoch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leighton Buzzard+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leighton Buzzard+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lichfield City',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lichfield City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lidlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lidlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leiston (via Saxmundham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leiston (via Saxmundham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LIE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liverpool FC',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool Fc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Gatwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Gatwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leigh (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leigh (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LIH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Linlithgow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Linlithgow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liphook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Liphook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liss',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Liss',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Littlehampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Littlehampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool Lime Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LIV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Liverpool Lime Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLIZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Zone1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Zone1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLKE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LKE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Stansted',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Stansted',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanaber',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llanaber',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike London City Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike London City Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandecwyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandecwyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandudno',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandudno',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanelli',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanelli',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanfairfechan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanfairfechan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llangadog',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llangadog',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llangennech',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llangennech',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandybie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandybie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandudno Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandudno Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandeilo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandeilo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llangammarch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llangammarch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandaf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandaf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandrindod',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandrindod',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liverpool Lime St, Central, James St or Moorfields',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool Lime St, Central, James St or Moorfields',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanharan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanharan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanishen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanishen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanbister Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanbister Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llandovery',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llandovery',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llwyngwril',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Llwyngwril',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llwynypia',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llwynypia',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limehouse (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limehouse (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Heathrow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Heathrow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leicester Matchdays only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leicester Matchdays only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Low Moor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Low Moor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leamington Spa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leamington Spa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLMU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Llangollen Music',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llangollen Music',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanbradach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanbradach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Corporate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Corporate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longniddry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longniddry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Londoneye_Fastrk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Londoneye_Fastrk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longcross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longcross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Langholm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langholm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lambeg (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lambeg (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lanark',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lanark',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LONG MARSTON',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LONG MARSTON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Le Yorks Northern Web',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Le Yorks Northern Web',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanwrda',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanwrda',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LUTON SHIRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LUTON SHIRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Terminals',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Terminals',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanwrtyd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanwrtyd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langley (Berks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langley (Berks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLNZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lenzie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lenzie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LNZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLO2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Longniddry 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Longniddry 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lockerbie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lockerbie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Dungeon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Dungeon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Eye',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Eye',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Fields',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Fields',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leigh-on-Sea+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leigh-on-Sea+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lostock Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lostock Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leigh On Sea Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leigh On Sea Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Midlands Trains',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Midlands Trains',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Looe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Looe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Loughborough Park&Ride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Loughborough Park&Ride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lostwithiel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lostwithiel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lostock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lostock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Loughborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Loughborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lowdham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LOW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lowdham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leybourne Pk Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leybourne Pk Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LONDON PADDINGTON CROSSRL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LONDON PADDINGTON CROSSRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LANGDON PARK LT',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LANGDON PARK LT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanfairpwll',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanfairpwll',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LPG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Ap Bus Vgn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Ap Bus Vgn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Long Preston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Long Preston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LPR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Longport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lapworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lapworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LPW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLPY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool South Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool South Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LPY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLR2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lrdcrcktgda6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lrdcrcktgda6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLR5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lrdcrcktgda6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lrdcrcktgda6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Road (Brighton)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Road (Brighton)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lingfield Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lingfield Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Road (Guildford)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Road (Guildford)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Legoland Resort',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Legoland Resort',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lairg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lairg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Larkhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Larkhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LONDONRD BTN+BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Londonrd Btn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton Airport+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton Airport+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Road D R',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Road D R',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'L Orient Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'L Orient Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lerwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lerwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLS2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lrdcrcktgda6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lrdcrcktgda6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLS9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lst & Ssd A9 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lst & Ssd A9 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lst & Ssd A9',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lst & Ssd A9',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liverpool Lime Street Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool Lime Street Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liskeard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liskeard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Livingston North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Livingston North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London St Pancras Low Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London St Pancras Low Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leicester Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leicester Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liverpool South Parkway+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool South Parkway+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leasowe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leasowe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LONDON LIVERPOOL STREET XRAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LONDON LIVERPOOL STREET XRAIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lower Sydenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lower Sydenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LSY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lostock Gralam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lostock Gralam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanhilleth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanhilleth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Little Kimble',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Little Kimble',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Littleborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Littleborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lytham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lytham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Luton Airport Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Luton Airport Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Littleport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Littleport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lelant Saltings',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lelant Saltings',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Little Sutton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Little Sutton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLTV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lichfield Trent Valley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lichfield Trent Valley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lydford Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lydford Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lands End Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lands End Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Legoland Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Legoland Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Luton Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LUA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Luton Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lossiemouth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lossiemouth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ludlow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ludlow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Luton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Luton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Llangollen Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llangollen Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Luton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Luton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLUX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Luxulyan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LUX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Luxulyan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLV6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lon Viccs A6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lon Viccs A6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lon Viccs A6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lon Viccs A6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Leven Valley Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leven Valley Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Livingston South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Livingston South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool James Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool James Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Looe Valley Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Looe Valley Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Levenshulme',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Levenshulme',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Littlehaven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Littlehaven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Liverpool+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Liverpool Landing Stage',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Liverpool Landing Stage',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLVT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lisvane & Thornhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lisvane & Thornhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LVT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LETCHWORTH BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LETCHWORTH BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lowestoft+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lowestoft+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lawrence Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lawrence Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llantwit Major',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llantwit Major',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Legoland Windsor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Legoland Windsor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Llanrwst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Llanrwst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lewes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lewes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lowestoft',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lowestoft',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Langworthy Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Langworthy Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lympstone Commando',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lympstone Commando',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lydney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lydney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lye (West Midlands)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LYE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lye (West Midlands)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lympstone Village',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LYM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Lympstone Village',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Le Yorks Northern',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Le Yorks Northern',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lymington Pier',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lymington Pier',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lampeter Ayw Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lampeter Ayw Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lymington Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lymington Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 2-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '23A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 2-3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 3-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '34A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 3-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lazonby & Kirkoswald',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lazonby & Kirkoswald',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LZB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 2-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '24A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 2-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Zone 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Zone 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Limobike Zone 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limobike Zone 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Zones 3-6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: '36A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Zones 3-6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'London Zoo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Zoo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBLZS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lincoln Bus Z 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lincoln Bus Z 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBM3D',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C3/D2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C3/D2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBM3F',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C3/F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C3/F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBM4D',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merseyrail 4 Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merseyrail 4 Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBM6S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marblearch A6 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marblearch A6 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMA2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marblearcha6 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marblearcha6 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMA3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MOD ABBEY WOOD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOD Abbey Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMA4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Markinch 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Markinch 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMA5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marblearcha6 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marblearcha6 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marble Arch A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marble Arch A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maerdy Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maerdy Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Macclesfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Macclesfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maidstone+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidstone+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ME Only Area F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Me Only Area F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maghull',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Maghull',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maidenhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Maidenhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Malden Manor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Malden Manor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Martins Heron',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Martins Heron',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Margate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Margate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manors',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manors',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Matlock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Matlock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mauldeth Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mauldeth Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Manchester Airport Viewing Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Airport Viewing Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maxwell Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Maxwell Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maybole',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Maybole',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMB1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C/B1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C/B1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMB2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE B2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone B2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA B2/G1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area B2/G1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marlboro Bdw Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marlboro Bdw Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA B/C1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area B/C1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA B1/C1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area B1/C1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA B/G1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area B/G1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Millbrook (Hants)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Millbrook (Hants)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mugdock Bus Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mugdock Bus Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Middlesbrough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Middlesbrough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MAIDSTONE BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAIDSTONE BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maidenbower Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidenbower Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMBZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maldon Bus Zone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maldon Bus Zone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMC1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA A/C1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area A/C1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMC2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMC3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE C1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone C1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMC4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE C2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone C2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMC5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE C3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone C3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C/A2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C/A2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moulsecoomb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MCB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Moulsecoomb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mach C.A.T.',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mach C.A.T.',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mid Cheshire Z1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mid Cheshire Z1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Metrocentre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MCE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Metrocentre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Martinsctft Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Martinsctft Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'March',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'March',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morecambe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Morecambe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Machynlleth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Machynlleth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester Oxford Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Oxford Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mad T Cham Cr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mad T Cham Cr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Motorcycle Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Motorcycle Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Media City Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Media City Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester Victoria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Victoria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMCZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester Ctlz',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Ctlz',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MCZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMD1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE D1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone D1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMD2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C/D2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C/D2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maidstone Barracks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidstone Barracks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA D/C3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area D/C3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maidstone East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidstone East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Midgham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Midgham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Middlewood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Middlewood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moor Road Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Moor Road Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maiden Newton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maiden Newton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morden South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MDS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Morden South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Madame Tussauds',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Madame Tussauds',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maidstone West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidstone West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meols Cop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MEC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Meols Cop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ME Only Area G',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Me Only Area G',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maidstone East, West or Barracks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maidstone East, West or Barracks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meldreth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Meldreth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Menheniot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Menheniot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meols',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Meols',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meopham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Meopham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MEP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Merthyr Tydfil',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Merthyr Tydfil',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Melton (Suffolk)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Melton (Suffolk)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Merthyr Vale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merthyr Vale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MEV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maesteg (Ewenny Road)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maesteg (Ewenny Road)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mexborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MEX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mexborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Merryton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merryton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMEZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z1-2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMF1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA C/F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area C/F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMFA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morfa Mawddach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MFA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Morfa Mawddach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Minffordd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Minffordd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMFH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milford Haven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MFH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Milford Haven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mount Florida',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mount Florida',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMFT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mansfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mansfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MFT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMG1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA G',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area G',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMG2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE G2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone G2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mc Glen Ashfd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mc Glen Ashfd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA G/B2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area G/B2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metheringham Cc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metheringham Cc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mg11 Issued',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mg11 Issued',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Metheringham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metheringham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marston Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marston Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Motherwell Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Motherwell Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mad T Cham Crcon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mad T Cham Crcon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Merstham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merstham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro City Hop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro City Hop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Market Harborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Market Harborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meadowhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Meadowhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MIA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Micheldever',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Micheldever',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Middlesborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Middlesborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mills Hill (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mills Hill (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mitcham Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mitcham Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Micklefield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Micklefield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MIK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mill Hill Broadway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mill Hill Broadway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moreton-in-Marsh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Moreton-in-Marsh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milliken Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milliken Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mirfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mirfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mistley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mistley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Minden (Westfalen)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Minden (Westfalen)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMIZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z2-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z2-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMJS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Madjeski Stadium',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Madjeski Stadium',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Milton Keyns Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milton Keyns Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milton Keynes Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milton Keynes Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MKC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Melksham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Melksham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Market Rasen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Market Rasen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MKR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marks Tey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marks Tey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMKZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z3-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z3-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Millbrook (Bedfordshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Millbrook (Bedfordshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mouldsworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mouldsworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milford (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milford (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mallaig',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mallaig',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mill Hill (Lancs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mill Hill (Lancs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Maritime Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maritime Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Millom',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Millom',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milngavie',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milngavie',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Milnrow Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Milnrow Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Melrose',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Melrose',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Malton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Malton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marlow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marlow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Morley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMLZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z1-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Melton Mowbray',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Melton Mowbray',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mills & Markets Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mills & Markets Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Man Airport Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Man Airport Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Markinch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Markinch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manningtree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manningtree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Monchengladbach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Monchengladbach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Magheramorne (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Magheramorne (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Menston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Menston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marino (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marino (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manor Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manor Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manor Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manor Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maghull North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maghull North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMNZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mainz',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mainz',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ME Only Area B',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Me Only Area B',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mobberley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mobberley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mountfit Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mountfit Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE D2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone D2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moorgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Moorgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Monifieth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Monifieth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Muir of Ord',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Muir of Ord',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Morpeth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Morpeth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mortimer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mortimer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moss Side',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Moss Side',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Motspur Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Motspur Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Moulsecoomb+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moulsecoomb+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mhr Park&Ride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mhr Park&Ride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mersey PTE Child max fare',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mersey PTE Child max fare',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Minffordd/Porthmadog (Ffestiniog Railway)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Minffordd/Porthmadog (Ffestiniog Railway)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mosspark',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mosspark',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marple',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marple',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merton Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merton Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morpeth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Morpeth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMPV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Manchester Piccadilly, Victoria, Oxford Road or Deansgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester Piccadilly, Victoria, Oxford Road or Deansgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMR2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA A2/C2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area A2/C2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL AREA A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Area A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manorbier',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manorbier',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ME Only Area C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Me Only Area C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morchard Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Morchard Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Moira (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moira (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moorfields',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moorfields',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Margate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Margate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Moorslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moorslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ME Only Area D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Me Only Area D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marden (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marden (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merthyr Rock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merthyr Rock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moorthorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moorthorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Morar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Morar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Monks Risborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Monks Risborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moreton (Merseyside)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moreton (Merseyside)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Moorsbus Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moorsbus Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Meridian Water',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Meridian Water',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maryport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maryport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMRZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE E',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone E',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMS2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mdjski Stdm Conc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mdjski Stdm Conc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Middlesbrough Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Middlesbrough Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moorside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moorside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merseyrail Special Event',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merseyrail Special Event',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mossley Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mossley Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marske',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marske',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mossley (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mossley (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Monsall Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Monsall Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marsden (Yorks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marsden (Yorks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Minster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Minster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moses Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moses Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maesteg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maesteg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mansfield Woodhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mansfield Woodhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMT1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Tyne & Wear All Zones',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Tyne & Wear All Zones',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mountain Ash',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mountain Ash',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Matlock Bath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Matlock Bath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mitcham Eastfields',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mitcham Eastfields',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'M Train Dayrover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'M Train Dayrover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mottingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mottingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Motherwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Motherwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mortlake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mortlake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Martin Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Martin Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Moreton (Dorset)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Moreton (Dorset)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Marton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Montpelier',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Montpelier',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Merseyrail Trio Area B',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Merseyrail Trio Area B',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Montrose',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Montrose',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Tyne & Wear Zone A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Tyne & Wear Zone A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mount Vernon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mount Vernon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MTV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metromax',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metromax',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMTZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z1-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z1-3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mold Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mold Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Musselburgh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MUB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Musselburgh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mudchute (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mudchute (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mull Experience',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mull Experience',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester United Football Ground',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester United Football Ground',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MUF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Muirend',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MUI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Muirend',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Munster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Munster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mundesley Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mundesley Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Manchester United Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Manchester United Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMVL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Malvern Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Malvern Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MVL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Marylebone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Marylebone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maryhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maryhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MYH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maryland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maryland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mytholmroyd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mytholmroyd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE A1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone A1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mid Cheshire Z2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mid Cheshire Z2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE A3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone A3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZ4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'METROLINK Z4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'METROLINK Z4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZ5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Zone 56/57',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Zone 56/57',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Zone A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Zone A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Zone D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Zone D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Metro Zone E',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Metro Zone E',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE G1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone G1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maze Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Maze Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MZH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBMZI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'MRAIL ZONE A2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mrail Zone A2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBN1W',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Netw 1Stpl Z 1-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Netw 1Stpl Z 1-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBN1Z',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Netw 1Stpl Z 1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Netw 1Stpl Z 1-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBN4I',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nmidwales 4 In 8',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nmidwales 4 In 8',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nantwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nantwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Narberth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Narberth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NEWCASTLE TRMC',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEWCASTLE TRMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton Aycliffe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newton Aycliffe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNB3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Berwick 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Berwick 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Barnet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Barnet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Beckenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Beckenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nwalsham Bus Bb',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nwalsham Bus Bb',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbold Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newbold Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Brighton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Brighton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nbus+Metro Only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nbus+Metro Only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Narborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Narborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Norbiton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Norbiton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'N Walsham Busfec',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'N Walsham Busfec',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Berwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'North Berwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ncfc Carrow Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ncfc Carrow Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Clee',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Clee',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'N Cntry Flexirvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'N Cntry Flexirvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Cumnock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Cumnock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newcastle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newcastle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Camp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'North Camp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newcourt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newcourt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newark Castle or Northgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newark Castle or Northgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newark Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newark Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNCZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newcastle(Metro)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NCZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newcastle(Metro)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network Dd All Z',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd All Z',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'N Devon Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'N Devon Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nth Downs Dy Rgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nth Downs Dy Rgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network Dd Z2-5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd Z2-5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Dulwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'North Dulwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network Dd Z1-4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd Z1-4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network Dd Z1-3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd Z1-3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network Dd Z1-2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd Z1-2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNDZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Network DD Z1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Network Dd Z1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNE5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NORTHERN EXPLORER 55 WEST DAY RANGER',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NORTHERN EXPLORER 55 WEST DAY RANGER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newport Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newport Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newtongrange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newtongrange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Eltham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Eltham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Neilston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Neilston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nelson',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nelson',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Malden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'New Malden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newcastle Nth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newcastle Nth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NETWORK PHOTOCRD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NETWORK PHOTOCRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North East Rnd R',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North East Rnd R',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Neston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Neston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Netherfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Netherfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newcraighall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newcraighall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Fambridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NFA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'North Fambridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'New Forest Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Forest Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Northfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Northfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nafferton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nafferton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNFO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nth Fam&F Only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nth Fam&F Only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NTHNTPL HARRGTE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NTHNTPL HARRGTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Northampton Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northampton Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nunhead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nunhead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Hythe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Hythe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Holland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Holland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NTHNTPL HARRGTE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NTHNTPL HARRGTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nottingham Tram',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nottingham Tram',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NTHNTPL HULL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NTHNTPL HULL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newhey Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newhey Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Norwich Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norwich Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Islngtn Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Islngtn Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NIM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nitshill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nitshill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nine Elms (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nine Elms (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Llanrwst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'North Llanrwst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nailsea & Backwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nailsea & Backwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northolt Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Northolt Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton-le-Willows',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton-le-Willows',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Mills Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Mills Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Net Mth Dd Addon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Net Mth Dd Addon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newmarket',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newmarket',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newtnh&Mostnmtkl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newtnh&Mostnmtkl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Mills Newtown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Mills Newtown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Northampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northern Mr Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Northern Mr Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Needham Market',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Needham Market',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NTHNTPL BRDFRD',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NTHNTPL BRDFRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newark North Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newark North Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nbus (Bus Only)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nbus (Bus Only)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ninian Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ninian Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Norfolk Railway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Norfolk Railway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nunthorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nunthorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton-on-Ayr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton-on-Ayr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NOA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'New Romney Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Romney Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North of England City Experience',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North of England City Experience',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Northwirrallines',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northwirrallines',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Normanton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Normanton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North County Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North County Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nottingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nottingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Pudsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Pudsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NPD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNQB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newquay Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newquay Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNQU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Queensferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Queensferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NQU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNQY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newquay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newquay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NQY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newmarket Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newmarket Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Norbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbury Racecourse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newbury Racecourse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Road (Darlington)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Road (Darlington)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newbury Rc Grand',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newbury Rc Grand',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nth Rail Day Rgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nth Rail Day Rgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Navigation Road (Metrolink)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Navigation Road (Metrolink)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nairn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nairn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nthwst Rnd Robin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nthwst Rnd Robin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newbury Rc Prem',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newbury Rc Prem',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Northern Rail Weekend Only',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northern Rail Weekend Only',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nethertown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nethertown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North of England Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North of England Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Norwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Net Stude Add-On',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Net Stude Add-On',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Normans Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Normans Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NOT USED',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NOT USED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Southgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Southgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Sheen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Sheen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton Abbot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton Abbot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton St Cyres',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton St Cyres',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Nottingham+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nottingham+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Neath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Neath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Netley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Netley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton (Lanark)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton (Lanark)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northallerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northallerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Norwich Attrctns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norwich Attrctns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNTU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Not Used',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Not Used',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'NOT USED',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NOT USED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Not Used',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Not Used',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nutfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NUF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nutfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northumberland Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northumberland Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nuneaton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nuneaton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Not Used',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Not Used',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nutbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Nutbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newhaven Harbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newhaven Harbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NVH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newhaven Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newhaven Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Navigation Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Navigation Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NVR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Walsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Walsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Wembley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wembley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Norwood Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Norwood Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newport (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newport (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Newark+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newark+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'N Wales All Zne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'N Wales All Zne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Milton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Milton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newton for Hyde',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newton for Hyde',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newport (South Wales)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newport (South Wales)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newtonmore',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newtonmore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newtown (Powys)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newtown (Powys)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wembly Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wembly Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Newry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNWZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNXG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Cross Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'New Cross Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'NXG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone G',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone G',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone M',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone M',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone W',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone W',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBNZX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'North Wales Zone X',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Wales Zone X',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOBN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oban',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oban',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OBN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'OXFORD CITY BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OXFORD CITY BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ockendon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ockendon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oldham Ctrl Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Oldham Ctrl Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBODE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Day Explorer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Day Explorer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBODR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBODS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oyster Deposit refund',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oyster Deposit refund',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford Evening Out',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Evening Out',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Old Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Old Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOKE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Okehampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OKE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Okehampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOKL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oakleigh Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oakleigh Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OKL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oakham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oakham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oakengates',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oakengates',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oldham K St Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Oldham K St Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Old Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Old Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oldfield Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oldfield Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oldham Mps Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oldham Mps Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Olton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Olton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Olton Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Olton Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ockley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ockley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ormskirk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ormskirk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oostende',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oostende',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Orrell Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Orrell Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford Parkway+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Parkway+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ORPINGTON DSIDE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ORPINGTON DSIDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ore',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ORE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Old Roan',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Old Roan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ORN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Orpington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ORP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Orpington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Orrell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ORR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Orrell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBORT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Original Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Original Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oswestry Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oswestry Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Osnabruck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Osnabruck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOSU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Old Street Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Old Street Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Otford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Otford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Old Traffrd Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Old Traffrd Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oulton Broad North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oulton Broad North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oulton Broad South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oulton Broad South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Outwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Outwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Overpool',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OVE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Overpool',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Overpool+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Overpool+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Overton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Overton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OVR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOW1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ontrn Wknd 1St A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ontrn Wknd 1St A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk All Zone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk All Zone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk Zone 12*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk Zone 12*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ontrn Wknd 1St B',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ontrn Wknd 1St B',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOWZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk Zone 1*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk Zone 1*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxenholme+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxenholme+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OXF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Oxford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxenholme Lake District',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxenholme Lake District',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OXN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxford Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'OXP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxshott',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OXS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Oxshott',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOXT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxted',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'OXT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Oxted',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oyster Deposit',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oyster Deposit',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oyster Prepay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oyster Prepay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk Zone 123*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk Zone 123*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk Zone*2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk Zone*2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBOZN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'One Wk Zone1234*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'One Wk Zone1234*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontardawe Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontardawe Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Palmers Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Palmers Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pangbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pangbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Paradise Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paradise Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Par',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Patricroft',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Patricroft',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Potton Bus Biggl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Potton Bus Biggl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Parbold',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Parbold',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Peterborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PBO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Peterborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Potters Bar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Potters Bar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontefract Baghill or Monkhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontefract Baghill or Monkhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pershore Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pershore Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pembrey & Burry Port',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pembrey & Burry Port',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pencoed',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PCD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pencoed',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paisley Canal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paisley Canal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Plymouth Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Plymouth Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPCZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontoon Dock (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontoon Dock (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Padgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Padgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paddock Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paddock Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPE4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Perth 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Perth 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Peartree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Peartree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pevensey Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pevensey Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portree',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portree',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pegswood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pegswood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pemberton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pemberton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penarth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Penarth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Penge East or West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penge East or West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Penalty Payment',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penalty Payment',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penrhiwceiber',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penrhiwceiber',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pensarn (Gwynedd)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pensarn (Gwynedd)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Petts Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Petts Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pevensey & Westham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pevensey & Westham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pewsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PEW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pewsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Purfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Purfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPFM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontefract Monkhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PFM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pontefract Monkhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontefract Baghill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PFR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pontefract Baghill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPFT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Poole Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PFT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Poole Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPFY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Poulton-le-Fylde',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PFY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Poulton-le-Fylde',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pengam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pengam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paignton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paignton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Peterhead Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Peterhead Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Portsmth Hst Dck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portsmth Hst Dck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPHG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penhelig',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penhelig',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PHG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Peel Hall Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Peel Hall Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penshurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penshurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prittlewell Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prittlewell Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pilning',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pilning',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pinhoe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pinhoe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Premier Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Premier Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pitlochry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pitlochry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penkridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penkridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Parkstone (Dorset)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Parkstone (Dorset)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Park Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Park Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Polperro Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Polperro Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pluckley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pluckley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portslade',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portslade',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshields East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pollokshields East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Polegate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Polegate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Planet Hollywood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Planet Hollywood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plockton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Plockton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plumley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Plumley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portlethen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portlethen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pleasurewood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pleasurewood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pleasington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pleasington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontlottyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pontlottyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plumstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Plumstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshields West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pollokshields West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plymouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Plymouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portsmouth Arms',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PMA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portsmouth Arms',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pembroke',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pembroke',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pembroke Dock',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pembroke Dock',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portsmouth Harbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portsmouth Harbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pomona Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pomona Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plumpton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Plumpton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PMP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Peckham Rye',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Peckham Rye',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portsmouth & Southsea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Portsmouth & Southsea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Polmont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Polmont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penmaenmawr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penmaenmawr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PMW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penally',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penally',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penychain',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penychain',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penge East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penge East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penyffordd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penyffordd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pickering (North Yorks Moors Railway)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pickering (North Yorks Moors Railway)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pannal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pannal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penmere',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penmere',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Penryn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penryn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penzance Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penzance Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penrith (North Lakes)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penrith (North Lakes)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penistone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penistone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penge West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penge West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pen-y-Bont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pen-y-Bont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPNZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penzance',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penzance',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PNZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Poplar (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Poplar (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pokesdown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'POK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pokesdown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Polsloe Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'POL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Polsloe Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ponders End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ponders End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Poole',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'POO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Poole',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Poppleton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'POP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Poppleton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Porth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'POR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Porth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'PRESTON SPPFM',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PRESTON SPPFM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontefract Tanshelf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontefract Tanshelf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'POT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Paradise Pk Snr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paradise Pk Snr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prenton Park Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prenton Park Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontypridd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontypridd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PPD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Possilpark & Parkhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Possilpark & Parkhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontypool & New Inn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontypool & New Inn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Preston Prk Ldnr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PPR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Preston Prk Ldnr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'P Rabbit Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'P Rabbit Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Puppet Train',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Puppet Train',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestwick International Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Prestwick International Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Prestbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Plumpton Races',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Plumpton Races',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Preston (Lancs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Preston (Lancs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penrhyndeudraeth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penrhyndeudraeth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prince Regent (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prince Regent (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Park Royal Loop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Park Royal Loop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prittlewell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prittlewell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Portsmouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portsmouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Parton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Parton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Preston Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Princes Risborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Princes Risborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prees',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prees',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestatyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestatyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prudhoe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Prudhoe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Period Validity',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Period Validity',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Perranwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Perranwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Perry Barr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Perry Barr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPS2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Portsmouth & Southsea or Harbour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portsmouth & Southsea or Harbour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPS6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Potton Bus Sandy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Potton Bus Sandy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prestwick Atrain',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestwick Atrain',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prescot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prescot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pontns Brean Sds',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontns Brean Sds',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pitsea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pitsea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pershore',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pershore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Port Sunlight',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Port Sunlight',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Parson Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Parson Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Park+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Park+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestonpans',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestonpans',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Polesworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Polesworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPT0',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Peterborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Peterborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Port Talbot Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Port Talbot Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pentre-Bach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pentre-Bach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portchester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portchester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontarddulais',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontarddulais',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Passport To Ely',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Passport To Ely',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pantyffynnon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pantyffynnon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Port Glasgow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Port Glasgow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Perth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Perth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Partick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Partick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Priesthill & Darnley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Priesthill & Darnley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Porthmadog',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Porthmadog',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portadown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portadown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Petersfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Petersfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portrush',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portrush',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Patterton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Patterton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Portslade+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portslade+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestwick Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestwick Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pulborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pulborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPUO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Purley Oaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PUO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Purley Oaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Purley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Purley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Putney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Putney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Peebles (via Edinburgh)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Peebles (via Edinburgh)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPW1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Prem Weekend 1St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prem Weekend 1St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestwich Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Prestwich Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PWC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshaws East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pollokshaws East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pwllheli',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pwllheli',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Penalty Warning',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penalty Warning',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshaws West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pollokshaws West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PWW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Patchway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Patchway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontyclun',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pontyclun',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pye Corner',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PYE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pye Corner',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paisley Gilmour Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paisley Gilmour Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Paisley St James',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Paisley St James',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pyle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'PYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Pyle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penryn (Cornwall)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Penryn (Cornwall)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pont-y-Pant',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pont-y-Pant',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Parc Y Scarlet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Parc Y Scarlet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Poynton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Poynton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pennine Zone 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pennine Zone 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pennine Zone 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pennine Zone 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBPZ7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Preston Car Z 78',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Preston Car Z 78',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queenborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Queenborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Golflink 2015',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Golflink 2015',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'QGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hamptn Ct Flower',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hamptn Ct Flower',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'QHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queens Park (Glasgow)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Queens Park (Glasgow)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQPU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Queens Park Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Queens Park Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queens Park (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QPW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Queens Park (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Kings Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KGX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Kings Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manchester Piccadilly',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Manchester Piccadilly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham New Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Birmingham New Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Paddington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Paddington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        iataCode: 'QQS',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London St Pancras International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London St Pancras International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Euston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'London Euston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Waterloo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Waterloo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQQY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'York',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'York',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queenstown Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Queenstown Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queens Road (Peckham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Queens Road (Peckham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Quintrell Downs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QUI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Quintrell Downs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQXD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Xc Destination',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QXD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Xc Destination',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQXO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Xc Origin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'QXO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Xc Origin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBQYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Quakers Yard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Quakers Yard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'QYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rayleigh Bus1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rayleigh Bus1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Radley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Radley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rainham (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rainham (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ramsgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ramsgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rannoch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rannoch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Railmastr Sy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Railmastr Sy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rauceby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rauceby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ravenglass for Eskdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ravenglass for Eskdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Raynes Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Raynes Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rainford Zonetkt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainford Zonetkt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rhymney Bus M-F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rhymney Bus M-F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Robertsbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Robertsbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redcar British Steel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redcar British Steel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rayleigh Bus2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rayleigh Bus2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Bath & West Somerset',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royal Bath & West Somerset',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Risca & Pontymister',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RCA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Risca & Pontymister',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ryder Cup Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ryder Cup Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redcar Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RCC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redcar Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rochdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RCD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rochdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redcar East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RCE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redcar East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Radcliffe Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Radcliffe Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RCF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rochester+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rochester+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ride Cornwall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ride Cornwall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redditch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redditch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Riddlesdown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Riddlesdown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Radcliffe (Nottinghamshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Radcliffe (Nottinghamshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RDF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reading',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reading',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Redhill+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Redhill+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Riding Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Riding Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reddish North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reddish North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Radyr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Radyr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reddish South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reddish South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Radlett',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Radlett',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RDT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reading West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reading West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Docks (Cablecar)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royal Docks (Cablecar)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRE2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Refreshmnt 2.50',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Refreshmnt 2.50',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBREC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rectory Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'REC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rectory Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redruth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Redruth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBREE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reedham (Norfolk)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'REE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reedham (Norfolk)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBREI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reigate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'REI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Reigate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBREP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Refreshment Plus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Refreshment Plus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ryde Esplanade & Ryde St Johns Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ryde Esplanade & Ryde St Johns Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Retford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Retford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rochford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rochford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRFT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Red Funnel T1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Red Funnel T1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRFY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rock Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RFY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rock Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Reigate Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Reigate Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Reigate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Reigate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rugeley Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rugeley Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ramsgreave & Wilpshire',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ramsgreave & Wilpshire',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RGW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'NO',
        mainStation: 'NO',
        name: 'Robin Hood Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rhigos Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rhigos Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ribblehead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ribblehead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhiwbina',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RHI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rhiwbina',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhyl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rhyl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reedham (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Reedham (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhosneigr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rhosneigr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhymney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rhymney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRIA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rhoose Cardiff International Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rhoose Cardiff International Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RIA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rickmansworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rickmansworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ridgmont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ridgmont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rice Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rice Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rishton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rishton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRIU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Richmond Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Richmond Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rainham Kent+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainham Kent+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ruskington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RKT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ruskington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'RAILDIRECT IBM',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RAILDIRECT IBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Albert (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royal Albert (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rayleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rayleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'RETFORD LOW LVL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RETFORD LOW LVL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rowlands Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rowlands Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roman Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roman Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rotherham Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rotherham Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Richmond (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Richmond (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRMF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Romford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RMF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Romford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Romiley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Romiley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ramsgate+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ramsgate+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Runcorn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Runcorn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rainbow Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainbow Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rainford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rainhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rainham (Essex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rainham (Essex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roughton Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roughton Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ross On Wye Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ross On Wye Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Races North/ Wrl L',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Races North/ Wrl L',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roche',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roche',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rotherhithe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rotherhithe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rogart',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rogart',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rolleston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rolleston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Romsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Romsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roose',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roose',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rogerstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rogerstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ROR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rosyth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rosyth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Roundabout',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Roundabout',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBROW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rowley Regis',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ROW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rowley Regis',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryder Brow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ryder Brow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rnd Robin Cum Cs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rnd Robin Cum Cs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Robinswd Rd Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Robinswd Rd Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Reading or Reading West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Reading or Reading West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRS0',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rosythsfast Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rosythsfast Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRSG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rose Grove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rose Grove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rose Hill Marple',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rose Hill Marple',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rowsley Station (Peak Rail)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rowsley Station (Peak Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRT2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Red Funnel T2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Red Funnel T2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rochdale Tc Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rochdale Tc Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Retford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Retford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roundthorn Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Roundthorn Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Renton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Renton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rochester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rochester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rothesay (Bute)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rothesay (Bute)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRU1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Rye +Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rye +Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ruabon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ruabon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ruthin Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ruthin Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Runcorn+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Runcorn+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Runcorn East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Runcorn East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rufford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rufford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rugby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rugby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Runcorn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Runcorn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ruswarp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ruswarp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rutherglen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rutherglen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ravensbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ravensbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RVB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Victoria DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royal Victoria DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ravensthorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ravensthorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RVN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ruthin Wrx Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ruthin Wrx Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRWC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rawcliffe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rawcliffe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RWC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ruthin Wxc Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ruthin Wxc Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Royal Welsh Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royal Welsh Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRXB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Renfrew X Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Renfrew X Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roy Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Roy Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryde Esplanade',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ryde Esplanade',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rye (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RYE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rye (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rye House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rye House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Roydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryde Pier Head',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ryde Pier Head',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryde St Johns Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ryde St Johns Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBRYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Royston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Royston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBS9C',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stnstd A9 Coach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stnstd A9 Coach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBS9S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratford A9 St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford A9 St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSA1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Paul\'s Cathedral',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Paul\'s Cathedral',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSA2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratford A9 20%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford A9 20%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSA3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swindon Steam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swindon Steam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSA4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Yorks Travelmaster East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Yorks Travelmaster East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSA5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratford A9 50%',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford A9 50%',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Albans Abbey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Albans Abbey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Smallbrook Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Smallbrook Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Albans City',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Albans City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandwell & Dudley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sandwell & Dudley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saltaire',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Saltaire',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salfords (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Salfords (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Day Grp',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Day Grp',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Salhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Salisbury+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Salisbury+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Johns (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Johns (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salisbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Salisbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saltmarshe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saltmarshe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sandown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scarborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scarborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salford Qys Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Salford Qys Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SAQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Andrews Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Andrews Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Annes-on-the-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Annes-on-the-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Acton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Acton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Austell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Austell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stratford-upon-Avon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stratford-upon-Avon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sawbridgeworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sawbridgeworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saxmundham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Saxmundham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swanley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swanley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shenfield Bus2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shenfield Bus2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stroud+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stroud+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sturry+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sturry+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swanage Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swanage Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SHIRES BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHIRES BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSB9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sittingbourne Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sittingbourne Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandal Abg Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandal Abg Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Starbeck',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Starbeck',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Budeaux Ferry Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Budeaux Ferry Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sealife Bham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sealife Bham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stourbridge Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stourbridge Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Bank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Bank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Bermondsey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Bermondsey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Showboat',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Showboat',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stonebridge Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stonebridge Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spean Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Spean Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Bees',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Bees',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stourbridge Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stourbridge Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Budeaux Victoria Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Budeaux Victoria Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Selby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Selby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSC4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SHEFFIELD TCS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHEFFIELD TCS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSC5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sleaford Cc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sleaford Cc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Scarborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Scarborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Scrabster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Scrabster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southend Central or Victoria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southend Central or Victoria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Senior Rover (c2c All Line)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Senior Rover (c2c All Line)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stechford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stechford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stone Crossing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stone Crossing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Scotstounhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Scotstounhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scientriffic',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scientriffic',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stockton+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stockton+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scrbrosealifectr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scrbrosealifectr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sth Chadtn Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sth Chadtn Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stone Crown St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stone Crown St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'LONDON N WESTERN',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LONDON N WESTERN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scunthorpe+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scunthorpe+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Columb Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Columb Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Starcross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Starcross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Scotscalder',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scotscalder',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SCT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Scunthorpe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Scunthorpe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scunthorpeb Vlcn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scunthorpeb Vlcn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Senior Rover (c2c, excluding London Zones 1-6)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Senior Rover (c2c, excluding London Zones 1-6)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSCY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Croydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Croydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSD1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saveaway Area D',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saveaway Area D',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSD2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Pennies Dr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Pennies Dr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSD4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sydenham (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sydenham (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Snodland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Snodland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandbach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandbach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shoreditch High Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shoreditch High Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shadwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shadwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saundersfoot',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saundersfoot',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sudbury Hill Harrow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sudbury Hill Harrow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Line Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Line Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandhills',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandhills',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shieldmuir',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shieldmuir',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Denys',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Denys',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandplace',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandplace',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saunderton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saunderton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandy+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandy+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hastings+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hastings+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Day Fam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Day Fam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SDY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSE3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Staff Travel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Staff Travel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSE5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stromness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stromness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSE9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SETT-CARL RANGER',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sett-Carl Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seaham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Seaham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seaburn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seaburn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SEB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seaton Carew',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seaton Carew',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SEC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shelford (Cambs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shelford (Cambs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southease',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southease',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seaford (Sussex)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seaford (Sussex)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SEF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Selling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Selling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shoreham (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shoreham (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Skegness Seasider',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Skegness Seasider',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Selkirk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Selkirk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sellafield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sellafield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seamer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seamer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shenstone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shenstone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Erth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Erth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Elmsall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Elmsall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Settle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Settle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Skegness+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Skegness+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSEV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sevenoaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SEV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sevenoaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSF2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shrewsbury Winter Festival',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shrewsbury Winter Festival',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stratford International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SFA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stratford International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Staff Facilities',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Staff Facilities',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salford Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Salford Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scillonian Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scillonian Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stafford+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stafford+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shawfair',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SFI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shawfair',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seaforth & Litherland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seaforth & Litherland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shifnal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shifnal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stanford-le-Hope',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SFO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stanford-le-Hope',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shalford (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SFR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shalford (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'STANDON CALLING FESTIVAL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STANDON CALLING FESTIVAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSFY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shrewsbury Food Festival',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shrewsbury Food Festival',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSG2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Yorkshire GetAbout +25',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Yorkshire GetAbout +25',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSG3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'STEVENAGE BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STEVENAGE BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Smethwick Galton Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Smethwick Galton Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Gyle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Gyle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Germans',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Germans',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Greenford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Greenford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stone Grnvle Sq',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stone Grnvle Sq',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Slade Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Slade Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSGU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southern Gen Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southern Gen Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSH3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shotts 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shotts 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSH4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Seahill      (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seahill      (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shaw & Crom Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shaw & Crom Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shirebrook',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shirebrook',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Streethouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Streethouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shildon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shildon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sherborne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sherborne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sheffield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sheffield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shirehampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shirehampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shiplake',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shiplake',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Helens Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Helens Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shawlands',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shawlands',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sheringham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sheringham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shanklin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shanklin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sholing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sholing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shepperton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shepperton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shrewsbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Shrewsbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shotts',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shotts',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shotton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shotton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stonehouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stonehouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southsea Hoverport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southsea Hoverport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shawford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shawford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shakespeare Explorer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shakespeare Explorer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shipley (Yorks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shipley (Yorks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southend Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southend Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saints Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saints Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Silecroft',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Silecroft',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sidcup',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sidcup',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sherburn-in-Elmet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sherburn-in-Elmet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SIE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratford International (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford International (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Helier (Surrey)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Helier (Surrey)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sileby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sileby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scilly I Merlin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scilly I Merlin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Singer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Singer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shipton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shipton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scilly Isles',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scilly Isles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sittingbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sittingbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Ruislp Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Ruislp Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSIV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Ives (Cornwall)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SIV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Ives (Cornwall)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSJL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Smallbrook Jn & Brading',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Smallbrook Jn & Brading',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSJN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Helens Junction+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Helens Junction+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSJP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St James Park (Exeter)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SJP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St James Park (Exeter)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSJS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St James Street (Walthamstow)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SJS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St James Street (Walthamstow)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSJW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Johns Wood A6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Johns Wood A6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Skegness Busvlcn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Skegness Busvlcn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Skewen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Skewen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Skegness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Skegness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sherlock Holmes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sherlock Holmes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Skipton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Skipton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stoke Mandeville',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stoke Mandeville',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Keyne Wishing Well Halt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Keyne Wishing Well Halt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strood Kent+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strood Kent+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stocksfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stocksfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Spinnaker Tower',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spinnaker Tower',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Keyne Village',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Keyne Village',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSKW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stoke Newington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SKW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stoke Newington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSL1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shottle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shottle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSL4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SHADWELL DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHADWELL DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSL5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'STAR LANE DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STAR LANE DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSL6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'STRATFORD DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STRATFORD DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Slateford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Slateford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saltburn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saltburn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sealife Centre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sealife Centre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salford Crescent',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Salford Crescent',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sale Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sale Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stanford Le Hope Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stanford Le Hope Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stalybridge+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stalybridge+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sleights',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sleights',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Silkstone Common',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Silkstone Common',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stallingborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stallingborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Slough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SLO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Slough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SWT Logic Pass',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swt Logic Pass',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Leonards Warrior Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SLQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Leonards Warrior Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sleaford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sleaford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shettleston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shettleston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saltcoats',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saltcoats',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southampton Central+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southampton Central+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Silver Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Silver Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Salwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Salwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sleeper Executive',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sleeper Executive',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Selly Oak',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Selly Oak',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Small Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Small Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Smithy Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Smithy Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sampford Courtenay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sampford Courtenay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stamford (Lincs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stamford (Lincs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Margarets (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Margarets (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stamford Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stamford Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stowmarket',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stowmarket',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sea Mills',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sea Mills',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shadowmoss Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shadowmoss Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Merton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Merton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Mary\'S Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Mary\'S Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Smethwick Rolfe Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Smethwick Rolfe Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Spring Rd Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spring Rd Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Margarets (Herts)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Margarets (Herts)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSMY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Mary Cray',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SMY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Mary Cray',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSN5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shrewflower Snr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shrewflower Snr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSN6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Kenton Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Kenton Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSN7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Austell Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Austell Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandal & Agbrigg',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandal & Agbrigg',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SHEERNESS BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SHEERNESS BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swansea Conference Centre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swansea Conference Centre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sandhurst (Berks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandhurst (Berks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stone (Staffs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stone (Staffs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shenfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shenfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sunningdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sunningdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Helens Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Helens Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Snaith',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Snaith',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SNI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sankey for Penketh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sankey for Penketh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SNK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stoneleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stoneleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swinton (Manchester)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swinton (Manchester)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Neots',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'St Neots',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sanderstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sanderstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Staines',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Staines',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stanlow & Thornton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stanlow & Thornton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandwich+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandwich+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swanwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swanwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sunnymeads',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sunnymeads',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSO4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Spirit of Scotland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spirit of Scotland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southampton Airport Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southampton Airport Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southbourne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southbourne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southend Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southend Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sea Life London',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sea Life London',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southend East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southend East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Woodham Ferrers',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Woodham Ferrers',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stonegate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stonegate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Hampstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Hampstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Kenton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Kenton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Solihull',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Solihull',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Milford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'South Milford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Steeton & Silsden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Steeton & Silsden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Strood (Kent)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Strood (Kent)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sole Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sole Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stromness Orkney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stromness Orkney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stoke-on-Trent',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stoke-on-Trent',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southampton Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Southampton Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southend Victoria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southend Victoria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SOV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sowerby Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sowerby Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSOY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stornoway (Lewis)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SOY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stornoway (Lewis)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSP1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Smart PAYG Brighton & Hove+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Smart PAYG Brighton & Hove+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spalding',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Spalding',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shepherd\'s Bush',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shepherd\'s Bush',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Smart PAYG Crawley+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Smart PAYG Crawley+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stonebrge Pk Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stonebrge Pk Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Springfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Springfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Spagwestfest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spagwestfest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shepherds Well',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shepherds Well',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spital',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Spital',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sutton Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sutton Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sale W Park Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sale W Park Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spooner Row',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Spooner Row',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spondon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Spondon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shippea Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shippea Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Springburn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Springburn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stepps',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stepps',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stockport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stockport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Staplehurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SPU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Staplehurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SPT Exchange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spt Exchange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSPY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shepley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shepley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SPY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSQD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Quay (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Quay (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSQE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Surrey Quays',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SQE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Surrey Quays',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSQH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sanquhar',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sanquhar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SQH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSQU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Squires Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SQU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Squires Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSR1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stonehenge Tour',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stonehenge Tour',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSR2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ST ALBANS SHIRES',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ST ALBANS SHIRES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stratford (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stratford (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saveaway Area B',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saveaway Area B',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Streatham Common',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Streatham Common',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stapleton Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stapleton Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stretford Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stretford Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seer Green & Jordans',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seer Green & Jordans',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Streatham Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Streatham Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Spring Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Spring Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shirley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shirley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shoreham by Sea+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shoreham by Sea+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Strines',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Strines',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shireoaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shireoaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southampton Airport+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southampton Airport+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sarn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sarn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Selhurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Selhurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shortlands',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shortlands',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Ruislip',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Ruislip',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saveaway Area C',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saveaway Area C',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shoeburyness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shoeburyness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSS6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Neots Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Neots Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSS8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stow On Wold Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stow On Wold Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sissinghurst Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sissinghurst Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seascale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seascale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SSC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stansted Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SSD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stansted Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shoreham-by-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shoreham-by-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SSE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southend Sealife',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southend Sealife',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shrewflower Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shrewflower Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandown & Lake & Shanklin',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandown & Lake & Shanklin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scilly Isles',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scilly Isles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stocksmoor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stocksmoor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SUFC Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sufc Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Swindon Steam Sr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swindon Steam Sr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sheerness-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sheerness-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stansted Mountfitchet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stansted Mountfitchet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Neots+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Neots+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Short Hop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Short Hop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sp Flower Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sp Flower Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Season Exchange',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Season Exchange',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSSY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Scilly Skybus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Scilly Skybus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBST5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SY Travelmaster South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SY Travelmaster South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBST9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratford A9',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford A9',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stafford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stafford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Strathcarron',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Strathcarron',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stroud (Gloucs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stroud (Gloucs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Streatham (Greater London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Streatham (Greater London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stromeferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stromeferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stirling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stirling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Shepreth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shepreth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stadium of Light',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stadium of Light',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Severn Tunnel Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Severn Tunnel Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stockton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stockton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Michaels',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Michaels',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stonehaven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stonehaven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Tottenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Tottenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London St Pancras',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London St Pancras Ll',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Soton Town Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Soton Town Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stranraer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Stranraer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saltash',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saltash',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stewarton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stewarton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sturry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'STU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sturry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stevenston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stevenston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Strawberry Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strawberry Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stratford-upon-Avon Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratford-upon-Avon Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSTZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Peters',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Peters',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'STZ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Snowdon Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Snowdon Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southampt Cl Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southampt Cl Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southm A Sbl Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southm A Sbl Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southport+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southport+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Helens Central+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Helens Central+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSU9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Mawes Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Mawes Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shf Supertram',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shf Supertram',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southport Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southport Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sutton Common',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sutton Common',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sudbury & Harrow Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sudbury & Harrow Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stonehouse+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stonehouse+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sugar Loaf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sugar Loaf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southampton International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southampton International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southwick+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southwick+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Summerston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Summerston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sunderland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sunderland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sutton (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sutton (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sundridge Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sundridge Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SUP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Surbiton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Surbiton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sandy Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sandy Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sutton Coldfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sutton Coldfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sunbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sunbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SUU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSUY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sudbury (Suffolk)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sudbury (Suffolk)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SUY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSV2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SWT ITSO B TRVL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SWT ITSO B TRVL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saveaway Allzone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saveaway Allzone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Severn Beach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Severn Beach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Camb Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Camb Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Severnbch Dayrgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Severnbch Dayrgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Selkirk (via Edinburgh)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Selkirk (via Edinburgh)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stevenage',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stevenage',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southend Vic Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southend Vic Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seven Kings',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seven Kings',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Staveley (Cumbria)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Staveley (Cumbria)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sthsea Hoverline',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sthsea Hoverline',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Ives Pk&Rde05',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Ives Pk&Rde05',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Silverdale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Silverdale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Seven Sisters',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Seven Sisters',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SVS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Staverton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Staverton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'SCOTRAIL ARRVBUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SCOTRAIL ARRVBUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSVZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shugbrgh Viazaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shugbrgh Viazaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSW3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Wales 3In7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Wales 3In7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swansea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SWA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swansea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swinderby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swinderby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swineshead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SWE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swineshead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sth Woodhm F Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sth Woodhm F Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swaythling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swaythling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swindon (Wilts)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SWI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Swindon (Wilts)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'St Werburgh Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Werburgh Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swanscombe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swanscombe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Swinton (South Yorks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Swinton (South Yorks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Snowdown',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Snowdown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stewartby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stewartby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'South Wigston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Wigston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Slaithwaite',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Slaithwaite',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSXS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Shkspere Exp Snr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Shkspere Exp Snr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSXY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Saxilby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saxilby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SXY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Styal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Styal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SYA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stalybridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stalybridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sydenham (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SYD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sydenham (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Yorkshire GetAbout +18',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Yorkshire GetAbout +18',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sydenham Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sydenham Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SYH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Syon Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Syon Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Saveaway Area A',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Saveaway Area A',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Syston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Syston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Somerleyton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Somerleyton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'SYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSYU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Yorkshire GetAbout +21',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Yorkshire GetAbout +21',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 10',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 10',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 8',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 8',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZ9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 9',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 9',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone All',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone All',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 11',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 11',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBSZT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Strathc Zone 12',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Strathc Zone 12',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tame Bridge Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tame Bridge Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tackley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tackley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tadworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tadworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Teesside Air Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Teesside Air Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Taffs Well',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Taffs Well',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tain',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tain',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Talsarnau',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Talsarnau',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tamworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tamworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Taplow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Taplow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tattenham Corner',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tattenham Corner',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Taunton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Taunton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTAY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Taynuilt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TAY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Taynuilt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tonbridge Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tonbridge Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tredegar Bus Cdf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tredegar Bus Cdf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Three Bridges',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Three Bridges',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thameslink Bedford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thameslink Bedford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tredegar Bus M-F',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tredegar Bus M-F',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Three Bridges+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Three Bridges+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Traffrd Bar Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TBM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Traffrd Bar Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tilbury Riverside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tilbury Riverside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tunbridge Wells',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TBW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tunbridge Wells',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornaby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thornaby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTBZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tvm Barnsley Zn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tvm Barnsley Zn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTC5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'TFW CLUB 55',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TFW CLUB 55',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTCA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tw Child All Day',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tw Child All Day',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trecynon Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trecynon Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTCC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Torquay Conf Ctr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Torquay Conf Ctr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tottenham Court Road Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TCR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tottenham Court Road Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTDU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tondu',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TDU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tondu',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTDZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tvm Doncaster Zn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tvm Doncaster Zn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Teesside Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Teesside Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tetbury Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tetbury Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Teddington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Teddington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTEN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tenby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TEN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tenby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Theobalds Grove',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Theobalds Grove',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tenterden-Rye Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tenterden-Rye Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Teynham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Teynham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTFC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Telford Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Telford Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TFC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Transport Gala',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Transport Gala',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTGM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Teignmouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Teignmouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TGM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ty Glas',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ty Glas',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'The Grove Watford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'The Grove Watford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thatcham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thatcham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornliebank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thornliebank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thurnscoe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thurnscoe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thames Ditton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thames Ditton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'THD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Theale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Theale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thatto Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thatto Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'THH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thirsk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thirsk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tile Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tile Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thornford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thorpe Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorpe Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thurso',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thurso',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorntonhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorntonhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'THT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thurgarton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thurgarton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'The Hawthorns',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'The Hawthorns',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'THW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thornaby+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thornaby+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTHZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtnwk Zone12*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtnwk Zone12*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tidworth Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tidworth Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tilbury Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tilbury Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Timperley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TIM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Timperley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tring+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tring+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tipton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TIP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tipton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tir-Phil',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tir-Phil',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tisbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tisbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtnwkzone2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtnwkzone2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tewkesbury Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tewkesbury Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Talybont',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Talybont',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tal-y-Cafn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tal-y-Cafn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thameslink Dunstable',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thameslink Dunstable',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tilehurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tilehurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TLH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tarka Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tarka Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'The Lakes (Warks)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'The Lakes (Warks)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TLK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtwk Allzone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtwk Allzone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tyndrum Lower or Upper',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tyndrum Lower or Upper',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorpe-le-Soken',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorpe-le-Soken',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thames Branches',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thames Branches',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Templecombe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Templecombe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tramlink LT',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tramlink Lt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Timperley (Metrolink)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Timperley (Metrolink)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Travel Master South Yorks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Travel Master South Yorks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tamworth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tamworth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornton Abbey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thornton Abbey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tonfanau',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tonfanau',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trooperslane (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trooperslane (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorne North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorne North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thorne North or South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorne North or South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tonypandy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tonypandy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tonbridge+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tonbridge+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorne South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorne South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thornton Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thornton Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Totnes Conf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Totnes Conf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Todmorden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Todmorden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Three Oaks',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Three Oaks',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tolworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tolworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tonbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tonbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tooting',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tooting',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Topsham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Topsham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Totnes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Totnes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTOY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Toy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Toy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorpe Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorpe Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TPB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thorpe Culvert',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorpe Culvert',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TPC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTPN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ton Pentre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ton Pentre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TPN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTQY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Torquay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Torquay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TQY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trafford Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Trafford Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Treherbert',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Treherbert',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Threecountiesshw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Threecountiesshw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Troed-y-Rhiw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Troed-y-Rhiw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trefforest Estate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Trefforest Estate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trefforest',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trefforest',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trehafod',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trehafod',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tring',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tring',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tramlink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tramlink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trimley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trimley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Troon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Troon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Trowbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Trowbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Torre',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Torre',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thurston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thurston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtnwkzone1234',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtnwkzone1234',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Truro',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Truro',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thames Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thames Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Term Extra',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Term Extra',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Treorchy',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Treorchy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTRZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tvm Rotherham Zn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tvm Rotherham Zn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thameslink St Albans',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thameslink St Albans',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tees Dayrider',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tees Dayrider',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thfc Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thfc Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTSZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tvm Sheffield Zn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tvm Sheffield Zn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTT1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tamworth High Level',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tamworth High Level',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTT2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Transfare T&W 2Z',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Transfare T&W 2Z',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTT3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Transfare T&W 3Z',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Transfare T&W 3Z',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTT9',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tyne & Tees',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tyne & Tees',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Transfare T&W Cd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Transfare T&W Cd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thetford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thetford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornton Heath',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thornton Heath',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tottenham Hale',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tottenham Hale',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Totton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Totton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tilbury Town or Riverside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tilbury Town or Riverside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTTZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Transfare T&W Z1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Transfare T&W Z1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTUH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tulse Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TUH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tulse Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTUL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tulloch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tulloch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTUR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Turkey Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TUR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Turkey Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTUT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tutbury & Hatton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TUT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tutbury & Hatton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTUW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tunbridge Wells+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tunbridge Wells+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 1',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 1',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 3',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 3',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 4',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 4',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 5',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 5',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTV6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Trvcard 6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Trvcard 6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thorney Via Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thorney Via Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTVD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tamr Vly Dy Rngr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tamr Vly Dy Rngr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTVM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tamar Valley Line',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tamar Valley Line',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tiverton Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tiverton Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TVP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTVT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thames Avon Trio',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thames Avon Trio',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'THAMES WHARF DLR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THAMES WHARF DLR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tweedbank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TWB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tweedbank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tyne & W Day Rvr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tyne & W Day Rvr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Twickenham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TWI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Twickenham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tile Hill Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tile Hill Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Town Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Town Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TWN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Two Wk Off Peak',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Two Wk Off Peak',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tunbridge Wells Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tunbridge Wells Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tunbridge Wells West',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tunbridge Wells West',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTWY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Twyford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Twyford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTYC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ty Croes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ty Croes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TYC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTYG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tygwyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TYG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tygwyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tyndrum Lower',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tyndrum Lower',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTYS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tyseley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TYS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tyseley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTYW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tywyn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TYW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Tywyn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtnwk Zone 1*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtnwk Zone 1*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTZN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Thirtnwk Zone123',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thirtnwk Zone123',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBTZU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'T10 Zone U1 Lndn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'T10 Zone U1 Lndn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ullswater Bus & Boat',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ullswater Bus & Boat',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Uckfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Uckfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Uddingston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UDD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Uddingston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'UFN Issued',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ufn Issued',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Uphall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UHA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Uphall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upper Holloway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Upper Holloway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBULC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ulceby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ULC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ulceby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBULL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ulleskelf',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ULL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ulleskelf',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBULP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ullapool',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ullapool',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ULP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBULV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ulverston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ULV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ulverston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Umberleigh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Umberleigh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Unaccmpniedchild',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Unaccmpniedchild',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'University (Birmingham)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UNI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'University (Birmingham)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUNZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Unizone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Unizone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUPH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upper Halliford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UPH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Upper Halliford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upholland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Upholland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'UPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Upminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUPT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upton (Merseyside)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Upton (Merseyside)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUPW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upwey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UPW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Upwey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBURM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Urmston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'URM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Urmston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Uttoxeter',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'UTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Uttoxeter',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upper Tyndrum',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Upper Tyndrum',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'UTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUVS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Uxbridge (via Slough) Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Uxbridge (via Slough) Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBUWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upper Warlingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Upper Warlingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'UWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Valley Explorer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Valley Explorer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Valley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'VAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Valley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Vt Car Link',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Vt Car Link',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ventnor Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ventnor Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Virginia Water',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'VIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Virginia Water',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVIT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Vintage Trains',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Vintage Trains',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Valleys Night Rider',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Valleys Night Rider',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Velopark Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Velopark Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'VPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Vt Catering',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Vt Catering',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBVXH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Vauxhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Vauxhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'VXH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wallingford-Reading Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wallingford-Reading Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warrington Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Warrington Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wadhurst',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wadhurst',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Waterloo East',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Waterloo East',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WAE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wallyford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wallyford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitby Arriva Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitby Arriva Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walton-on-Thames',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Walton-on-Thames',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walmer',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walmer',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WAM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wanborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wanborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walton (Merseyside)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walton (Merseyside)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WAO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warwick Parkway+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick Parkway+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ware (Herts)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ware (Herts)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watton-at-Stone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Watton-at-Stone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wavertree Technology Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WAV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wavertree Technology Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warwick+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWAZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids All Zones',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids All Zones',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWB2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Calder/Bgate 2',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Calder/Bgate 2',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WITNEY HANBOROUGH BUS',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WITNEY HANBOROUGH BUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waterbeach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waterbeach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WBC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitley Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whitley Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warblington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warblington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WBL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wimbledon Chase',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WBO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wimbledon Chase',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Brompton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WBP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Brompton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warrington Bank Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warrington Bank Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WBQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whaley Bridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WBR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whaley Bridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Woodstock Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woodstock Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Waterloovile Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waterloovile Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitby Bus (via X93)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitby Bus (via X93)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Byfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Byfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westcombe Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Westcombe Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westcliff',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Westcliff',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitchurch (Hampshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whitchurch (Hampshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Calder',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Calder',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wickham Market',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wickham Market',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warrington Central or Bank Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warrington Central or Bank Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worcester Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Worcester Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitecraigs',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whitecraigs',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Windsor Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windsor Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wembley Ctl Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wembley Ctl Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wembley Stadium',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wembley Stadium',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWCY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Croydon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WCY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Croydon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Midlands Day Ranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Midlands Day Ranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Widdrington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Widdrington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wood End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wood End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodhouse',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodhouse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'W Didsbury Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Didsbury Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WDI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodhall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodhall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Windermere',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Windermere',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walsden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walsden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waddon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waddon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WDO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Queens Road Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Queens Road Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodlesford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodlesford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Drayton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Drayton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Dulwich',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WDU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Dulwich',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WY BUS DAYROVER',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wy Bus Dayrover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWDZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids Z12***',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids Z12***',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Ealing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Ealing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Westhill Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westhill Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WYCA ENCTS WINTER OFFER DR',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYCA ENCTS WINTER OFFER DR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWED',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wedgwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WED',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wedgwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weeley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Weeley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Weekend First',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weekend First',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Ham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'West Ham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wellingborough+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wellingborough+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wellingborough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wellingborough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wem',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wedgwood Old Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wedgwood Old Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wel Park&Ride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wel Park&Ride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWER',
        countryCode: 'GB',
        fareStation: 'NO',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wedgwood Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wedgwood Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Westerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weeton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weeton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Windermere+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windermere+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitlock E Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitlock E Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weymouth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WEY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Weymouth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWEZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Z123**',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Z123**',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whifflet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WFF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whifflet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watford High Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watford High Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westerfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westerfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watford Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watford Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wainfleet',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wainfleet',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watford North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watford North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWFS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wisley Flower Show',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wisley Flower Show',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westgate-on-Sea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westgate-on-Sea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WGA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welwyn Garden City',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welwyn Garden City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WGC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wylde Gree Wmbus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wylde Gree Wmbus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wigan North Western',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WGN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wigan North Western',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodgrange Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodgrange Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wigton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WGT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wigton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wargrave',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wargrave',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WGV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWGW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wigan Wallgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WGW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wigan Wallgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWH2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whitstable+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitstable+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westenhanger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westenhanger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walthamstow Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walthamstow Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Hampstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Hampstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whalley (Lancs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whalley (Lancs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westhoughton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westhoughton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitstable',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WHI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whitstable',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wythnshw Tc Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WHK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wythnshw Tc Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'White Hart Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'White Hart Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whimple',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whimple',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whiston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whiston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Hampstead or West Hampstead Thameslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Hampstead or West Hampstead Thameslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Hampstead Thameslink',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Hampstead Thameslink',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Horndon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Horndon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whyteleafe South',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whyteleafe South',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitchurch (Cardiff)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitchurch (Cardiff)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whyteleafe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Whyteleafe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWHZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whipsnade Zoo',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whipsnade Zoo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wimborne Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wimborne Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wickford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wickford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Widnes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Widnes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Widnes+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Widnes+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winchmore Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Winchmore Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Willesden Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Willesden Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Willington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Willington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wimbledon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wimbledon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winchester',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Winchester',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West India Quay (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West India Quay (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wirksworth',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wirksworth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wisbech (Coach)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wisbech (Coach)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wivenhoe',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WIV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wivenhoe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWIZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids Z1****',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids Z1****',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWJN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Willesden Jn Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Willesden Jn Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWJU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Waterloo Jubilee (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waterloo Jubilee (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Kilbride',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Kilbride',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Weekdays Conc',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weekdays Conc',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walkden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walkden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wakefield Westgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wakefield Westgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Workington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WKG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Workington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Kirby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Kirby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wakefield Kirkgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wakefield Kirkgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wokingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WKM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wokingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWKR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Weekend Rover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weekend Rover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wallingford-Didcot Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wallingford-Didcot Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waltham Cross',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waltham Cross',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West St Leonards',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West St Leonards',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whittlesea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whittlesea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whittlesford Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whittlesford Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wallasey Grove Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wallasey Grove Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Williamwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Williamwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wellington (Shropshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wellington (Shropshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waterloo (Merseyside)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waterloo (Merseyside)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welshpool',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welshpool',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woolston',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WLS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woolston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wallington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wallington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wood Lane (Underground)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wood Lane (Underground)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wallasey Village',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wallasey Village',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welwyn North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welwyn North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWLY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WLY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Malling',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Malling',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wembley Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wembley Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wilmcote',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wilmcote',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wymondham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wymondham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWME',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodmansterne',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WME',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodmansterne',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Welham Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Welham Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Waddon Marsh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waddon Marsh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wildmill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wildmill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWML',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wilmslow',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wilmslow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WML',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Withington Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Withington Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Widney Manor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Widney Manor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wemyss Bay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wemyss Bay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weaste Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weaste Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wimbledon Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wimbledon Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walthamstow Queens Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walthamstow Queens Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WMW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWMZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids Z*2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids Z*2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Whiteabbey (N Ireland)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whiteabbey (N Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Windsor & Eton Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windsor & Eton Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wendover',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wendover',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wilnecote (Staffs)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wilnecote (Staffs)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winchfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winchfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waun-Gron Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waun-Gron Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warnham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warnham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winchelsea New I',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winchelsea New I',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whinhill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whinhill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weston Milton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weston Milton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wennington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wennington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wanstead Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wanstead Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Windsor & Eton Riverside',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Windsor & Eton Riverside',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winnersh',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winnersh',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wandsworth Town',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wandsworth Town',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Norwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Norwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWNY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'White Notley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'White Notley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woburn Sands',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woburn Sands',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wedgwood Old Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wedgwood Old Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WOODSIDE',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOODSIDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worcester Foregate Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Worcester Foregate Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woldingham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woldingham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Woking+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woking+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woking',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woking',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wolverton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wolverton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wombwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wombwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWON',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walton-on-the-Naze',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walton-on-the-Naze',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WON',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wool',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wool',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worksop+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worksop+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WOR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Worle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worcester Shrub Hill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worcester Shrub Hill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WOS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worthing+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worthing+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worthing Unizone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worthing Unizone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWOW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'WOOLWICH CROSSRAIL',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woolwich Crossrail',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WOW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWP4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wales Pass 4In8',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wales Pass 4In8',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warwick Pway Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick Pway Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wapping',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wapping',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WPE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wimbledon Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wimbledon Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WPK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worplesdon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worplesdon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WPL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wythnshw Pk Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wythnshw Pk Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Student',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Student',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWPZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Z12***',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Z12***',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wrabness',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wrabness',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warwick Castle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick Castle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wrenbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WRE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wrenbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worcester Shrub Hill or Foregate St',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worcester Shrub Hill or Foregate St',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worthing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worthing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worksop',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worksop',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wetheral',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wetheral',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wareham (Dorset)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wareham (Dorset)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Runton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Runton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warwick Parkway',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick Parkway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wherrylineranger',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wherrylineranger',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wressle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wressle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worstead',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worstead',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Ruislip',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Ruislip',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warwick',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warwick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wrexham General',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wrexham General',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWRY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wraysbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wraysbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WRY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Allerton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Allerton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Waverly Stn Cab',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waverly Stn Cab',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Westferry (DLR)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westferry (DLR)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winchelsea',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winchelsea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winsford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winsford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wishaw',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wishaw',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walsall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Walsall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weston-super-Mare',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weston-super-Mare',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Winchstr Sbl Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winchstr Sbl Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wcrc Scarbro Spa',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wcrc Scarbro Spa',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodsmoor',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woodsmoor',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wood Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wood Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Sutton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Sutton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wandsworth Common',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wandsworth Common',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WSW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWSZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids Z123**',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids Z123**',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wester Hailes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wester Hailes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitby',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitby',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitchurch (Shropshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitchurch (Shropshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitlocks End',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitlocks End',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitefield Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitefield Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watlington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watlington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitehaven',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitehaven',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winnersh Triangle',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Winnersh Triangle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitland',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitland',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Witham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitton (London)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitton (London)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Water Orton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Water Orton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wateringbury',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wateringbury',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whatstandwell',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whatstandwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Witton (West Midlands)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witton (West Midlands)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Cowes',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Cowes',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWTY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Witley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WTY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Witham Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witham Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Witney Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Witney Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Workington Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Workington Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU5',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Worthing Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Worthing Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU6',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wycombe Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wycombe Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU7',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wells - Bath Spa Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wells - Bath Spa Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWU8',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Ruislip Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Ruislip Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WHUFC Season',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whufc Season',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWVB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wivenhoe Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wivenhoe Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWVF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wivelsfield',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wivelsfield',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WVF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWVH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wolverhampton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wolverhampton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WVH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woolwich Arsenal',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woolwich Arsenal',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wythall WM Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wythall WM Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woolwich Dockyard',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Woolwich Dockyard',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wakefield Westgate or Kirkgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wakefield Westgate or Kirkgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wigan North Western or Wallgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wigan North Western or Wallgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Wickham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Wickham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitwell (Derbyshire)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitwell (Derbyshire)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westwood Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westwood Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'West Worthing',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Worthing',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wandsworth Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wandsworth Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'West Wales Dyrgr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'West Wales Dyrgr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWWW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wootton Wawen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wootton Wawen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WWW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWXB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wantage Oxf Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wantage Oxf Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWXC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wrexham Central',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wrexham Central',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WXC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWXS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wells Nxt Seabus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wells Nxt Seabus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weybridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weybridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WYB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Dayrvr-Cl',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Dayrvr-Cl',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wye',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Weymouth+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weymouth+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wylde Green',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wylde Green',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wylam',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wylam',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WYM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Weymouth Quay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Weymouth Quay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WYQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wythall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Wythall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWYZ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Z*2345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Z*2345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWZ1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WYPTE Zones 1 to 5+6',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYPTE Zones 1 to 5+6',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWZ2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'W Mids Z1234*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'W Mids Z1234*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWZ3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Z1234*',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Z1234*',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWZ4',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wypte Z12345',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wypte Z12345',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBWZP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'WYPTE Zones 1 to 5+7',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WYPTE Zones 1 to 5+7',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXCQ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chinnor, The Red Lion',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chinnor, The Red Lion',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XCQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXDJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Plymouth Salt Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Plymouth Salt Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XDJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chippenham New Rd',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chippenham New Rd',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XDN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXDP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Totnes Stn Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Totnes Stn Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XDP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXEI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Neots Bus Mk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Neots Bus Mk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Nutfield Memorial Hall',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Nutfield Memorial Hall',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXID',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Idridgehay',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Idridgehay',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XID',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXLB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Leeds Bradford Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Leeds Bradford Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Altrincham Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Altrincham Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXNE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham Airport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Birmingham Airport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XNE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXOF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gosport Ferry',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gosport Ferry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XOF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXQE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        iataCode: 'XQE',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ebbsfleet International',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ebbsfleet International',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'EBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXQT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lichfield Trent Valley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lichfield Trent Valley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LTV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ryde Hoverport',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ryde Hoverport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXRG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rugeley Trent Valley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RGL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Rugeley Trent Valley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rchdle Stn Mtlk',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rchdle Stn Mtlk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBXVE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Oxford Westgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Westgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XVE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYAB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yarmouth Buszone',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yarmouth Buszone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YAE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYAI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yarmouth (IoW)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yarmouth (IoW)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yalding',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yalding',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYAT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yatton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YAT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yatton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ystradgnlais Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ystradgnlais Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYCD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yrk Cst Day Rngr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yrk Cst Day Rngr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYCF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yrk Cst Fam Rngr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yrk Cst Fam Rngr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYEJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yeovil Junction+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yeovil Junction+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yeoford',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yeoford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yetminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yetminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yarmouth (Isle-of-Wight)',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yarmouth (Isle-of-Wight)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ynyswen',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YNW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ynyswen',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYOD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'York Dungeon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'York Dungeon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYOK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yoker',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YOK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Yoker',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yeovil Pen Mill+Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yeovil Pen Mill+Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYRB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Yarmouthplsr Bch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yarmouthplsr Bch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYRD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yardley Wood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yardley Wood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YRD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'York Races Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'York Races Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYRM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yarm',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yarm',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YRM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYRT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yorton',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yorton',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YRT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYSB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ystalyferra Bus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ystalyferra Bus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ystrad Mynach',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ystrad Mynach',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYSR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ystrad Rhondda',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'YSR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ystrad Rhondda',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYVJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yeovil Junction',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yeovil Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YVJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBYVP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Yeovil Pen Mill',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Yeovil Pen Mill',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'YVP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ1E',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone 12  Student',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone 12  Student',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ1N',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Bushey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Bushey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ1S',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Carpenders Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Carpenders Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ2T',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone 2 Two Juv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone 2 Two Juv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ3T',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone 3 Three Juv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone 3 Three Juv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ4F',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone 4 Four Juv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone 4 Four Juv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZ5F',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone 5 Five Juv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone 5 Five Juv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZA1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Watford High Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Watford High Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Cutty Sark',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Cutty Sark',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Elverson Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elverson Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldgate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldgate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Aldgate East Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Aldgate East Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zone   All Juv',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zone   All Juv',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Abbey Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Abbey Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Angel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Angel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZAN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Rickmansworth Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Rickmansworth Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZAW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Watford High Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Watford High Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bank',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bank',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barbican',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barbican',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bond Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bond Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Baker Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Baker Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Borough',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Borough',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZBY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bayswater',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bayswater',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Chesham Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Chesham Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Chesham Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chesham Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Croxley',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Croxley',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZCO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Covent Garden',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Covent Garden',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZCV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Canada Water',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Canada Water',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZCW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZCY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Chancery Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Chancery Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZCY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZDB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Debden Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Debden Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZDB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZDE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dagenham East L.T.',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Dagenham East L.T.',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZDE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZDN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'ZReading - Do not Use',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ZReading - Do not Use',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZEH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Ham Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Ham Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZEH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZEK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Embankment',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Embankment',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZEK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZEL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Elephant and Castle Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Elephant and Castle Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZEL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZEO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bermondsey Lrt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bermondsey Lrt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZEO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZEP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Victoria',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Victoria',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'VIC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZER',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Edgware Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edgware Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZER',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZES',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Euston Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Euston Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZES',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZET',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Earl\'s Court',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Earl\'s Court',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZET',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZFD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farringdon',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Farringdon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZGE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Green Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Green Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZGE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZGP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Great Portland Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Great Portland Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZGP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZGR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Gloucester Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gloucester Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZGS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Goodge Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Goodge Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZGS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hammersmith (District) Lul',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hammersmith (District) Lul',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hillingdon Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hillingdon Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHJ',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Heathrow Underground',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Heathrow Underground',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Holborn',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Holborn',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Holloway Rd Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Holloway Rd Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'High Street Kensington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'High Street Kensington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZHY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Hyde Park Corner',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Hyde Park Corner',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZHY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZKB',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barking L.T.',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Barking L.T.',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZKB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZKN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Knightsbridge',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Knightsbridge',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZKN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZLA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lancaster Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lancaster Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZLN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Lambeth North',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Lambeth North',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZLN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZLS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'London Liverpool Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'London Liverpool Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            source: 'ATOC',
            type: 'SMARTCARD'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZLW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Whitechapel',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Whitechapel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZLW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZMA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Marble Arch',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Marble Arch',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZMA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZMH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Mansion House',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mansion House',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZMH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZMM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Monument',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Monument',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZMM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZN1',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Croxley Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Croxley Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZN2',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Croxley Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Croxley Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZN3',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Watford Met Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Watford Met Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZNA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'North Acton Underground',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'North Acton Underground',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZNA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZNC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Chorleywood Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Chorleywood Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZND',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Northwood',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Northwood',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZND',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZNG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Notting Hill Gate',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Notting Hill Gate',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZNG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZNR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Rickmansworth Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Rickmansworth Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZNW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Watford Met Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Watford Met Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZOC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Oxford Circus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Oxford Circus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZOC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZPC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Piccadilly Circus',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Piccadilly Circus',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZPC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZPM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pudding Mill Lane',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pudding Mill Lane',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZPM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZPO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Pimlico',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Pimlico',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZPO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZPU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'East Putney',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'East Putney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZPU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Amersham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Amersham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Chalfont & Latimer Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Chalfont & Latimer Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Carpenders Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Carpenders Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones R123456 & Bushey',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones R123456 & Bushey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Regents Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Regents Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZRP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZRS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Russell Square',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Russell Square',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZRS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZSK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'South Kensington',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'South Kensington',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZSK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZSM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stanmore Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stanmore Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZSM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZSO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Southwark Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Southwark Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZSO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZSP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St Paul\'s',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St Paul\'s',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZSP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZSS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'St James\'s Park',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'St James\'s Park',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZST',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Stratfrd Hst Dlr',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Stratfrd Hst Dlr',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZST',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZTC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tottenham Court Road',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tottenham Court Road',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZTC',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZTH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Tower Hill L.T.',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tower Hill L.T.',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZTH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZTM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Temple',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Temple',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZTM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZTU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Turnham Green Lt',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Turnham Green Lt',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZTU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZUA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Amersham',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Amersham',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZUE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Chesham Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Chesham Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZUM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Upminster L.T.',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Upminster L.T.',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZUN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Chalfont & Latimer Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Chalfont & Latimer Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZUS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Zones U123456 & Chorleywood Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Zones U123456 & Chorleywood Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZWM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Westminster',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westminster',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZWM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZWS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Warren Street',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Warren Street',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZWS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GBZWT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watford Met Und',
        timeZone: 'Europe/London',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Watford Met Und',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ZWT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Barry (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBRY'
              }
            },
            {
              attributes: {
                code: 'GBBYD'
              }
            },
            {
              attributes: {
                code: 'GBBYI'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birmingham International (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBHX'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BK',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Birkenhead (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBKC'
              }
            },
            {
              attributes: {
                code: 'GBBKN'
              }
            },
            {
              attributes: {
                code: 'GBBKP'
              }
            },
            {
              attributes: {
                code: 'GBBKQ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bootle (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBNW'
              }
            },
            {
              attributes: {
                code: 'GBBOT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromborough (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBMR'
              }
            },
            {
              attributes: {
                code: 'GBBOM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Brundall (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBDA'
              }
            },
            {
              attributes: {
                code: 'GBBGA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bradford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBDI'
              }
            },
            {
              attributes: {
                code: 'GBBDQ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bloxwich (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBLX'
              }
            },
            {
              attributes: {
                code: 'GBBWN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^BY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bromley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBBMN'
              }
            },
            {
              attributes: {
                code: 'GBBMS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^CA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cardiff International Airport (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRIA'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^CL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carlton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCTO'
              }
            },
            {
              attributes: {
                code: 'GBNET'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^CS',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Colchester (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBCET'
              }
            },
            {
              attributes: {
                code: 'GBCOL'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^GN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greenock (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBGKC'
              }
            },
            {
              attributes: {
                code: 'GBGKW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harrow (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHOH'
              }
            },
            {
              attributes: {
                code: 'GBHRW'
              }
            },
            {
              attributes: {
                code: 'GBSDH'
              }
            },
            {
              attributes: {
                code: 'GBSUD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HC',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harwich (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHPQ'
              }
            },
            {
              attributes: {
                code: 'GBHWC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hawarden (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHWB'
              }
            },
            {
              attributes: {
                code: 'GBHWD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harringay (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHGY'
              }
            },
            {
              attributes: {
                code: 'GBHRY'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Hertford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHFE'
              }
            },
            {
              attributes: {
                code: 'GBHFN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^HW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Harlow (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBHWM'
              }
            },
            {
              attributes: {
                code: 'GBHWN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^KP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kings Cross St Pancras (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'GB^KT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kenton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBKNT'
              }
            },
            {
              attributes: {
                code: 'GBSOK'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^LF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lichfield (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLIC'
              }
            },
            {
              attributes: {
                code: 'GBXQT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^LN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Lymington (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLYP'
              }
            },
            {
              attributes: {
                code: 'GBLYT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^LV',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Livingston (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBLSN'
              }
            },
            {
              attributes: {
                code: 'GBLVG'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^NM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'New Mills (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNMC'
              }
            },
            {
              attributes: {
                code: 'GBNMN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^PF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pontefract (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPFM'
              }
            },
            {
              attributes: {
                code: 'GBPFR'
              }
            },
            {
              attributes: {
                code: 'GBPOT'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^PG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Penge (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPNE'
              }
            },
            {
              attributes: {
                code: 'GBPNW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^PH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portsmouth (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBFTN'
              }
            },
            {
              attributes: {
                code: 'GBPMH'
              }
            },
            {
              attributes: {
                code: 'GBPMS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^PL',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Pollokshields (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPLE'
              }
            },
            {
              attributes: {
                code: 'GBPLW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^PR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Prestwick (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBPRA'
              }
            },
            {
              attributes: {
                code: 'GBPTW'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^RD',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redcar (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRBS'
              }
            },
            {
              attributes: {
                code: 'GBRCC'
              }
            },
            {
              attributes: {
                code: 'GBRCE'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^RE',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Redditch (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRDC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^RH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Reddish (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRDN'
              }
            },
            {
              attributes: {
                code: 'GBRDS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^SA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Stratford-upon-Avon (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSAV'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^SP',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Southport (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSOP'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^SU',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sutton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSUC'
              }
            },
            {
              attributes: {
                code: 'GBSUO'
              }
            },
            {
              attributes: {
                code: 'GBWSU'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WA',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walthamstow (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBSJS'
              }
            },
            {
              attributes: {
                code: 'GBWHC'
              }
            },
            {
              attributes: {
                code: 'GBWMW'
              }
            },
            {
              attributes: {
                code: 'GBWST'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WF',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Watford (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWFH'
              }
            },
            {
              attributes: {
                code: 'GBWFJ'
              }
            },
            {
              attributes: {
                code: 'GBWFN'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WG',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Warrington (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWAC'
              }
            },
            {
              attributes: {
                code: 'GBWBQ'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WH',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worthing (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBEWR'
              }
            },
            {
              attributes: {
                code: 'GBWRH'
              }
            },
            {
              attributes: {
                code: 'GBWWO'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WI',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Winnersh (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWNS'
              }
            },
            {
              attributes: {
                code: 'GBWTI'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WM',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wimbledon (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWBO'
              }
            },
            {
              attributes: {
                code: 'GBWIM'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WN',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Windsor And Eton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWNC'
              }
            },
            {
              attributes: {
                code: 'GBWNR'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WO',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woolwich (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWWA'
              }
            },
            {
              attributes: {
                code: 'GBWWD'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WR',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Worcester (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWOF'
              }
            },
            {
              attributes: {
                code: 'GBWOS'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WT',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Walton (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBRIL'
              }
            },
            {
              attributes: {
                code: 'GBWAO'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WW',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wandsworth (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWNT'
              }
            },
            {
              attributes: {
                code: 'GBWSW'
              }
            },
            {
              attributes: {
                code: 'GBWWR'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WX',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wrexham (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBWRX'
              }
            },
            {
              attributes: {
                code: 'GBWXC'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'GB^WY',
        countryCode: 'GB',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wembley (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'GBNWB'
              }
            },
            {
              attributes: {
                code: 'GBWCX'
              }
            },
            {
              attributes: {
                code: 'GBWMB'
              }
            }
          ]
        }
      ]
    },
    {
      attributes: {
        code: 'IEARW',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Arklow',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Arklow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ARW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEATM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Attymon',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ATM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Attymon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEATO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Athlone',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Athlone',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ATO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEATR',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Athenry',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Athenry',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ATR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEATY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Athy',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Athy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ATY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBAO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ballymote',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballymote',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BAO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBAQ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Ballycullane (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballycullane (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBAX',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ballina',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballina',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BAX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBBY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ballybrophy (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballybrophy (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BBY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBCI',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Balbriggan  (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Balbriggan  (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBHN',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ballyhaunis',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballyhaunis',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BHN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBNU',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Banteer (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Banteer (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BNU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBOQ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Boyle',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'BOQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Boyle',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBRJ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Bridgetown  (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bridgetown  (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBSG',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ballinasloe',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ballinasloe',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BSG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEBZY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Bray',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Bray',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'BZY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECAH',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cahir',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'CAH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cahir',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECAW',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carlow',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carlow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CAW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECHJ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Charleville',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Charleville',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CHJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECKA',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carrick-On-Shannon',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carrick-On-Shannon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CKA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECKU',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Carrick-on-Suir',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Carrick-on-Suir',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CKU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECLB',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castlebar',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castlebar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECLQ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clara',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clara',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLQ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECLX',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Clonmel',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Clonmel',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CLX',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECMI',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Claremorris',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Claremorris',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CMI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECOK',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Cork City',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'COK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Cork City',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECOU',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Collooney',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Collooney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'COU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IECSE',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Castlerea',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Castlerea',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'CSE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDBP',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin Pearse',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DBP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Pearse',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDCL',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin Connolly',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DCL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Connolly',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDFP',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin Ferryport',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DFP',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Ferryport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDHT',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin Heuston',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'DHT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Heuston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Heuston',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDMD',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dromod',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DMD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dromod',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDPS',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin Port - Stena',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DPS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dublin Port - Stena',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDRA',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Drogheda',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DRA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Drogheda',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEDUK',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dundalk',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'DUK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Dundalk',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEEFD',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enfield (Ireland)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'EFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Enfield (Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEENN',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ennis',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'ENN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Ennis',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEENS',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Enniscorthy',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Enniscorthy',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'ENS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEFAR',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Farranfore',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'FAR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Farranfore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEFXF',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Foxford',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Foxford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'FXF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEGOY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gorey',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gorey',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GOY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEGSO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Greystones',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Greystones',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GSO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEGWY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Galway',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Galway',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'GWY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEKDR',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kildare (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'KDR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Kildare (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEKLL',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Killarney',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Killarney',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KLL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEKNY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Kilkenny',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Kilkenny',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'KNY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IELFO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Longford',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'LFO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Longford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IELJN',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Limerick Junction',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limerick Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LJN',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IELRK',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Limerick',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Limerick',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'LRK',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMAE',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Maynooth',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Maynooth',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMAJ',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Manulla Junction',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MAJ',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Manulla Junction',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMAW',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mallow',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Mallow',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MAW',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMBH',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Muine Bheag (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MBH',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Muine Bheag (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMIE',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Millstreet (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MIE',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Millstreet (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMLC',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Malahide    (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Malahide    (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMOM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Edgeworthstown',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Edgeworthstown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'MOM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEMUL',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Mullingar',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'MUL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Mullingar',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IENBG',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Newbridge (Ireland)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'NBG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Newbridge (Ireland)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEPRO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portarlington (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portarlington (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PRO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEPTO',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Portlaoise',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Portlaoise',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'PTO',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IERCM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Roscommon',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'RCM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Roscommon',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IERDU',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rathdrum (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rathdrum (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RDU',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IERMR',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rathmore (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rathmore (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RMR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IERSB',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rosslare Europort',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rosslare Europort',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RSB',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IERSS',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Rosslare Strand',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Rosslare Strand',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'RSS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IESKC',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Skerries    (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Skerries    (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IESLI',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Sligo',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'SLI',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Sligo',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IESWC',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Sutton (Howth) (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Sutton (Howth) (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETEM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Templemore',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'TEM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Templemore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETHM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thornastown',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'THM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Thornastown',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETPY',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tipperary',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tipperary',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TPY',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETRL',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tralee',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tralee',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TRL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETUM',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Tullamore',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Tullamore',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TUM',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IETUS',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Thurles',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Thurles',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'TUS',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWBN',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'OUTPUT_ONLY',
        mainStation: 'NO',
        name: 'Wellington Bridge (CIV)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wellington Bridge (CIV)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWFD',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Waterford',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Waterford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WFD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWKL',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wicklow (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wicklow (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WKL',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWLA',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Woodlawn (Irish Rail)',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'WLA',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'Woodlawn (Irish Rail)',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWPT',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Westport',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Westport',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WPT',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEWXF',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Wexford',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Wexford',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'WXF',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEXAG',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Ardrahan',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Ardrahan',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XAG',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEXCD',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Craughwell',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Craughwell',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XCD',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEXGR',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Gort',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Gort',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XGR',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IEXMV',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Monasterevin',
        timeZone: 'Europe/Dublin',
        type: 'Station'
      },
      supplier: [
        {
          attributes: {
            alias: 'Monasterevin',
            source: 'ATOC',
            type: 'ALIAS'
          }
        },
        {
          attributes: {
            alias: 'XMV',
            source: 'ATOC',
            type: 'ALIAS'
          }
        }
      ]
    },
    {
      attributes: {
        code: 'IE^DU',
        countryCode: 'IE',
        fareStation: 'OUTPUT_ONLY',
        itineraryStation: 'YES',
        mainStation: 'NO',
        name: 'Dublin (All Stations)',
        timeZone: 'Europe/London',
        type: 'Group'
      },
      supplier: [
        {
          attributes: {
            source: 'ATOC'
          },
          memberStation: [
            {
              attributes: {
                code: 'IEDBP'
              }
            },
            {
              attributes: {
                code: 'IEDCL'
              }
            },
            {
              attributes: {
                code: 'IEDHT'
              }
            },
            {
              attributes: {
                code: 'IEDFP'
              }
            }
          ]
        }
      ]
    }
  ]