import nreStations from '../assets/nre_station_codes.json'
import { parse, toSeconds } from 'iso8601-duration'

import { createSelector } from 'reselect'
import { collect } from 'collect.js'

export const getTravelSegment = (order, index) => order.travelSegments.travelSegment[index]

export const getStationByCode = (stations, code) => stations.find((station) => station.attributes.code === code)

export const getNREStationCodeByName = (name) => nreStations[name]

export const selectStationByCode = (code) => (state) => state.stations.find(station => station.attributes.code === code)

export const getTotalDurationFromTravelSegments = (travelSegments) =>
  travelSegments.reduce((totalSeconds, travelSegment) => {
    return totalSeconds + toSeconds(parse(travelSegment.duration))
  }, 0)

export const getRules = (booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  // Implying we only have 1 order ticketable fare
  const ticketableFare = order.ticketableFares.ticketableFare[0]
  return ticketableFare.rules.rule
}

export const getChanges = (booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]

  return order.travelSegments.travelSegment.length - 1
}
export const getOriginStation = (stations, booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  const station = getStationByCode(stations, getTravelSegment(order, 0).originTravelPoint.$value)
  return {
    ...station.attributes,
    nreCode: getNREStationCodeByName(station.attributes.name)
  }
}

export const getDestinationStation = (stations, booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  const station = getStationByCode(stations, getTravelSegment(order, order.travelSegments.travelSegment.length - 1).destinationTravelPoint.$value)
  return {
    ...station.attributes,
    nreCode: getNREStationCodeByName(station.attributes.name)
  }
}

export const getDepartureDateTime = (booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  const departureTravelSegment = getTravelSegment(order, 0)

  return departureTravelSegment.departureDateTime
}

export const getArrivalDateTime = (booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  const arrivalTravelSegment = getTravelSegment(order, order.travelSegments.travelSegment.length - 1)

  return arrivalTravelSegment.arrivalDateTime
}

export const getPassengers = (booking) => {
  // Implying we only allow 1 order per booking
  const { passenger: passengers } = booking.passengers
  return passengers
}

export const getPriceTotal = (booking) => {
  const { price: prices } = booking.financials.prices
  return prices.reduce((total, price) => total + Number(price.amount.$value), 0)
}

export const getTicketableFares = (booking) => {
  // Implying we only allow 1 order per booking
  const order = booking.orders.order[0]
  return order?.ticketableFares.ticketableFare
}

export const selectIsCreatingBooking = (state) => state.bookings.isCreatingBooking

export const selectBooking = (state) => state.booking || null

export const selectBookingOrder = createSelector(
  selectBooking,
  (booking) => booking?.orders?.order[0] || null
)

export const selectBookingTotalPrice = createSelector(
  selectBooking,
  (booking) => booking?.paymentRequirements?.minimumDepositAmount?.$value || null
)

export const selectBookingHandoffQRCode = createSelector(
  selectBooking,
  (booking) => booking?.qrCode || null
)

export const selectBookingTravelSegmentByID = (travelSegmentIDRef) => createSelector(
  selectBookingTrainTravelSegments,
  (travelSegments) => travelSegments?.find(travelSegment => travelSegment.attributes.travelSegmentID === travelSegmentIDRef)
)

export const selectBookingTicketableFares = createSelector(
  selectBookingOrder,
  (order) => order?.ticketableFares?.ticketableFare || []
)

export const selectBookingTicketableFareByTravelSegmentID = (travelSegmentID) => createSelector(
  selectBookingTicketableFares,
  (ticketableFares) => ticketableFares.find(ticketableFare =>
    ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode.find(fareCode =>
      fareCode.travelSegmentIDRef === travelSegmentID) !== undefined
  )
)

export const selectBookingTicketableFare = (ticketableFareID) => createSelector(
  selectBookingOrder,
  (order) => order?.ticketableFares?.ticketableFare.find(ticketableFare => ticketableFare.attributes.ticketableFareID === ticketableFareID) || null
)

export const selectBookingPassengerReference = (ticketableFareID) => createSelector(
  selectBookingTicketableFare(ticketableFareID),
  (ticketableFare) => ticketableFare?.passengerReferences?.passengerReference[0] || null
)

export const selectBookingFare = (ticketableFareID) => createSelector(
  selectBookingPassengerReference(ticketableFareID),
  (passengerReference) => passengerReference?.fareCodes.fareCode[0] || null
)

export const selectBookingPrices = (ticketableFareID) => createSelector(
  selectBookingTicketableFare(ticketableFareID),
  (ticketableFare) => ticketableFare?.prices?.price || []
)

export const selectBookingTravelSegmentsByTicketableFareID = (ticketableFareID) => createSelector(
  selectBookingPassengerReference(ticketableFareID),
  selectBookingTrainTravelSegments,
  (passengerReference, travelSegments) => {
    const fares = passengerReference?.fareCodes.fareCode
    const travelSegmentIDs = fares.map(fare => fare.travelSegmentIDRef)
    return travelSegments.filter(travelSegment => travelSegmentIDs.includes(travelSegment.attributes.travelSegmentID))
  }
)

export const selectBookingTravelSegments = createSelector(
  selectBookingOrder,
  (order) => order?.travelSegments.travelSegment
)

export const selectBookingTrainTravelSegments = createSelector(
  selectBookingTravelSegments,
  (travelSegments) => travelSegments?.filter(travelSegment => travelSegment.attributes.type === 'TRAIN')
)

export const selectBookingOrderedLegTicketableFares = createSelector(
  selectBookingTrainTravelSegments,
  selectBookingTicketableFares,
  (travelSegments, ticketableFares) => {
    return collect(travelSegments
      ?.sort((a, b) => a.attributes.legGrouping - b.attributes.legGrouping)
      ?.filter(travelSegment => ticketableFares.filter(ticketableFare =>
        ticketableFare.passengerReferences.passengerReference[0]
        .fareCodes.fareCode.filter(fareCode => fareCode.travelSegmentIDRef === travelSegment.attributes.travelSegmentID)
      ))
      ?.map(travelSegment => ({
          legGrouping: travelSegment.attributes.legGrouping,
          ...ticketableFares.find(ticketableFare => ticketableFare.passengerReferences.passengerReference[0].fareCodes.fareCode.find(fareCode => fareCode.travelSegmentIDRef === travelSegment.attributes.travelSegmentID))
        })
      )).unique(ticketableFare => ticketableFare.attributes.ticketableFareID + ticketableFare.legGrouping).toArray()
  }
)
