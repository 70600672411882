import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import GPayButton from '@google-pay/button-react'

import './GooglePayButton.scss'

export default class GooglePayButton extends PureComponent {

    static propTypes = {
        onPaymentAuthorized: PropTypes.func.isRequired,
        orderTotal: PropTypes.number.isRequired
    }

    handlePaymentAuthorized = (paymentData) => {
        this.props.onPaymentAuthorized(paymentData, 'googlepay', () => { })
        return { transactionState: 'SUCCESS' };
    }

    render() {
        return (
            <GPayButton
                environment={process.env.REACT_APP_RAILPAY_GOOGLE_PAY_ENV}
                paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                        {
                            type: 'CARD',
                            parameters: {
                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                billingAddressRequired: true,
                                billingAddressParameters: {
                                    format: 'FULL'
                                }
                            },
                            tokenizationSpecification: {
                                type: 'PAYMENT_GATEWAY',
                                parameters: {
                                    gateway: 'cybersource',
                                    gatewayMerchantId: 'vtec_acom',
                                },
                            },
                        },
                    ],
                    merchantInfo: {
                        merchantId: 'BCR2DN6T57243CD5',
                        merchantName: 'RailPay',
                    },
                    transactionInfo: {
                        totalPriceStatus: 'FINAL',
                        totalPriceLabel: 'Total',
                        totalPrice: this.props.orderTotal.toString(),
                        currencyCode: 'GBP',
                        countryCode: 'UK',
                    },
                    emailRequired: true,
                    callbackIntents: ['PAYMENT_AUTHORIZATION'],
                }}
                onLoadPaymentData={paymentRequest => {
                    console.log('Success', paymentRequest);
                }}
                totalPriceStatus={'FINAL'}
                onPaymentAuthorized={this.handlePaymentAuthorized}
            />
        )
    }
}
